import { Post } from '@kessel/core'
import { Context } from '@nuxt/types'
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { usePost } from '~/stores/post'

export default defineNuxtMiddleware(async (ctx: Context) => {
  const {
    route: {
      params: { id: publicationId },
      query: { post: postId },
    },
    redirect,
    localePath,
  } = ctx

  if (publicationId && !postId) {
    const { addPost } = usePost(ctx.$pinia)
    const post = await addPost(publicationId.toString(), {
      title_draft: '',
      description_draft: '',
      is_draft: true,
      pinned: false,
    } as Post)

    await redirect(localePath({ name: 'publication-id-write', params: { id: publicationId }, query: { post: post.id } }))
  }
})
