import {
  PostStats,
  PublicationStats,
  PublicationStatsRevenues,
  PublicationStatsRevenuesBucket,
  PublicationStatsUsers,
  StatsParams,
} from '@kessel/core'
import { ManipulateType } from 'dayjs'
import { defineStore } from 'pinia'
import { usePublication } from '~/stores/publication'

interface State {
  stats: PublicationStats[]
}

export const useStats = defineStore('stats', {
  state: (): State => ({
    stats: [],
  }),
  getters: {
    getLocalPublicationStats: (state) => (publicationId: string) => {
      return state.stats.find((stat: PublicationStats) => stat.publication_id === publicationId)
    },
    getDataCumulative: (_) => (data: number[], cumulativeData?: number, sum?: boolean) => {
      if (typeof cumulativeData !== 'undefined') {
        const result: number[] = []
        const dataCopy = [...data]
        dataCopy[0] += cumulativeData
        dataCopy.reduce((a, b, i) => {
          result[i] = a + b
          return result[i]
        }, 0)
        return sum ? result.reduce((partialSum, a) => partialSum + a, 0) : result
      }
      return sum ? data.reduce((partialSum, a) => partialSum + a, 0) : data
    },
  },
  actions: {
    /**
     * Get range dates
     * @param subtract - number of dates to subtract
     * @param type - type of dates to subtract
     * @param endDate - date to subtract from
     * @param minDate - minimum date (registered date for example)
     */
    getRangeDates(subtract: number, type: ManipulateType, endDate?: Date, minDate?: Date) {
      const {
        $nuxt: { $dayjs },
      } = this
      const format = 'YYYY-MM-DD'

      const startDate = $dayjs($dayjs(endDate).subtract(subtract, type).format(format)).toDate()
      const minDateFormat = minDate && $dayjs($dayjs(minDate).format(format)).toDate()

      return {
        start: minDateFormat && minDateFormat > startDate ? minDateFormat : startDate,
        end: $dayjs($dayjs(endDate).format(format)).toDate(),
      }
    },
    async syncPublicationsStats() {
      const { publications } = usePublication()

      for (const publication of publications) {
        if (publication?.id) {
          this.stats.push({
            ...(await this.getPublicationStats({
              publicationId: publication.id,
              dates: this.getRangeDates(1, 'year'),
              bucket: '100y',
            })),
            publication_id: publication.id,
          })
        }
      }
    },
    async syncPublicationStats() {
      const { publication } = usePublication()
      if (publication?.id) {
        this.stats.push({
          ...(await this.getPublicationStats({
            publicationId: publication.id,
            dates: this.getRangeDates(1, 'year'),
            bucket: '100y',
          })),
          publication_id: publication.id,
        })
      }
    },
    async addPostStats(postId: string) {
      await this.$nuxt.$axios.$post('/v1/stats/posts/page_view', {
        post_id: postId,
        timeout: 5000,
      })
    },
    async getPublicationStats({ publicationId, dates, bucket }: StatsParams): Promise<PublicationStats> {
      return await this.$nuxt.$axios.$get(`/v1/stats/publications/${publicationId}`, {
        params: {
          start: dates.start.toISOString(),
          end: dates.end.toISOString(),
          bucket,
        },
      })
    },
    async getPublicationUsersStats({ publicationId, dates, bucket }: StatsParams): Promise<PublicationStatsUsers> {
      return await this.$nuxt.$axios.$get(`/v1/stats/publications/${publicationId}/users`, {
        params: {
          start: dates.start.toISOString(),
          end: dates.end.toISOString(),
          bucket,
        },
      })
    },
    async getPostAllRevenues(publicationId: string): Promise<PublicationStatsRevenuesBucket> {
      return await this.$nuxt.$axios.$get(`/v1/stats/publications/${publicationId}/all_revenues`)
    },
    async getPostRevenues({ publicationId, dates, bucket }: StatsParams): Promise<PublicationStatsRevenues> {
      return await this.$nuxt.$axios.$get(`/v1/stats/publications/${publicationId}/revenues`, {
        params: {
          start: dates.start.toISOString(),
          end: dates.end.toISOString(),
          bucket,
        },
      })
    },
    async getPostStats(postId: string): Promise<PostStats> {
      return await this.$nuxt.$axios.$get(`/v1/stats/posts/${postId}`)
    },
  },
})
