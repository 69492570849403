import { render, staticRenderFns } from "./TipTapImageElement.vue?vue&type=template&id=24c2d528&scoped=true&"
import script from "./TipTapImageElement.vue?vue&type=script&lang=ts&"
export * from "./TipTapImageElement.vue?vue&type=script&lang=ts&"
import style0 from "./TipTapImageElement.vue?vue&type=style&index=0&id=24c2d528&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c2d528",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KImage: require('/tmp/build_84effd69/apps/web/components/image/KImage.vue').default,KIcon: require('/tmp/build_84effd69/apps/web/components/icons/KIcon.vue').default,KUploadFile: require('/tmp/build_84effd69/apps/web/components/input/KUploadFile.vue').default,Button: require('/tmp/build_84effd69/apps/web/components/editor/components/Button.vue').default})
