


































import { computed, defineComponent, ref, toRefs, useContext, watch } from '@nuxtjs/composition-api'

import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2'
import { useUpload } from '~/stores/upload'

import { EMBED_SRC_REGEX, TYPES } from '~/utils/tiptap'

export default defineComponent({
  components: {
    NodeViewWrapper,
  },
  props: { ...(nodeViewProps as any) },
  setup(props) {
    const {
      updateAttributes,
      node: {
        value: {
          attrs: {
            image,
            code,
          },
        },
      },
    } = toRefs(props)
    const embedElem = ref()
    const { $editor } = useContext()
    const { doFetch } = useUpload()
    const active = computed(() => $editor.isActive(TYPES.EMBED))

    const remove = () => {
      $editor.chain().focus().deleteSelection().run()
    }

    const renderImage = async () => {
      const [_, href] = EMBED_SRC_REGEX.exec(code) || []

      if (!href) {
        return
      }

      const image = await doFetch(href)

      updateAttributes.value({
        image,
      })
    }

    watch(embedElem, () => {
      if (image || !embedElem?.value) {
        return
      }

      setTimeout(renderImage, 3000)
    }, {
      immediate: true,
    })

    return {
      embedElem,
      code,
      active,
      remove,
    }
  },
})
