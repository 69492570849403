



































































import { defineComponent } from '@nuxtjs/composition-api'
import { useDomain } from '~/stores/domain'

export default defineComponent({
  inheritAttrs: false,
  props: {
    separator: {
      type: Boolean,
      default: true,
    },
    whiteLogo: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { goToHome } = useDomain()
    return {
      goToHome,
    }
  },
})
