





































import { PaginationNames, Subscription } from '@kessel/core'
import { computed, defineComponent, onBeforeMount, useAsync } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { useFeatureFlip } from '~/stores/featureFlip'
import { useOffer } from '~/stores/offer_v2'
import { usePagination } from '~/stores/pagination'
import { usePublication } from '~/stores/publication_v2'
import { useRights } from '~/stores/right'
import { useSearch } from '~/stores/search'

export default defineComponent({
  props: {
    value: { type: Boolean, default: false },
  },
  setup(_) {
    const { publication, recommendations } = storeToRefs(usePublication())
    const { subscription } = storeToRefs(useRights())
    const { products } = storeToRefs(useOffer())
    const { forceCloseSubscriptionDrawer: forceClose } = useRights()
    const { recommendations: algoliaRecommendations } = storeToRefs(useSearch())
    const { recommends } = useSearch()
    const { isActive } = useFeatureFlip()
    const { syncPagination, getPagination } = usePagination()
    const paginationName = computed(() => PaginationNames.DASHBOARD_SUBSCRIPTIONS)

    const userLocalSubs = computed(() => getPagination(paginationName.value).items as Subscription[])

    const featureRecommends = computed(() => isActive('recommends'))

    useAsync(async () => {
      if (featureRecommends.value && publication.value?.id) {
        await recommends([publication.value.id], {
          exludedIds: userLocalSubs.value.map((sub) => sub?.publication?.id),
        })
      }
    })

    onBeforeMount(async () => {
      await syncPagination({ name: paginationName.value, page: 1 }, true)
    })

    return {
      canCancel: true,
      recommendations: computed(() =>
        publication.value?.recommendation_enabled ? recommendations.value : algoliaRecommendations.value
      ),
      publication,
      products,
      subscription,
      forceClose,
    }
  },
})
