










































import { computed, defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: null,
    },
    capitalize: {
      type: Boolean,
      default: false,
    },
    resizeable: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const inputRef = ref<HTMLFormElement>()

    return {
      valid: computed(() => {
        return inputRef.value?.validity.valid
      }
      ),
      inputRef,
    }
  },
})

