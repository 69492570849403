import { Extensions, JSONContent } from '@tiptap/core'
import { Level } from '@tiptap/extension-heading'
import { generateJSON } from '@tiptap/html'
import { ButtonOptions } from './tiptap/button'
import { EMBED_SRC_REGEX, YOUTUBE_EMBED } from './tiptap/embed'

export { EMBED_SRC_REGEX, YOUTUBE_EMBED }
export type { ButtonOptions }

export enum TEXT_MARKS {
  ITALIC = 'italic',
  BOLD = 'bold',
  SUP = 'superscript',
  UNDERLINE = 'underline',
  STRIKE = 'strike',
}

export enum INLINE_MARKS {
  LINK = 'link',
}

export enum TYPESBUTTON {
  SUBSCRIBE = 'EDITOR_TYPE_SUB',
  LINKBTN = 'EDITOR_BTN_TYPE',
}

type MARKS = TEXT_MARKS | INLINE_MARKS

export enum TYPES_LEGACY {
  HEADING_LEGACY = 'header',
  HEADING_ONE = 'heading-one',
  HEADING_TWO = 'heading-two',
  HEADING_THREE = 'heading-three',
  HEADING_FOUR = 'heading-four',
  HEADING_FIVE = 'heading-five',
  HEADING_SIX = 'heading-six',
  QUOTE = 'quote',
  RIGHT = 'right',
  CENTER = 'center',
  LEFT = 'left',
  BUTTON = 'button',
  JUSTIFY = 'justify',
  SEPARATOR = 'separator',
  DELIMITER = 'delimiter',
  BULLETED_LIST = 'bulleted-list',
  NUMBERED_LIST = 'numbered-list',
  LIST_ITEM = 'list-item',
  LIST = 'list',
}

export enum ALIGNEMENTS {
  RIGHT = 'right',
  CENTER = 'center',
  LEFT = 'left',
  JUSTIFY = 'justify',
}

export enum TYPES {
  FONTFAMiLY = 'textStyle',
  TEXT_INDENT = 'textIndent',
  CODE = 'code',
  IMAGE = 'image',
  TEXT = 'text',
  DOCUMENT = 'doc',
  PARAGRAPH = 'paragraph',
  HEADING = 'heading',
  TABLE = 'table',
  TABLE_CELL = 'tableCell',
  TABLE_ROW = 'tableRow',
  BULLET_LIST = 'bulletList',
  ORDERED_LIST = 'orderedList',
  LIST_ITEM = 'listItem',
  BLOCKQUOTE = 'blockquote',
  EMBED = 'embed',
  BUTTON = 'button',
  CAPTION = 'caption',
  HARD_BREAK = 'hardBreak',
  HORIZONTAL_RULE = 'horizontalRule',
}

export const LEGACY_HEADINGS = [
  TYPES_LEGACY.HEADING_ONE,
  TYPES_LEGACY.HEADING_TWO,
  TYPES_LEGACY.HEADING_THREE,
  TYPES_LEGACY.HEADING_FOUR,
  TYPES_LEGACY.HEADING_FIVE,
  TYPES_LEGACY.HEADING_SIX,
]

export enum SHORTCUTS_KEY {
  BOLD = 'b',
  ITALIC = 'i',
  UNDERLINE = 'u',
  STRIKE = 'k',
}

export interface LegacyItem {
  content?: string
}
export interface LegacyData {
  items?: LegacyItem[]
  style?: string
  text?: string
  file?: { url: string }
  level?: number
}

export interface LegacyNode {
  type?: TYPES | TYPES_LEGACY
  data?: LegacyData
}

interface ContainMarks extends Partial<Record<MARKS, boolean | string>> {}

export interface KElement extends LegacyNode, ContainMarks {
  text?: string
  x?: number
  y?: number
  width?: number
  height?: number
  src?: string
  url?: string
  link?: string
  caption?: string | null
  target?: string | null
  alignment?: 'left' | 'center' | 'right' | 'justify'
  children?: KElement[]
}

export interface TipTapEmbedAttrs {
  code: string
}

export interface TipTapImageAttrs {
  src: string
  href?: string
  caption?: string
  expanded?: boolean
  crop?: {
    x?: number
    y?: number
    width?: number
    height?: number
  }
}

export interface TipTapMarkAttrs {
  textAlign?: 'left' | 'center' | 'right' | 'justify'
}

export interface TipTapLinkAttrs {
  href: string
  target?: '_blank' | '_self'
}

export interface TipTapHeadingAttrs {
  level: Level
}

export interface TipTapFontAttrs {
  fontFamily: String
  label?: String
}

export interface TipTapButtonAttrs extends ButtonOptions {}

export interface TipTapLegacyButtonAttrs extends TipTapButtonAttrs {
  // legacy
  position?: string | null
  link?: string | null
  expanded?: string | null
  label?: string | null
  textColor?: string | null
}

export type TipTapNodeAttrs =
  | TipTapFontAttrs
  | TipTapHeadingAttrs
  | TipTapLinkAttrs
  | TipTapMarkAttrs
  | TipTapImageAttrs
  | TipTapEmbedAttrs
  | TipTapButtonAttrs

export interface TiptTapMark extends Omit<LegacyNode, 'type'> {
  type: MARKS
  attrs?: TipTapNodeAttrs
}

export interface TiptTapNode extends Omit<TiptTapMark, 'type'> {
  type?: TYPES
  text?: string
  marks?: TiptTapMark[]
  content?: TiptTapNode[]
}

export interface KDocument {
  type?: TYPES
  content?: TiptTapNode[]
  blocks?: KElement[]
}

export const SHORTCUTS = {
  [SHORTCUTS_KEY.BOLD]: {
    format: TEXT_MARKS.BOLD,
    tooltip: {
      mac: '+b',
      other: 'ctrl+b',
    },
  },
  [SHORTCUTS_KEY.ITALIC]: {
    format: TEXT_MARKS.ITALIC,
    tooltip: {
      mac: '+i',
      other: 'ctrl+i',
    },
  },
  [SHORTCUTS_KEY.UNDERLINE]: {
    format: TEXT_MARKS.UNDERLINE,
    tooltip: {
      mac: '+u',
      other: 'ctrl+u',
    },
  },
  [SHORTCUTS_KEY.STRIKE]: {
    format: TEXT_MARKS.STRIKE,
    tooltip: {
      mac: '+x',
      other: 'ctrl+shift+x',
    },
  },
}

export const getUriWithParam = (baseUrl: string, params: Record<string, any>): string => {
  const Url = new URL(baseUrl)
  const urlParams: URLSearchParams = new URLSearchParams(Url.search)
  for (const key in params) {
    if (params[key] !== undefined) {
      urlParams.set(key, params[key])
    }
  }
  Url.search = urlParams.toString()
  return Url.toString()
}

export const parseOutputBlock = ({ content, attrs, type, marks, text }: TiptTapNode): KElement => {
  return {
    children: [],
    ...(type !== TYPES.TEXT && { type }),
    ...(type === TYPES.BLOCKQUOTE && { type: TYPES_LEGACY.QUOTE }),
    ...(type === TYPES.HORIZONTAL_RULE && { type: TYPES_LEGACY.SEPARATOR }),
    ...(text && { text }),
    ...(content && { children: content?.map(parseOutputBlock) }),
    ...((attrs as TipTapMarkAttrs)?.textAlign && { alignment: (attrs as TipTapMarkAttrs).textAlign }),
    ...(type === TYPES.HEADING && { type: LEGACY_HEADINGS[(attrs as TipTapHeadingAttrs).level] }),
    ...(type === TYPES.EMBED &&
      (attrs as TipTapEmbedAttrs)?.code && {
      src: (attrs as TipTapEmbedAttrs)?.code,
    }),
    ...(type === TYPES.IMAGE &&
      (attrs as TipTapImageAttrs)?.src && {
      url: (attrs as TipTapImageAttrs).src,
      caption: (attrs as TipTapImageAttrs).caption,
      expanded: (attrs as TipTapImageAttrs).expanded,
      link: (attrs as TipTapImageAttrs).href,
      ...((attrs as TipTapImageAttrs)?.crop && {
        x: (attrs as TipTapImageAttrs).crop?.x || 0,
        y: (attrs as TipTapImageAttrs).crop?.y || 0,
        width: (attrs as TipTapImageAttrs).crop?.width || 0,
        height: (attrs as TipTapImageAttrs).crop?.height || 0,
      }),
    }),
    ...(marks &&
      marks.reduce((acc: Partial<KElement>, { type, attrs: markAttrs }) => {
        return {
          ...acc,
          [type]: true,
          ...(type === INLINE_MARKS.LINK &&
            (markAttrs as TipTapLinkAttrs)?.href && {
            type: INLINE_MARKS.LINK as any as TYPES,
            src: (markAttrs as TipTapLinkAttrs).href,
            target: (markAttrs as TipTapLinkAttrs).target,
            children: [{ text }],
            text: undefined,
          }),
        }
      }, {})),
  }
}

export const filterUncompatibilyNode = ({ type = TYPES.TEXT, text }: KElement): boolean => {
  switch (true) {
    case type === 'hardBreak':
      return false
    case type === TYPES.TEXT && !!text?.length:
      return true
    case (type as any) === INLINE_MARKS.LINK:
    case (type as any) === TYPES.IMAGE:
      return true
    default:
      console.warn(`Uncompatibily node type: ${type} ${text}`)
      return !!text?.length
  }
}

export const fonts: { name: string; label: string }[] = [
  {
    name: 'Whyte, Helvetica, Arial, sans-serif',
    label: 'EDITOR_TOOLS_FONT_HELVETICA',
  },
  {
    name: 'Arial',
    label: 'EDITOR_TOOLS_FONT_ARIAL',
  },
  {
    name: 'Times New Roman',
    label: 'EDITOR_TOOLS_TIMES',
  },
  {
    name: 'Verdana',
    label: 'EDITOR_TOOLS_FONT_VERDANA',
  },
  {
    name: 'Courier New',
    label: 'EDITOR_TOOLS_FONT_COURIER',
  },
  {
    name: 'Tahoma',
    label: 'EDITOR_TOOLS_FONT_TAHOMA',
  },
  {
    name: 'Georgia',
    label: 'EDITOR_TOOLS_FONT_GEORGIE',
  },
  {
    name: 'Palatino',
    label: 'EDITOR_TOOLS_FONT_ARIAL_PLATINO',
  },
  {
    name: 'Trebuchet MS',
    label: 'EDITOR_TOOLS_FONT_ARIAL_TREBUCHETMS',
  },
  {
    name: 'Genève',
    label: 'EDITOR_TOOLS_FONT_ARIAL_GENEVE',
  },
]

export const parseInputBlock = (
  {
    type = TYPES.TEXT,
    x = 0,
    y = 0,
    width = 0,
    height = 0,
    children,
    alignment,
    text,
    link,
    src,
    url,
    caption,
    data: { style: legacyStyle, items: legacyItems, text: legacyText, file: legacyFile, ...legacyData } = {},
    ...block
  }: KElement,
  extensions: Extensions
): TiptTapNode => {
  const marks = Object.keys(TEXT_MARKS).reduce((acc: TiptTapMark[], key: any) => {
    if (block[TEXT_MARKS[key as keyof typeof TEXT_MARKS]] === true) {
      acc.push({
        type: TEXT_MARKS[key as keyof typeof TEXT_MARKS],
      })
    }

    return acc
  }, [])

  if ((type as any) === INLINE_MARKS.LINK && src) {
    if (children) {
      children = children.splice(0, children.length)
    }
    marks.push({
      type: INLINE_MARKS.LINK,
      attrs: {
        href: src,
        target: '_blank',
      },
    })
  }

  if ((type as any) === TYPES.IMAGE && link) {
    marks.push({
      type: INLINE_MARKS.LINK,
      attrs: {
        href: link,
        target: '_blank',
      },
    })
  }

  if (type && [TYPES_LEGACY.RIGHT, TYPES_LEGACY.CENTER, TYPES_LEGACY.LEFT, TYPES_LEGACY.JUSTIFY].includes(type as any)) {
    alignment = type as any
    type = TYPES.PARAGRAPH
  }

  const alignAttrs = alignment && { textAlign: alignment }

  const baseAttrs = {
    ...legacyData,
    ...alignAttrs,
  }

  const { content } =
    (legacyText && typeof legacyText === 'string' && legacyText.length && extensions && generateJSON(legacyText, extensions)) ||
    {}

  const [legacyContent] = content || []

  return {
    ...legacyContent,
    ...(type && ({ type } as any)),

    ...(text && { text }),
    attrs: {
      ...baseAttrs,
    },
    ...((type as any) === TYPES.TEXT && children?.length && { type: TYPES.PARAGRAPH }),
    ...((type as any) === TYPES_LEGACY.LIST && {
      type: legacyStyle === 'ordered' ? TYPES.ORDERED_LIST : TYPES.BULLET_LIST,
      content: (legacyItems || []).map(({ content }) =>
        parseInputBlock({ type: TYPES.LIST_ITEM, children: [{ type: TYPES.TEXT, text: content }] }, extensions)
      ),
    }),
    ...((type as any) === TYPES_LEGACY.LIST_ITEM && { type: TYPES.LIST_ITEM }),
    ...((type as any) === TYPES_LEGACY.NUMBERED_LIST && { type: TYPES.ORDERED_LIST }),
    ...((type as any) === TYPES_LEGACY.BULLETED_LIST && { type: TYPES.BULLET_LIST }),
    ...((type as any) === TYPES_LEGACY.DELIMITER && { type: TYPES.HORIZONTAL_RULE }),
    ...((type as any) === TYPES_LEGACY.SEPARATOR && { type: TYPES.HORIZONTAL_RULE }),
    ...((type as any) === TYPES_LEGACY.QUOTE && { type: TYPES.BLOCKQUOTE }),
    ...((type as any) === INLINE_MARKS.LINK && { type: TYPES.TEXT, text: children?.find(({ text }) => !!text)?.text || src }),
    ...(type === TYPES_LEGACY.HEADING_LEGACY && { type: TYPES.HEADING }),
    ...(type &&
      LEGACY_HEADINGS.includes(type as TYPES_LEGACY) && {
      type: TYPES.HEADING,
      attrs: { ...baseAttrs, level: (LEGACY_HEADINGS.indexOf(type as TYPES_LEGACY) + 1) as Level },
    }),
    ...(type === TYPES.EMBED &&
      src && {
      attrs: {
        ...baseAttrs,
        code: src,
      },
    }),
    ...(type === TYPES.IMAGE && {
      attrs: {
        ...baseAttrs,
        ...(caption && { caption }),
        src: url || legacyFile?.url,
        crop: { x, y, width, height },
      } as TipTapImageAttrs,
    }),
    ...(marks.length && { marks }),
    ...(children && { content: children.filter(filterUncompatibilyNode).map((child) => parseInputBlock(child, extensions)) }),
  }
}

const isButtonLegacyAttrs = (b: TipTapNodeAttrs): b is TipTapLegacyButtonAttrs => {
  return (b as TipTapLegacyButtonAttrs).expanded !== undefined
}

export const ensureLegacyNode = ({ type, attrs, content, ...rest }: TiptTapNode, extensions: Extensions = []): TiptTapNode => {
  return {
    type,
    content: content?.map((node) => ensureLegacyNode(node, extensions)) || [],
    ...(
      extensions.filter(({ name }) => name === 'table').length === 0 &&
      (type === TYPES.TABLE || type === TYPES.TABLE_ROW || type === TYPES.TABLE_CELL) && {
        type: TYPES.PARAGRAPH,
        content: content?.reduce((aggr: any[], node) => {
          const child = ensureLegacyNode(node)
          return [...aggr, ...child.content ? child.content : []]
        }, []),
      }),
    ...rest,
    ...(attrs && {
      attrs: {
        ...attrs,
        ...(isButtonLegacyAttrs(attrs) &&
          ({
            href: (attrs as TipTapLegacyButtonAttrs).link,
            title: (attrs as TipTapLegacyButtonAttrs).label,
            fullWidth: !!(attrs as TipTapLegacyButtonAttrs).expanded,
            textAlign: (attrs as TipTapLegacyButtonAttrs).position,
            color: (attrs as TipTapLegacyButtonAttrs).textColor,
            backgroundColor: (attrs as TipTapLegacyButtonAttrs).color,
          } as TipTapButtonAttrs)),
      } as TipTapNodeAttrs,
    }),
  }
}

export const parseOutputValue = (data: TiptTapNode): KDocument => {
  const blocks = data.content?.map(parseOutputBlock) || []

  return { ...data, blocks }
}

export const ensureLegacyNodes = (content: TiptTapNode[], extensions: Extensions = []): TiptTapNode[] => {
  return content.map((node) => ensureLegacyNode(node, extensions))
}

export const parseInitialValue = (data: string | undefined | null, extensions: Extensions): JSONContent => {
  const defaultBlocks = [
    {
      type: TYPES.PARAGRAPH,
    },
  ]

  const {
    type,
    content,
    blocks = [],
  }: KDocument = JSON.parse(
    data ||
      JSON.stringify({
        blocks: defaultBlocks,
      })
  )
  const parsedBlocks = content ? ensureLegacyNodes(content, extensions) : blocks.map((args) => parseInputBlock(args, extensions))

  return {
    type: type || TYPES.DOCUMENT,
    content: parsedBlocks,
  }
}
