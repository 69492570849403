













































































































































































































import { DEFAULT_BACKEND_RESIZE_IMAGE, EXTERNAL_URL_REGEX, IMAGE_MAX_SIZE, ImageCropped } from '@kessel/core'
import { PropType, Ref, computed, defineComponent, ref, toRefs, useContext, watch } from '@nuxtjs/composition-api'
import isEqual from 'lodash/isEqual'
import type { Cropper, VisibleArea } from 'vue-advanced-cropper'
import KMessage, { MessageProps } from '~/components/KMessage.vue'
import { useUpload } from '~/stores/upload'
import { BASE64_URL_REGEX, base64ToFile } from '~/utils/base64ToBlob'

export default defineComponent({
  components: { KMessage },
  props: {
    value: { type: Object as PropType<ImageCropped>, default: null },
    customLabel: { type: String, default: null },
    recommendation: { type: String, default: 'SIZE_UPLOAD_RECOMMENDATION' },
    icon: { type: String, default: 'edit' },
    readonly: {
      type: Boolean,
      default: false,
    },
    isCropModalActive: {
      type: Boolean,
      default: false,
    },
    aspect: {
      type: String,
      default: 'square',
      validator: (value: string) => ['free', 'square', 'video', 'banner', 'rounded'].includes(value),
    },
    fileSize: {
      type: Number,
      default: IMAGE_MAX_SIZE,
    },
    error: {
      type: Object as PropType<MessageProps>,
      default: null,
    },
  },
  setup(props, { emit, root: { $buefy } }) {
    const { value, customLabel, aspect, isCropModalActive, fileSize, error } = toRefs(props)
    const { i18n, $img } = useContext()
    const { doFetch, doUpload, checkFileSize } = useUpload()

    const isComponentModalActive = ref(isCropModalActive.value)
    const inputEl = ref()
    const cropper = ref<Cropper | null>(null)
    const croppedValue = ref<ImageCropped | null>(value.value)
    const uploading = ref(false)
    const localError: Ref<MessageProps | null> = ref(error.value || null)

    const aspectRatio = computed(() => {
      switch (aspect.value) {
        case 'banner':
          return 5 / 1
        case 'video':
          return 16 / 9
        case 'square':
          return 1
        default:
          return 0
      }
    })

    const fullImageURL = computed(() => (value.value?.filename && $img.getImage(value.value.filename).url) || false)

    watch(
      isCropModalActive,
      (openModalFromProps: boolean) => {
        if (openModalFromProps !== isComponentModalActive.value) {
          isComponentModalActive.value = openModalFromProps
        }
      },
      { immediate: false }
    )

    const fetchOrUpload = async (file?: File) => {
      if (!croppedValue.value || !croppedValue.value.filename) {
        return
      }
      try {
        let filename = String(croppedValue.value.filename)
        const isExternalURL = EXTERNAL_URL_REGEX.test(filename)
        const isBASE64URL = BASE64_URL_REGEX.test(filename)

        uploading.value = true

        const filenameNotEqual = (croppedValue: Ref<ImageCropped | null>) => {
          if (filename !== croppedValue.value?.filename) {
            croppedValue.value = {
              ...croppedValue.value,
              filename,
            }
            emit('input', croppedValue.value)
          }
        }

        const buefyError = () => {
          $buefy.toast.open({
            message: String(i18n.t(localError.value?.message as string)),
            type: 'is-danger',
            position: 'is-top',
            duration: 8000,
          })
        }

        if (isExternalURL) {
          filename = await doFetch(filename)
          filenameNotEqual(croppedValue)
        } else if (!file && isBASE64URL) {
          const b64ToFile = base64ToFile(filename)

          checkFileSize(b64ToFile, fileSize.value, ['image/gif'], async () => {
            filename = await doUpload(b64ToFile)
            console.info('filename after ===> ', filename)
            filenameNotEqual(croppedValue)
          }, (_, __, ___, error) => {
            localError.value = error
            if (localError.value?.toast) {
              buefyError()
            }
            croppedValue.value = null
          })
        } else if (file) {
          checkFileSize(file, fileSize.value, ['image/gif'], async () => {
            filename = await doUpload(file)
            localError.value = null
            filenameNotEqual(croppedValue)
          }, (_, __, ___, error) => {
            localError.value = error
            if (localError.value?.toast) {
              buefyError()
            }
            croppedValue.value = null
          })
        }
      } catch (error) {
        console.error('Upload Error =>', error)
      } finally {
        uploading.value = false
      }
    }

    const upload = (file: File) => {
      try {
        uploading.value = true
        const imageFileReader = new FileReader()
        imageFileReader.onload = async (event: any) => {
          await new Promise((resolve) => {
            const img = new Image()
            img.onload = () => {
              // image is loaded; sizes are available
              croppedValue.value = {
                ...value.value,
                filename: event.target.result,
                ...aspectRatioImage(img),
              }
              resolve(fetchOrUpload(file))
            }
            img.src = event.target.result
          }).catch(function (error) {
            console.error('Failed loading file', error)
            throw new Error(error)
          })
        }
        imageFileReader.readAsDataURL(file)
      } catch (error: any) {
        throw new Error(error)
      } finally {
        uploading.value = false
      }
    }

    const aspectRatioImage = ({ naturalWidth, naturalHeight }: HTMLImageElement) => {
      // let's store the width and height of our image
      const inputWidth = Math.min(DEFAULT_BACKEND_RESIZE_IMAGE, naturalWidth)
      const inputHeight = Math.min(DEFAULT_BACKEND_RESIZE_IMAGE, naturalHeight)
      // get the aspect ratio of the input image
      const inputImageAspectRatio = inputWidth / inputHeight
      // if it's bigger than our target aspect ratio
      let outputWidth = inputWidth
      let outputHeight = inputHeight
      if (inputImageAspectRatio > aspectRatio.value) {
        outputWidth = Math.round(inputHeight * aspectRatio.value)
      } else if (inputImageAspectRatio < aspectRatio.value) {
        outputHeight = Math.round(inputWidth / aspectRatio.value)
      }
      // Move to center
      const outputX = Math.round(Math.max(0, (inputWidth * 0.5) - (outputWidth * 0.5)))
      const outputY = Math.round(Math.max(0, (inputHeight * 0.5) - (outputHeight * 0.5)))
      // set it to the same size as the image
      return {
        x: outputX,
        y: outputY,
        width: outputWidth,
        height: outputHeight,
      }
    }

    watch(value, async (newVal) => {
      croppedValue.value = newVal
      await fetchOrUpload()
    }, { immediate: true })

    const closeCropper = () => {
      isComponentModalActive.value = false
      emit('close-cropper')
    }

    const updateCropped = () => {
      closeCropper()

      if (!isEqual(value.value, croppedValue.value)) {
        emit('input', croppedValue.value)
      }
    }

    const zoom = (factor: number) => {
      cropper?.value?.zoom(factor)
    }

    const cropChanged = ({ coordinates: { top: y, left: x, width, height } }: {
      coordinates: VisibleArea;
    }) => {
      croppedValue.value = {
        ...value.value,
        x,
        y,
        width,
        height,
      }
    }

    const update = () => {
      inputEl.value?.$el?.lastChild?.click()
    }

    const remove = (event: Event) => {
      event.preventDefault()
      // error.value = null
      emit('input', null)
    }

    const getLabel = computed(() => (customLabel.value ?? i18n.t('APP_WRITE_UPLOAD_IMAGE')) as string)
    const getAspect = computed(() => `tw-aspect-${aspect.value}`)

    const preventClickUpload = (event: Event) => {
      if (fullImageURL.value) {
        event.preventDefault()
      }
    }

    return {
      localError,
      cropper,
      zoom,
      croppedValue,
      inputEl,
      upload,
      remove,
      update,
      getLabel,
      getAspect,
      uploading,
      fullImageURL,
      isComponentModalActive,
      cropChanged,
      updateCropped,
      closeCropper,
      aspectRatio,
      preventClickUpload,
    }
  },
}) as unknown
