






































import { Publication, PublicationList } from '@kessel/core'
import { PropType, computed, defineComponent, toRefs, useContext } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { useDomain } from '~/stores/domain'
import { usePublication } from '~/stores/publication'
import { useRights } from '~/stores/right'

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<PublicationList>,
      required: false,
      default: null,
    },
    checkboxGroupRef: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props) {
    const { data } = toRefs(props)
    const { pathToPublication } = useDomain()
    const { subscriptionError, userCanSubscribe, subscriptionLevelsLabel, findSubscription } = storeToRefs(useRights())
    const { redirect } = useContext()
    const { getAuthorName, getAuthorPublication } = usePublication()

    const pathToPub = computed(() => pathToPublication(data.value as Publication))

    const author = computed(() => getAuthorName(getAuthorPublication(data.value as Publication)))

    const goToPublication = () => {
      redirect(pathToPub.value)
    }

    return {
      author,
      pathToPub,
      goToPublication,
      userCanSubscribe,
      subscriptionLevelsLabel,
      subscriptionError,
      findSubscription,
    }
  },
})
