




















































































import { computed, defineComponent, ref, toRefs, useRoute, useContext } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { useAuth } from '~/stores/auth'
import { useUser } from '~/stores/user'

export default defineComponent({
  inheritAttrs: false,
  props: {
    linkLogout: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { linkLogout } = toRefs(props)
    const navigation = ref(false)
    const { logout } = useAuth()
    const { user, isLogged } = storeToRefs(useUser())
    const route = useRoute()
    const { $config: { dashboardV2Url } } = useContext()

    const isDashboard = computed(() => route.value.name?.includes('dashboard'))
    const lOut = async () => {
      const defaultLink = await logout()
      console.info(linkLogout.value || defaultLink)
      window.location.href = linkLogout.value || defaultLink
    }

    const getImg = computed(() => user.value?.cropped_picture)

    return {
      isDashboard,
      navigation,
      logout,
      isLogged,
      lOut,
      getImg,
      dashboardUrl: dashboardV2Url,
    }
  },
})
