import {
  PaymentStatus,
  PostVisibilityLevel,
  SubscribedUser,
  Subscription,
  SubscriptionLevel,
} from '@kessel/core'
import { useContext } from '@nuxtjs/composition-api'
import { defineStore, storeToRefs } from 'pinia'
import { usePost } from '~/stores/post_v2'
import { usePublication } from '~/stores/publication_v2'
import { useSubscription } from '~/stores/subscription_v2'
import { useUser } from '~/stores/user'

interface State {
  subscription: Subscription | null
  subscribed: boolean
}

export const useRights = defineStore('right', {
  state: (): State => ({
    subscription: null,
    subscribed: false,
  }),
  getters: {
    userHasSubscribed: (state) => (subscription?: Subscription) => {
      return !!(subscription || state.subscription)
    },
    postIsPublic() {
      const { post } = storeToRefs(usePost())
      return !!post.value && post.value.visibility_level === PostVisibilityLevel.PUBLIC
    },
    userCanSubscribe: (state) => (subscription?: Subscription) => {
      const sub = subscription || state.subscription

      return !sub
    },
    userIsOwner: () => (pubId?: string) => {
      const { isOwnerOfPublication } = useUser()
      const publicationId = pubId || usePublication().publication?.id

      return publicationId ? isOwnerOfPublication(publicationId) : false
    },
    subscriptionLevel: (state) => {
      return state.subscription ? state.subscription.subscription_level : SubscriptionLevel.UNKNOWN
    },
    subscriptionLevelsLabel:
      (state) =>
        (subscription?: Subscription | Subscription['subscription_level'], short = false, feminine = false) => {
          const sub = typeof subscription !== 'number' ? subscription || state.subscription : subscription

          if (typeof sub !== 'number') {
            switch (sub?.payment_status) {
              case PaymentStatus.PAST_DUE:
                return 'SUB_PAST_DUE'
              default:
                break
            }
          }

          const getGender = (label: string) => {
            return feminine ? label + '_F' : label
          }

          switch (sub) {
            case SubscriptionLevel.FREE:
              return short ? getGender('FREE') : 'SUB_FREE'
            case SubscriptionLevel.MONTHLY:
              return short ? getGender('MONTHLY') : 'SUB_PAID'
            case SubscriptionLevel.YEARLY:
              return short ? getGender('YEARLY') : 'SUB_PAID'
            case SubscriptionLevel.PREMIUM:
              return short ? getGender('PREMIUM') : 'SUB_PREMIUM'
            case SubscriptionLevel.ONE_TIME:
              return short ? getGender('ONE_TIME') : 'BUY_ONE_TIME'
            default:
              return 'SUBSCRIBE'
          }
        },
    subscriptionError: (state) => (subscription?: Subscription) => {
      const sub = subscription || state.subscription
      return sub?.payment_status === PaymentStatus.PAST_DUE
    },
    shouldOpenSubscriptionDrawer() {
      const route = process.server ? useContext().route.value : window.$nuxt.$route

      const { publication } = usePublication()
      return !!route.query.subscribe && !!publication
    },
    shouldOpenRecommendPrompt() {
      const route = process.server ? useContext().route.value : window.$nuxt.$route

      return route.query.r === 'success'
    },
    findSubscription: () => (pubId: string, subscriptions?: Subscription[]) => {
      const sub = subscriptions || useSubscription().subscriptions || []
      return sub.find((sub) => sub.publication.id === pubId)
    },
  },
  actions: {
    async syncSubscriptionDrawer() {
      const { publication } = storeToRefs(usePublication())
      const { isLogged } = storeToRefs(useUser())
      const { getSubscriptionByPublicationId } = useSubscription()

      try {
        this.$patch(isLogged.value
          ? await getSubscriptionByPublicationId(publication.value?.id as string)
          : { subscribed: false, subscription: null }
        )
      } catch (e: any) {
        if (e.response.status !== 401) {
          console.error('syncSubscriptionDrawer - subscription - error', e)
        }
      }
    },
    forceCloseSubscriptionDrawer() {
      const {
        $nuxt: { redirect },
      } = this

      redirect({
        query: { ...this.$nuxt.route.query, landing: undefined, subscribe: undefined, r: undefined },
      })
    },
    isPaymentActive(subscriber: SubscribedUser | Subscription, boolean = false) {
      const { i18n, $dayjs } = useContext()
      const { subscriptionLevelsLabel } = this
      if (!subscriber.active_till) {
        return null
      }
      const isActive = subscriber.payment_status === PaymentStatus.ACTIVE
      const isFuture = $dayjs(subscriber.active_till).isAfter($dayjs())
      return boolean
        ? isActive && isFuture
        : i18n.t(isActive ? 'SUBSCRIPTION_DUE_DATE' : isFuture ? 'SUBSCRIPTION_ACTIVE_TO' : 'SUBSCRIPTION_STOP_AT', {
          date: i18n.d(new Date(subscriber.active_till), 'short'),
          type: i18n.t(subscriptionLevelsLabel(subscriber.subscription_level, true)),
        })
    },
  },
})
