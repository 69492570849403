


















































































import { computed, defineComponent, PropType, reactive, ref, toRefs, useContext } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'

import { Post } from '@kessel/core'
import { useThemeColor } from '~/composables/useTheme'
import { useApp } from '~/stores/app'
import { useDomain } from '~/stores/domain'
import { usePost } from '~/stores/post'
import { ButtonOptions, getUriWithParam, TYPESBUTTON } from '~/utils/tiptap'

export default defineComponent({
  props: {
    options: {
      type: Object as PropType<ButtonOptions>,
      required: true,
    },
  },
  setup: (props, { emit }) => {
    const { options } = toRefs(props)
    const { i18n, $config: { theme } } = useContext()
    const { post } = storeToRefs(usePost())
    const { pathToPost } = useDomain()
    const { appColors } = useApp()
    const { extractColorFromTheme } = useThemeColor()

    const colors = computed(() => appColors())

    const form: ButtonOptions = reactive({
      title: options.value.title || (options.value.type === TYPESBUTTON.SUBSCRIBE && i18n.t('SUBSCRIBE').toString()) || '',
      href: options.value.href || '',
      backgroundColor: extractColorFromTheme(options.value.backgroundColor || 'primary').hexColor,
      color: options.value.color || extractColorFromTheme('secondary').hexColor,
      textAlign: options.value.textAlign || 'center',
      type: String(options.value.type) || TYPESBUTTON.LINKBTN,
      fullWidth: options.value.fullWidth || false,
    })

    const types = ref([{
      label: String(i18n.t('EDITOR_BTN_TYPE')),
      value: TYPESBUTTON.LINKBTN,
    }, {
      label: String(i18n.t('EDITOR_TYPE_SUB')),
      value: TYPESBUTTON.SUBSCRIBE,
    }])

    const cancel = () => {
      emit('cancel')
    }

    const submit = () => {
      if (form.type === TYPESBUTTON.SUBSCRIBE) {
        form.href = getUriWithParam(pathToPost(post.value as Post), { subscribe: true })
      }

      const { isDark } = extractColorFromTheme(form.backgroundColor || theme.colors.primary)
      const { hexColor: hexColorBlack } = extractColorFromTheme('black')
      const { hexColor: hexColorWhite } = extractColorFromTheme('white')

      form.color = isDark ? hexColorWhite : hexColorBlack
      emit('submit', form)
    }

    return {
      subType: TYPESBUTTON.LINKBTN,
      colors,
      types,
      form,
      submit,
      cancel,
    }
  },
})
