import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9421ca1e = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _ed842e98 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _222e8f2c = () => interopDefault(import('../pages/authors.vue' /* webpackChunkName: "pages/authors" */))
const _1be1b8f5 = () => interopDefault(import('../pages/cgu.vue' /* webpackChunkName: "pages/cgu" */))
const _c162ce66 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2dacff10 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _9c647f86 = () => interopDefault(import('../pages/embed.vue' /* webpackChunkName: "pages/embed" */))
const _9fc20e0c = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _79b8b0ef = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _0f328832 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _27ce87de = () => interopDefault(import('../pages/register/1.vue' /* webpackChunkName: "pages/register/1" */))
const _27b258dc = () => interopDefault(import('../pages/register/2.vue' /* webpackChunkName: "pages/register/2" */))
const _279629da = () => interopDefault(import('../pages/register/3.vue' /* webpackChunkName: "pages/register/3" */))
const _2779fad8 = () => interopDefault(import('../pages/register/4.vue' /* webpackChunkName: "pages/register/4" */))
const _275dcbd6 = () => interopDefault(import('../pages/register/5.vue' /* webpackChunkName: "pages/register/5" */))
const _6176ef90 = () => interopDefault(import('../pages/legal-notice.vue' /* webpackChunkName: "pages/legal-notice" */))
const _014f80e7 = () => interopDefault(import('../pages/password.vue' /* webpackChunkName: "pages/password" */))
const _7e36e037 = () => interopDefault(import('../pages/posts.vue' /* webpackChunkName: "pages/posts" */))
const _62d2217a = () => interopDefault(import('../pages/posts/index.vue' /* webpackChunkName: "pages/posts/index" */))
const _0f2c2136 = () => interopDefault(import('../pages/posts/_id/index.vue' /* webpackChunkName: "pages/posts/_id/index" */))
const _1c620ae0 = () => interopDefault(import('../pages/posts/_id/_slug/index.vue' /* webpackChunkName: "pages/posts/_id/_slug/index" */))
const _0138700d = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _96bc0e60 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _3688332b = () => interopDefault(import('../pages/profile/account.vue' /* webpackChunkName: "pages/profile/account" */))
const _7bea6d15 = () => interopDefault(import('../pages/profile/settings.vue' /* webpackChunkName: "pages/profile/settings" */))
const _22b6df8a = () => interopDefault(import('../pages/publication-terms.vue' /* webpackChunkName: "pages/publication-terms" */))
const _f38b0c12 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _7421e1db = () => interopDefault(import('../pages/resubscribe.vue' /* webpackChunkName: "pages/resubscribe" */))
const _7e9917a2 = () => interopDefault(import('../pages/studio.vue' /* webpackChunkName: "pages/studio" */))
const _93af865c = () => interopDefault(import('../pages/why-kessel.vue' /* webpackChunkName: "pages/why-kessel" */))
const _8193207c = () => interopDefault(import('../pages/unsubscribe/email.vue' /* webpackChunkName: "pages/unsubscribe/email" */))
const _202e8cea = () => interopDefault(import('../pages/publication/create.vue' /* webpackChunkName: "pages/publication/create" */))
const _e0eea864 = () => interopDefault(import('../pages/publication/create/index.vue' /* webpackChunkName: "pages/publication/create/index" */))
const _d32de4a6 = () => interopDefault(import('../pages/publication/create/1.vue' /* webpackChunkName: "pages/publication/create/1" */))
const _d311b5a4 = () => interopDefault(import('../pages/publication/create/2.vue' /* webpackChunkName: "pages/publication/create/2" */))
const _ead700f6 = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _d5ba3260 = () => interopDefault(import('../pages/category/_slug/_tab.vue' /* webpackChunkName: "pages/category/_slug/_tab" */))
const _091bcebb = () => interopDefault(import('../pages/publication/_id.vue' /* webpackChunkName: "pages/publication/_id" */))
const _f6f9c604 = () => interopDefault(import('../pages/publication/_id/index.vue' /* webpackChunkName: "pages/publication/_id/index" */))
const _dcc40714 = () => interopDefault(import('../pages/publication/_id/drafts.vue' /* webpackChunkName: "pages/publication/_id/drafts" */))
const _2d13077f = () => interopDefault(import('../pages/publication/_id/posts.vue' /* webpackChunkName: "pages/publication/_id/posts" */))
const _238910a2 = () => interopDefault(import('../pages/publication/_id/referrals.vue' /* webpackChunkName: "pages/publication/_id/referrals" */))
const _b6489bb2 = () => interopDefault(import('../pages/publication/_id/settings.vue' /* webpackChunkName: "pages/publication/_id/settings" */))
const _e6a4812c = () => interopDefault(import('../pages/publication/_id/settings/index.vue' /* webpackChunkName: "pages/publication/_id/settings/index" */))
const _2ba3f46b = () => interopDefault(import('../pages/publication/_id/settings/contacts.vue' /* webpackChunkName: "pages/publication/_id/settings/contacts" */))
const _47079602 = () => interopDefault(import('../pages/publication/_id/settings/customise.vue' /* webpackChunkName: "pages/publication/_id/settings/customise" */))
const _453f2f54 = () => interopDefault(import('../pages/publication/_id/settings/design.vue' /* webpackChunkName: "pages/publication/_id/settings/design" */))
const _5dcecebd = () => interopDefault(import('../pages/publication/_id/settings/import.vue' /* webpackChunkName: "pages/publication/_id/settings/import" */))
const _1a6bd194 = () => interopDefault(import('../pages/publication/_id/settings/options.vue' /* webpackChunkName: "pages/publication/_id/settings/options" */))
const _a5afbb58 = () => interopDefault(import('../pages/publication/_id/settings/recommend.vue' /* webpackChunkName: "pages/publication/_id/settings/recommend" */))
const _5a725ab5 = () => interopDefault(import('../pages/publication/_id/settings/referral.vue' /* webpackChunkName: "pages/publication/_id/settings/referral" */))
const _00d5f835 = () => interopDefault(import('../pages/publication/_id/settings/subscription.vue' /* webpackChunkName: "pages/publication/_id/settings/subscription" */))
const _39160a4b = () => interopDefault(import('../pages/publication/_id/stats.vue' /* webpackChunkName: "pages/publication/_id/stats" */))
const _0ff89c92 = () => interopDefault(import('../pages/publication/_id/subscribers.vue' /* webpackChunkName: "pages/publication/_id/subscribers" */))
const _60d1fd8b = () => interopDefault(import('../pages/publication/_id/write.vue' /* webpackChunkName: "pages/publication/_id/write" */))
const _52c95d2a = () => interopDefault(import('../pages/publication/_id/templates/_templateId.vue' /* webpackChunkName: "pages/publication/_id/templates/_templateId" */))
const _77d74941 = () => interopDefault(import('../pages/search/_tab.vue' /* webpackChunkName: "pages/search/_tab" */))
const _220779bc = () => interopDefault(import('../pages/search/_tab/_slug.vue' /* webpackChunkName: "pages/search/_tab/_slug" */))
const _54b21494 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _9421ca1e,
    children: [{
      path: "",
      component: _ed842e98,
      name: "about___fr"
    }]
  }, {
    path: "/auteurs",
    component: _222e8f2c,
    name: "authors___fr"
  }, {
    path: "/cgu",
    component: _1be1b8f5,
    name: "cgu___fr"
  }, {
    path: "/connexion",
    component: _c162ce66,
    name: "login___fr"
  }, {
    path: "/dashboard",
    component: _2dacff10,
    name: "dashboard___fr"
  }, {
    path: "/embed",
    component: _9c647f86,
    name: "embed___fr"
  }, {
    path: "/faq",
    component: _9fc20e0c,
    name: "faq___fr"
  }, {
    path: "/inscription",
    component: _79b8b0ef,
    children: [{
      path: "",
      component: _0f328832,
      name: "register___fr"
    }, {
      path: "1",
      component: _27ce87de,
      name: "register-1___fr"
    }, {
      path: "2",
      component: _27b258dc,
      name: "register-2___fr"
    }, {
      path: "3",
      component: _279629da,
      name: "register-3___fr"
    }, {
      path: "4",
      component: _2779fad8,
      name: "register-4___fr"
    }, {
      path: "5",
      component: _275dcbd6,
      name: "register-5___fr"
    }]
  }, {
    path: "/legal-notice",
    component: _6176ef90,
    name: "legal-notice___fr"
  }, {
    path: "/password",
    component: _014f80e7,
    name: "password___fr"
  }, {
    path: "/posts",
    component: _7e36e037,
    children: [{
      path: "",
      component: _62d2217a,
      name: "posts___fr"
    }, {
      path: ":id",
      component: _0f2c2136,
      name: "posts-id___fr"
    }, {
      path: ":id/:slug",
      component: _1c620ae0,
      name: "posts-id-slug___fr"
    }]
  }, {
    path: "/profile",
    component: _0138700d,
    children: [{
      path: "",
      component: _96bc0e60,
      name: "profile___fr"
    }, {
      path: "account",
      component: _3688332b,
      name: "profile-account___fr"
    }, {
      path: "settings",
      component: _7bea6d15,
      name: "profile-settings___fr"
    }]
  }, {
    path: "/publication-terms",
    component: _22b6df8a,
    name: "publication-terms___fr"
  }, {
    path: "/recherche",
    component: _f38b0c12,
    name: "search___fr"
  }, {
    path: "/reinscription",
    component: _7421e1db,
    name: "resubscribe___fr"
  }, {
    path: "/studio",
    component: _7e9917a2,
    name: "studio___fr"
  }, {
    path: "/why-kessel",
    component: _93af865c,
    name: "why-kessel___fr"
  }, {
    path: "/desinscription/email",
    component: _8193207c,
    name: "unsubscribe-email___fr"
  }, {
    path: "/publication/create",
    component: _202e8cea,
    children: [{
      path: "",
      component: _e0eea864,
      name: "publication-create___fr"
    }, {
      path: "1",
      component: _d32de4a6,
      name: "publication-create-1___fr"
    }, {
      path: "2",
      component: _d311b5a4,
      name: "publication-create-2___fr"
    }]
  }, {
    path: "/categorie/:slug",
    component: _ead700f6,
    name: "category-slug___fr",
    children: [{
      path: ":tab?",
      component: _d5ba3260,
      name: "category-slug-tab___fr"
    }]
  }, {
    path: "/publication/:id?",
    component: _091bcebb,
    children: [{
      path: "",
      component: _f6f9c604,
      name: "publication-id___fr"
    }, {
      path: "drafts",
      component: _dcc40714,
      name: "publication-id-drafts___fr"
    }, {
      path: "posts",
      component: _2d13077f,
      name: "publication-id-posts___fr"
    }, {
      path: "referrals",
      component: _238910a2,
      name: "publication-id-referrals___fr"
    }, {
      path: "settings",
      component: _b6489bb2,
      children: [{
        path: "",
        component: _e6a4812c,
        name: "publication-id-settings___fr"
      }, {
        path: "contacts",
        component: _2ba3f46b,
        name: "publication-id-settings-contacts___fr"
      }, {
        path: "customise",
        component: _47079602,
        name: "publication-id-settings-customise___fr"
      }, {
        path: "design",
        component: _453f2f54,
        name: "publication-id-settings-design___fr"
      }, {
        path: "import",
        component: _5dcecebd,
        name: "publication-id-settings-import___fr"
      }, {
        path: "options",
        component: _1a6bd194,
        name: "publication-id-settings-options___fr"
      }, {
        path: "recommend",
        component: _a5afbb58,
        name: "publication-id-settings-recommend___fr"
      }, {
        path: "referral",
        component: _5a725ab5,
        name: "publication-id-settings-referral___fr"
      }, {
        path: "subscription",
        component: _00d5f835,
        name: "publication-id-settings-subscription___fr"
      }]
    }, {
      path: "stats",
      component: _39160a4b,
      name: "publication-id-stats___fr"
    }, {
      path: "subscribers",
      component: _0ff89c92,
      name: "publication-id-subscribers___fr"
    }, {
      path: "write",
      component: _60d1fd8b,
      name: "publication-id-write___fr"
    }, {
      path: "templates/:templateId?",
      component: _52c95d2a,
      name: "publication-id-templates-templateId___fr"
    }]
  }, {
    path: "/recherche/:tab",
    component: _77d74941,
    name: "search-tab___fr",
    children: [{
      path: ":slug?",
      component: _220779bc,
      name: "search-tab-slug___fr"
    }]
  }, {
    path: "/",
    component: _54b21494,
    name: "index___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
