import { RecommendationsQuery } from '@algolia/recommend'
import { BaseObjectWithObjectID, ObjectWithObjectID, SearchItem, SearcheableIndexes } from '@kessel/core'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import { defineStore } from 'pinia'
import slugify from 'slugify'
import { useDomain } from './domain'
import { usePost } from './post'
import { usePublication } from './publication'
import { useUser } from './user'
import { applyFilterQuery } from '~/utils/search'

interface State {
  query: string
  results: ObjectWithObjectID[]
  recommendsResults: ObjectWithObjectID[]
  isFetching: boolean
}

const MAX_RECOMMENDATIONS = 4
const MIN_THRESHOLD = 15

export const useSearch = defineStore('search', {
  state: (): State => ({
    query: '',
    results: [],
    recommendsResults: [],
    isFetching: false,
  }),
  getters: {
    recommendations: (state): SearchItem[] => {
      const { recommendsResults } = state

      const hits = recommendsResults.reduce((aggr: SearchItem[], { hits }: BaseObjectWithObjectID) => [...aggr, ...hits], [])

      return uniqBy(
        orderBy(
          hits.map((hit: SearchItem) => ({
            id: hit.objectID,
            ...hit,
          })),
          ['_score'],
          ['desc']
        ),
        'objectID'
      )
    },
  },
  actions: {
    resultsByIndexType(type: SearcheableIndexes = SearcheableIndexes.POST): ObjectWithObjectID {
      const { results } = this

      const foundedIndex = results.find(({ index }: ObjectWithObjectID) => index === type) || {
        index: type,
        hits: [],
        query: this.query,
      }

      return {
        ...foundedIndex,
        hits: uniqBy(
          foundedIndex?.hits.map((hit: SearchItem) => ({
            id: hit.objectID,
            ...hit,
          })),
          'objectID'
        ),
      } as ObjectWithObjectID
    },
    goToSearchPage() {
      const {
        $nuxt: { localePath, redirect },
      } = this

      redirect({
        path: localePath({
          name: 'search-tab-slug',
          params: {
            tab: SearcheableIndexes.POST,
            slug: slugify(this.query || '', { replacement: '+' }),
          },
        }),
      })
    },
    async goToResult(item: SearchItem) {
      const { pathToPublication, pathToPost } = useDomain()
      const { getPost } = usePost()
      const { getPublication } = usePublication()

      const isPost = !!item.objectID.includes('pst_')

      if (isPost) {
        const post = await getPost(item.objectID)
        window.location.href = pathToPost(post)
      }

      const publication = await getPublication(item.objectID)

      window.location.href = pathToPublication(publication)
    },

    async recommends(
      objectIDs: string[],
      {
        threshold = MIN_THRESHOLD,
        max = MAX_RECOMMENDATIONS,
        exludedIds = [],
      }: {
        threshold?: number
        max?: number
        exludedIds?: string[]
      } = {}
    ) {
      const { $patch } = this
      const { publications } = usePublication()

      $patch({
        isFetching: true,
      })

      const exludedIdsFull = [...publications?.map((publication) => publication.id), ...exludedIds]

      const maxRecommendations = Math.ceil((1 / objectIDs.length) * max)

      const queries: RecommendationsQuery[] = objectIDs.map((objectID) => ({
        // fallbackParameters,
        maxRecommendations,
        model: 'related-products',
        indexName: SearcheableIndexes.PUBLICATION,
        objectID,
        queryParameters: {
          filters: applyFilterQuery([
            ...[...objectIDs, ...exludedIdsFull].map((objectID) => [`NOT objectID:${objectID}`]),
            ['total_posts > 0', 'publication.total_posts > 0'],
          ]),
        },
        threshold,
      }))

      try {
        const { results } = await this.$nuxt.$recommendClient?.getRecommendations(queries)

        $patch({
          isFetching: false,
          recommendsResults: results as ObjectWithObjectID[],
        })
      } catch (e) {
        console.error(e)
      }
    },
    async search(query: string, indexes: SearcheableIndexes[] = [SearcheableIndexes.PUBLICATION, SearcheableIndexes.POST]) {
      const { $patch } = this

      const { user } = useUser()

      $patch({
        query,
        isFetching: true,
      })

      const { results: algoliaResults } = await this.$nuxt.$searchClient.search(
        indexes.map((indexName) => ({
          indexName,
          query,
          params: {
            hitsPerPage: 10,
            filters: applyFilterQuery([['total_posts > 0', 'publication.total_posts > 0']]),
            ...(user && {
              enablePersonalization: true,
              clickAnalytics: true,
              ...(user?.id && {
                userToken: user.id,
              }),
            }),
          },
        }))
      )

      $patch({
        isFetching: false,
        results: algoliaResults as ObjectWithObjectID[],
      })
    },
  },
})
