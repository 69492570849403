import { ImageCropped } from '../types'
import { Post } from './post'
import { Publication } from './publication'
import { Owner } from './user'

export enum SearcheableIndexes {
  POST = 'post',
  PUBLICATION = 'publication',
  AUTHORS = 'author',
}

export interface SearchItem extends Partial<Omit<Post, 'subdomain' | 'body_draft'>>, Partial<Publication>, Partial<Owner> {
  objectID: string
  queryID?: string
  indexName?: SearcheableIndexes
  title?: string
  description?: string
  image?: ImageCropped
}

export interface BaseObjectWithObjectID {
  hits: SearchItem[]
}
export interface ObjectWithObjectID extends BaseObjectWithObjectID {
  index: SearcheableIndexes
  query: string
}

export interface SearchTabs {
  name: string
  label: string
  count?: number
}
