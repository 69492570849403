


























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Object[]>,
      default: () => [],
    },
  },
  setup() {
    const dropdownActive = ref(false)
    return { dropdownActive }
  },
})
