import { Context } from '@nuxt/types'
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import axios from 'axios'
import { usePost } from '~/stores/post_v2'
import { useStats } from '~/stores/stats'

export default defineNuxtMiddleware(async (ctx: Context) => {
  const { route, redirect, localePath, error } = ctx

  const { syncPost, getPostSlug } = usePost(ctx.$pinia)
  const { post } = storeToRefs(usePost(ctx.$pinia))
  const { addPostStats } = useStats(ctx.$pinia)

  try {
    await syncPost(route.params.id)
  } catch (e) {
    error({ statusCode: 404, message: 'Post not found' })
    return
  }

  try {
    await addPostStats(route.params.id)
  } catch (err) {
    console.error(`Error adding post stats for ${route.params.id}: ${err}`)
    if (axios.isAxiosError(err) && err.response) {
      // eslint-disable-next-line no-console
      console.log(err.response)
    }
  }

  if (!route.params.slug && post?.value?.title) {
    redirect(
      localePath({
        name: 'posts-id-slug',
        params: { id: route.params.id, slug: getPostSlug(post.value) },
        query: { ...route.query },
      })
    )
  }
})
