import { DEFAULT_LOGO_PLACEHOLDER } from '@kessel/core'
import { ProviderGetImage } from '@nuxt/image'
import { operationsGenerator } from '@nuxt/image/dist/runtime/providers/imgix'
import { joinURL } from 'ufo'

export const getImage: ProviderGetImage = (src, { modifiers = {}, baseURL = '' } = {}) => {
  if (!src || src === DEFAULT_LOGO_PLACEHOLDER) {
    src = DEFAULT_LOGO_PLACEHOLDER
    modifiers = {
      ...modifiers,
      bg: '000000',
    }
  }

  const operations = operationsGenerator(modifiers)

  return {
    url: joinURL(baseURL, src + (operations ? '?' + operations : '')),
  }
}

export const validateDomains = true
export const supportsAlias = true
