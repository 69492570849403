import { Editor, Extension } from '@tiptap/core'
import { NodeWithPos } from '@tiptap/vue-2'
import { Node as ProseMirrorNode } from 'prosemirror-model'
import { Plugin, PluginKey, Selection } from 'prosemirror-state'
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view'
import Vue from 'vue'

import PaywallSeparatorVue from '~/components/editor/components/PaywallSeparator.vue'

const PaywallSeparatorComponent = Vue.extend(PaywallSeparatorVue)

export interface PaywallSeparatorOptions {
  class: string
  label: string
  enabled: boolean
  position: number
  scrollerElement?: HTMLElement
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    paywallSeparator: {
      /**
       * Insert a embed code
       */
      setPaywallSeparator: (options: { enabled: boolean; position?: number }) => ReturnType
      togglePaywallSeparator: () => ReturnType
    }
  }
}

export interface PaywallSeparatorStorage {
  /**
   * Get the new position of the paywall separator.
   */
  position: number
}

export const PaywallSeparator = Extension.create<PaywallSeparatorOptions, PaywallSeparatorStorage>({
  name: 'paywallSeparator',

  editor: Editor,
  editorView: EditorView,

  addOptions() {
    return {
      class: 'paywall-separator',
      label: 'Paywall',
      position: 2,
      enabled: false,
    }
  },

  addStorage() {
    return {
      position: this.options.position,
    }
  },

  onBeforeCreate() {
    this.storage.position = this.options.position
  },

  addAttributes() {
    // Return an object with attribute configuration
    return {
      class: { default: null },
      label: { default: null },
      position: { default: 2 },
    }
  },

  addCommands() {
    return {
      setPaywallSeparator: ({ enabled, position }) => {
        return ({ tr }) => {
          // Validate max and min separator based on position and options
          this.options.enabled = enabled
          const selectedBlocks: NodeWithPos[] = []

          let foundedIndex = 0

          if (!position) {
            try {
              tr.doc.nodesBetween(tr.selection.from, tr.selection.to, (node, pos) => {
                selectedBlocks.push({ node, pos })
              })
              const filteredBlocks = selectedBlocks.filter((child) => child.node.isBlock)

              const [block] = filteredBlocks

              tr.doc.descendants((node, _, __, index) => {
                if (node === block.node && !foundedIndex) {
                  // Update the new widget position in the storage
                  foundedIndex = index
                }
              })
            } catch (error) {
              console.error(error)
            }
          }

          this.storage.position = position || foundedIndex || this.options.position

          return enabled
        }
      },
      togglePaywallSeparator: () => {
        return () => {
          return this.editor.commands.setPaywallSeparator({ enabled: !this.options.enabled, ...(this.options.enabled && { position: -1 }) })
        }
      },
    }
  },

  addProseMirrorPlugins() {
    const plugin: PluginKey = new PluginKey(this.name)

    const handleDragStart = (e: Event) => {
      const element = e.target as HTMLElement

      element.classList.toggle('drag')
    }

    const handleDragEnd = (e: Event) => {
      const element = e.target as HTMLElement
      element.classList.remove('drag')

      const ghost = document.getElementById('drag-ghost')
      if (ghost?.parentNode) {
        ghost.parentNode.removeChild(ghost)
      }
    }

    const handleDrop = (e: Event) => {
      const element = e.target as HTMLElement
      element.classList.remove('drag')
    }

    const findBlockNodes = (doc: ProseMirrorNode, selection: Pick<Selection, 'from' | 'to'>) => {
      const results: NodeWithPos[] = []
      try {
        doc.nodesBetween(selection.from, selection.to, (node, pos) => {
          results.push({ node, pos })
        })
      } catch (error) {
        console.error(error)
      }
      return results.filter((child) => child.node.isBlock)
    }

    const removeWidget = (view: EditorView) => {
      const state: DecorationSet = plugin.getState(view.state)
      const transactions: any[] = view.state.tr.getMeta('dropTransaction') || state.find() || []
      const [dropTransaction] = transactions || []

      const selection = view.state.selection.from - dropTransaction?.from || 0
      if (transactions.length && selection === 1) {
        state.remove(transactions)
        this.options.enabled = false
      }
    }

    const createWidget = () => {
      const paywallWidget: any = new PaywallSeparatorComponent({
        propsData: {
          class: this.options.class,
          label: this.options.label,
          size: 'sm',
        },
      }).$mount()

      paywallWidget.$el.addEventListener('dragstart', handleDragStart)
      paywallWidget.$el.addEventListener('dragend', handleDragEnd)
      paywallWidget.$el.addEventListener('drop', handleDrop)

      return paywallWidget
    }

    const decorateNodes = (nodes: NodeWithPos[]) => {
      const widget = createWidget()
      return nodes
        .map((item) => [
          Decoration.widget(item.pos, widget.$el, {
            ignoreSelection: false,
            destroy: () => {
              return widget.$destroy()
            },
          }),
        ])
        .flat()
    }

    return [
      new Plugin({
        key: plugin,
        props: {
          decorations(state) {
            return plugin.getState(state)
          },

          handleKeyDown(view: EditorView, event) {
            switch (event.key) {
              case 'Delete':
              case 'Backspace': {
                removeWidget(view)
                break
              }

              default:
                break
            }

            return false
          },

          handleDOMEvents: {
            dragover(_, event: DragEvent | any) {
              if (!event) {
                return false
              }

              event.preventDefault()

              const element = event.target as HTMLElement

              element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
              })
              return true
            },
          },

          handleDrop(view, event: DragEvent | any): boolean {
            if (!event) {
              return false
            }

            event.preventDefault()

            const coordinates = view.posAtCoords({
              left: event.clientX,
              top: event.clientY,
            })

            if (coordinates) {
              const dropTransaction = view.state.tr.setMeta('dropTransaction', [coordinates])
              view.dispatch(dropTransaction)
              return true
            }

            return false
          },
        },
        filterTransaction: (transaction) => {
          // For pasted content, we try to remove the exceeding content.
          const pos = transaction.selection.$head.pos

          return !!pos && !!transaction.doc.childCount
        },
        state: {
          init: (_, state) => {
            return DecorationSet.create(state.doc, [])
          },

          apply: (tr, state) => {
            if (!this.options.enabled) {
              return DecorationSet.empty
            }

            let block: NodeWithPos | undefined

            try {
              const childAtPos = tr.doc.content.child(Math.min(this.storage.position, tr.doc.content.childCount - 1))

              tr.doc.descendants((node, pos) => {
                if (node === childAtPos) {
                  // Update the new widget position in the storage
                  block = { node, pos }
                }
              })
            } catch (error) {
              console.error(error)
            }

            const decorations: NodeWithPos[] = tr.getMeta('dropTransaction') || [block]

            const [dropTransaction] = decorations || []

            if (dropTransaction && !tr.docChanged) {
              // Find the target block from the drop position
              const blocks = findBlockNodes(tr.doc, { from: dropTransaction.pos, to: dropTransaction.pos + 1 })

              block = blocks.at(0)

              let founded = false

              if (block) {
                tr.doc.descendants((node, pos, __, index) => {
                  if (node === block?.node && !founded) {
                    // Update the new widget position in the storage
                    this.storage.position = index

                    block = { node, pos }
                    founded = true
                  }
                })
              }

              return (block && DecorationSet.create(tr.doc, decorateNodes([block]))) || state
            }

            return state
          },
        },
      }),
    ]
  },
})
