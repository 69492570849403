import { Context } from '@nuxt/types'
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { usePublication } from '~/stores/publication'
import { useUser } from '~/stores/user'

export default defineNuxtMiddleware(async (ctx: Context) => {
  const { publication, syncOwnPublication } = usePublication(ctx.$pinia)
  const { isOwnerOfPublication } = useUser(ctx.$pinia)
  const { route, redirect, localePath } = ctx

  if (!publication || publication.id !== route.params.id) {
    await syncOwnPublication(route.params.id)
  }

  if (!isOwnerOfPublication(route.params.id)) {
    await redirect(localePath({ name: 'dashboard' }))
  }
})
