import { Context } from '@nuxt/types/app'
import { defineNuxtPlugin, markRaw } from '@nuxtjs/composition-api'
// eslint-disable-next-line import/named
import { Pinia } from 'pinia'

declare module '@nuxt/types' {
  interface Context {
    $pinia: Pinia
  }
}

declare module 'pinia' {
  export interface PiniaCustomProperties {
    $nuxt: Context
  }
}

export default defineNuxtPlugin(({ $pinia, app }) => {
  $pinia.use(({ store }) => {
    store.$router = app.router && markRaw(app.router)
    store.$route = markRaw(app.context.route)
  })
})
