import { DEFAULT_LOGO_PLACEHOLDER, ImageCropped, MetaBuilder, MetaInfoHostEnv, Post, Publication } from '@kessel/core'
import { useContext } from '@nuxtjs/composition-api'
import get from 'lodash/get'
import truncate from 'lodash/truncate'
import { defineStore } from 'pinia'
import { usePublication } from './publication_v2'
import { useDomain } from '~/stores/domain'

// This interface must remain private
interface State {}

const LIMIT_DESCRIPTION_LENGTH = 100

export const useMetaInfo = defineStore('metaInfo', {
  state: (): State => ({}),
  getters: {},
  actions: {
    metaBuilder(
      { path }: MetaInfoHostEnv,
      {
        title,
        description: descriptionProps,
        type,
        image = { filename: DEFAULT_LOGO_PLACEHOLDER },
        locale,
        publishedTime,
        author,
        themeColor,
        twitterCard,
        twitterImage = { filename: DEFAULT_LOGO_PLACEHOLDER },
        twitterCreator,
      }: MetaBuilder
    ) {
      const { i18n, $img } = useContext()
      const modifiers = {
        width: 1200,
        height: 630,
        fit: 'contain',
        fill: 'blur',
        auto: 'compress',
        fm: 'jpg',
      }
      const description = truncate(descriptionProps, {
        omission: '...',
        length: LIMIT_DESCRIPTION_LENGTH,
        separator: ' ',
      })

      let publishedTimeParsed = null
      if (publishedTime) {
        const exec = /.*(?=:)/gm.exec(publishedTime)
        if (exec) {
          publishedTimeParsed = exec[0]
        }
      }

      return {
        title,
        htmlAttrs: {
          lang: i18n?.locale as string,
        },
        link: [
          {
            hid: 'canonical',
            rel: 'canonical',
            href: path.canonical,
          },
        ],
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: description,
          },

          {
            hid: 'og:title',
            property: 'og:title',
            content: title,
          },
          {
            hid: 'og:type',
            property: 'og:type',
            content: type,
          },
          ...(image
            ? [
                {
                  hid: 'og:image',
                  property: 'og:image',
                  content: $img.getImage(this.checkImage(image), {
                    modifiers: {
                      ...modifiers,
                      rect: `${image.x || 0},${image.y || 0},${image.width || 0},${image.height || 0}`,
                    },
                  }).url,
                },
                {
                  hid: 'og:image:secure_url',
                  property: 'og:image:secure_url',
                  content: $img.getImage(this.checkImage(image), {
                    modifiers: {
                      ...modifiers,
                      rect: `${image.x || 0},${image.y || 0},${image.width || 0},${image.height || 0}`,
                    },
                  }).url,
                },
                {
                  hid: 'og:image:width',
                  property: 'og:image:width',
                  content: '1200',
                },
                {
                  hid: 'og:image:height',
                  property: 'og:image:height',
                  content: '630',
                },
              ]
            : []),
          {
            hid: 'og:url',
            property: 'og:url',
            content: path.canonical,
          },
          {
            hid: `${type}:publisher`,
            property: `${type}:publisher`,
            content: path.subdomain,
          },
          // ---- not required ----- //
          {
            hid: 'og:description',
            property: 'og:description',
            content: description,
          },
          {
            hid: 'og:locale',
            property: 'og:locale',
            content: locale || 'fr_FR',
          },
          {
            hid: 'og:site_name',
            property: 'og:site_name',
            content: (i18n?.t('KESSEL') as string) || 'Kessel',
          },
          ...(themeColor
            ? [
                {
                  hid: 'theme-color',
                  name: 'theme-color',
                  content: themeColor,
                },
              ]
            : []),
          ...(author
            ? [
                {
                  hid: 'publisher',
                  name: 'publisher',
                  content: author,
                },
                {
                  hid: 'author',
                  name: 'author',
                  content: author,
                },
                {
                  hid: `${type}:author`,
                  property: `${type}:author`,
                  content: author,
                },
              ]
            : []),
          ...(publishedTimeParsed
            ? [
                {
                  hid: type + ':published_time',
                  property: type + ':published_time',
                  content: publishedTimeParsed,
                },
              ]
            : []),
          ...(publishedTime
            ? [
                {
                  hid: 'og:publish_date',
                  property: 'og:publish_date',
                  content: publishedTime,
                },
              ]
            : []),
          // ----------------------- //

          ...(title
            ? [
                {
                  hid: 'twitter:title',
                  property: 'twitter:title',
                  content: title,
                },
              ]
            : []),

          {
            hid: 'twitter:card',
            property: 'twitter:card',
            content: twitterCard || 'summary_large_image',
          },
          // ---- not required ----- //
          ...(twitterCreator
            ? [
                {
                  hid: 'twitter:site',
                  property: 'twitter:site',
                  content: twitterCreator,
                },
                {
                  hid: 'twitter:creator',
                  property: 'twitter:creator',
                  content: twitterCreator,
                },
              ]
            : []),
          ...(description
            ? [
                {
                  hid: 'twitter:description',
                  property: 'twitter:description',
                  content: description,
                },
              ]
            : []),
          ...(twitterImage
            ? [
                {
                  hid: 'twitter:image',
                  property: 'twitter:image',
                  content: $img.getImage(this.checkImage(twitterImage), {
                    modifiers: {
                      ...modifiers,
                      rect: `${twitterImage.x || 0},${twitterImage.y || 0},${twitterImage.width || 0},${
                        twitterImage.height || 0
                      }`,
                    },
                  }).url,
                },
              ]
            : []),
          // ----------------------- //
        ],
      }
    },
    getPath(publication?: Publication, post?: Post) {
      const { pathToPublication, pathToPost } = useDomain()
      let path

      if (publication && !post) {
        path = {
          subdomain: pathToPublication(publication),
          canonical: pathToPublication(publication),
        }
      } else if (publication && post) {
        path = {
          subdomain: pathToPublication(publication),
          canonical: pathToPost(post),
        }
      } else {
        path = {
          subdomain: pathToPublication(null),
          canonical: pathToPublication(null),
        }
      }

      return { path } as MetaInfoHostEnv
    },
    checkImage(image: ImageCropped | string) {
      if (!image) {
        return DEFAULT_LOGO_PLACEHOLDER
      }
      if (typeof image === 'string') {
        return image || DEFAULT_LOGO_PLACEHOLDER
      }
      if (typeof image === 'object' && !!image.filename) {
        return image.filename || DEFAULT_LOGO_PLACEHOLDER
      }
      return DEFAULT_LOGO_PLACEHOLDER
    },
    getHomepageMeta() {
      const { i18n } = useContext()

      const image: ImageCropped = { filename: '/static/logo-social.jpg' }

      return this.metaBuilder(this.getPath(), {
        title: i18n?.t('KESSEL_TITLE') as string,
        description: i18n?.t('KESSEL_DESCRIPTION') as string,
        type: 'website',
        image,
        twitterImage: image,
      })
    },
    getPublicationMeta(publication: Publication | null) {
      const { $config, i18n } = useContext()
      const { getAuthorName } = usePublication()

      if (publication) {
        const owner = get(publication, 'owners[0]')
        const authorName = getAuthorName(owner)

        return this.metaBuilder(this.getPath(publication), {
          title: i18n?.t('KESSEL_NEWSLETTER_TITLE', {
            title: publication.title,
            authorName,
          }) as string,
          description: i18n?.t('NEWSLETTER_DESCRIPTION', {
            description: publication.description,
            authorName,
          }) as string,
          type: 'blog',
          image: publication.cropped_banner_picture,
          themeColor: publication.color_primary || $config.theme.colors.primary,
          twitterImage: publication.cropped_banner_picture,
        })
      }
      return this.getHomepageMeta()
    },
    getPostMeta(publication: Publication | null, post: Post | null) {
      const { $config } = useContext()
      const { getAuthorName } = usePublication()

      const owner = get(publication, 'owners[0]')
      const authorName = getAuthorName(owner)

      if (!post || !publication) {
        return this.getHomepageMeta()
      }

      return this.metaBuilder(this.getPath(publication, post), {
        title: post.title,
        description: post.description,
        type: 'article',
        ...(post.last_updated && { publishedTime: post.last_updated }),
        image: post.cropped_image,
        themeColor: publication.color_primary || $config.theme.colors.primary,
        twitterImage: post.cropped_image,
        ...(owner && {
          author: authorName,
        }),
      })
    },
    getCategoryMeta(categoryTitle: string) {
      const { i18n } = useContext()

      return this.metaBuilder(this.getPath(), {
        title: i18n?.t('KESSEL_CATEGORY_TITLE', { category: categoryTitle }) as string,
        description: i18n?.t('KESSEL_CATEGORY_DESCRIPTION', { category: categoryTitle }) as string,
        type: 'website',
      })
    },
    getDashboardMeta(fullName: string) {
      const { i18n } = useContext()

      return this.metaBuilder(this.getPath(), {
        title: i18n?.t('KESSEL_DASHBOARD_TITLE', { fullName }) as string,
        description: i18n?.t('KESSEL_DASHBOARD_DESCRIPTION') as string,
        type: 'website',
      })
    },
    getEditorMeta(newsletterTitle: string, name: string, postId: string) {
      const { i18n } = useContext()

      return this.metaBuilder(this.getPath(), {
        title: i18n?.t('KESSEL_EDITOR_TITLE', { newsletterTitle, name, postId }) as string,
        description: i18n?.t('KESSEL_EDITOR_DESCRIPTION') as string,
        type: 'website',
      })
    },
    getDefaultMeta(title: string, description?: string, image?: ImageCropped) {
      const { i18n } = useContext()

      return this.metaBuilder(this.getPath(), {
        title: i18n?.t(title) as string,
        description: i18n?.t(description || 'KESSEL_DEFAULT_DESCRIPTION') as string,
        type: 'website',
        image,
        twitterImage: image,
      })
    },
  },
})
