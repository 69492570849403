import { defineStore } from 'pinia'
import slugify from 'slugify'

import {
  Pagination,
  Post,
  PostParameters,
  PostStatus,
  PostVisibilityLevel,
} from '@kessel/core'

import { usePublication } from '~/stores/publication_v2'

export interface State {
  posts: Post[]
  post: Post | null
  postPublished: Post | null
}

export const usePost = defineStore('post_v2', {
  state: (): State => ({
    posts: [],
    post: null,
    postPublished: null,
  }),
  getters: {
    getPostSlug: () => (post: Post) => slugify(post.title || '', { lower: true, strict: true }),
    getPostStatus: (): PostStatus[] => [
      { level: PostVisibilityLevel.PUBLIC, icon: 'users', text: 'EVERYONE' },
      { level: PostVisibilityLevel.SUBSCRIBERS, icon: 'user', text: 'MY_SUBSCRIBERS' },
      { level: PostVisibilityLevel.PAID_SUBSCRIBERS, icon: 'lock', text: 'MY_PAID_SUBSCRIBERS' },
    ],
    getPostActiveStatus: () => {
      return (status: PostStatus[], post: Post): PostStatus => {
        return Object.values(status).find((val) => val.level === post.visibility_level) || (status.at(0) as PostStatus)
      }
    },
    getPostsWithoutCurrent: (state) => {
      return (postId?: string): Post[] => {
        return state.posts.filter((post: Post) => post.id !== postId)
      }
    },
    getPinnedPost: (state) => (posts?: Post[]) =>
      (posts || state.posts).find(
        ({ id, published_at: publishedAt, is_draft: idDraft, pinned }: Post) => !!id && !!pinned && publishedAt && !idDraft
      ) as Post,
    getNotPinnedPosts: (state) => (posts?: Post[]) =>
      (posts || state.posts).filter(
        ({ id, published_at: publishedAt, is_draft: idDraft, pinned }: Post) => !!id && !pinned && publishedAt && !idDraft
      ),
    isLiked: (state) => state.post?.is_current_user_liked || false,
  },
  actions: {
    async toggleLikePost(postId: string) {
      const {
        isLiked,
        post,
        $nuxt: { $axios },
        $patch,
      } = this
      try {
        await (isLiked ? $axios.$delete : $axios.$post)(`/v2/author_v1/article/${postId}/like`)
        $patch({
          post: {
            ...post,
            is_current_user_liked: !isLiked,
          },
        })
      } catch (e) {
        console.info(e)
      }
    },
    async syncPost(postId: string) {
      const { $patch } = this
      const { syncPublication, publication } = usePublication()
      const post = await this.getPost(postId, publication?.id)
      if (post.newsletter_id !== publication?.id) {
        await syncPublication(post.newsletter_id)
      }

      $patch({ post })
    },
    async getPostsV2({ publicationId, ...params }: PostParameters): Promise<Pagination> {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$get(`/v2/author_v1/newsletter/${publicationId}/article`, { params: { ...params } })
    },

    async getPost(id: string, newsletterId?:string) {
      const {
        $patch,
        posts,
        $nuxt: { $axios },
      } = this

      const post = await $axios.$get(`/v2/author_v1/newsletter/${newsletterId}/article/${id}`)
      post.is_current_user_liked = await $axios.$get(`/v2/author_v1/article/${id}/like`)

      const hasPost = posts.find((post: Post) => post.id === id)

      if (!hasPost) {
        $patch((state: State) => {
          state.posts.push(post)
        })
      }

      return post
    },
  },
})
