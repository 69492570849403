var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"k-dropdown"},[_c('div',{class:{ 'tw-cursor-not-allowed tw-pointer-events-none tw-opacity-50 tw-bg-none': _vm.disabled },on:{"click":_vm.toggle}},[_vm._t("trigger",function(){return [_c('KButton',{attrs:{"size":"sm","color":"neutral","icon":"more-vertical","rounded":""}})]})],2),_vm._v(" "),(!_vm.disabled)?_c('div',{class:['items', _vm.itemsClass, {
      'items--active': _vm.localActive,
    }],attrs:{"id":_vm.dropdownId}},_vm._l((_vm.currentNavigationItems.filter(function (i) { return i.show !== false; })),function(item){
    var _obj;
return _c(item.to ? 'nuxt-link' : item.href ? 'a' : 'div',{key:item.label,tag:"component",class:['item', item.separator ? 'item--separator' : ''],attrs:{"href":item.href,"to":item.to,"title":item.label},on:{"click":function($event){return _vm.actionManager(item)}}},[(item.icon)?_c('div',{class:['item__label--icon', {
          'item__label--icon-right': item.icon.position_right,
        }]},[_c('KIcon',{style:({
            color: _vm.getColor(item.icon.color || item.color || 'black')
          }),attrs:{"icon":item.icon.name,"size":item.icon.size || 'sm'}})],1):_vm._e(),_vm._v(" "),(item.title || item.label)?_c('div',{class:[
          'item__label',
          ("tw-text-" + ((item.font && item.font.size) ? item.font.size : 'xs')),
          Object.assign({}, {'tw-font-bold': item.font && item.font.bold || true},
            item.font && ( _obj = {}, _obj[("tw-font-['" + (item.font.family) + "']")] = item.font.family, _obj )) ],style:(Object.assign({}, {color: _vm.getColor(item.color || 'black')},
          item.font && {'font-family' :item.font.family}))},[_vm._v("\n        "+_vm._s(!item.separator ? (item.title || item.label) : '')+"\n      ")]):_vm._e()])}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }