var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-items-center tw-gap-1 tw-flex-wrap"},[_vm._l((_vm.colorList.filter(Boolean)),function(color,index){return _c('KTooltip',{key:index,attrs:{"type":"is-dark","active":color === 'none' && !!_vm.value,"label":_vm.$t('DELETE').toString()}},[_c('div',{staticClass:"tw-drop-shadow tw-rounded-full tw-overflow-hidden tw-h-6 tw-w-6 tw-justify-center tw-flex tw-items-center tw-cursor-pointer",class:[
        {
          'is-active': _vm.value === color,
          'tw-border tw-border-black': color === 'none',
          'tw-cursor-not-allowed tw-opacity-30': !_vm.value && color === 'none',
        },
        ("tw-bg-" + (color !== 'none' ? color : 'white')) ],style:(("background-color: " + color + ";")),on:{"click":function($event){return _vm.setCustomColor(("" + color))}}},[(_vm.value === color)?_c('KIcon',{staticClass:"tw-drop-shadow",class:{
          'tw-text-white': _vm.isDark,
          'tw-text-black': !_vm.isDark,
        },attrs:{"icon":"tick-small"}}):_vm._e(),_vm._v(" "),(color === 'none')?_c('div',{staticClass:"tw-w-full tw--rotate-45 tw-h-[1px] tw-bg-black"}):_vm._e()],1)])}),_vm._v(" "),_c('label',{staticClass:"tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-relative tw-h-6 tw-w-6 tw-border tw-rounded-full tw-border-black"},[_c('KIcon',{staticClass:"tw-text-black",attrs:{"icon":"add","size":"sm"}}),_vm._v(" "),_c('input',{staticClass:"tw-absolute",attrs:{"type":"color"},on:{"change":function($event){return _vm.setCustomColor($event.target.value)}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }