import { MailTemplate, MailTemplateEnum, Owner, Publication } from '@kessel/core'
import { Context } from '@nuxt/types'
import { defineStore } from 'pinia'
import { useDomain } from '~/stores/domain'
import { CSV } from '~/utils/file'

interface State {
  publications: Publication[]
  mailTemplates: MailTemplate[]
  recommendations: Publication[]
  publication: Publication | null
  publicationSubDomainName: string | null
}

export const usePublication = defineStore('publication_v2', {
  state: (): State => ({
    publications: [],
    mailTemplates: [],
    recommendations: [],
    publication: null,
    publicationSubDomainName: null,
  }),
  getters: {
    getAuthorPublication: (state) => (publication?: Publication) => (publication || state.publication)?.owners[0],
    floorDecimals: () => (value: number) => Math.floor(value * 100) / 100 || 0,
    getAuthorName: () => (author?: Owner) => (author && `${author?.first_name || ''} ${author?.last_name || ''}`) || '',
    getMailtemplate: (state) => (key: MailTemplateEnum) => state.mailTemplates?.find((template) => template.key === key),
  },
  actions: {
    async syncPublicationFromSubdomain(context?: Context) {
      const { $patch, getSubdomainPublicationId } = this
      const { parseHostname } = useDomain()
      const { subdomain } = parseHostname(context)
      const publication = await getSubdomainPublicationId(subdomain)

      $patch({
        publication,
      })

      return publication
    },

    async syncPublication(publicationId: string) {
      const { getPublication, $patch } = this

      $patch({
        publication: await getPublication(publicationId),
      })
    },
    async getSubdomainPublicationId(name: string) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$get(`/v2/author_v1/domain/${name}`)
    },

    async syncMailTemplates(publicationId: string) {
      const { getMailTemplates, $patch } = this

      const mailTemplates = await getMailTemplates(publicationId)
      $patch({
        mailTemplates,
      })
    },
    async syncRecommendations(publicationId: string) {
      const { getRecommendations, $patch } = this

      const recommendations = await getRecommendations(publicationId)
      $patch({
        recommendations: recommendations?.items || [],
      })
    },
    async getPublication(publicationId: string) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$get(`/v2/author_v1/newsletter/${publicationId}`)
    },
    async getMailTemplates(publicationId: string) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$get(`/v1/newsletter/${publicationId}/templates`)
    },
    async getRecommendations(publicationId: string) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$get(`/v2/author_v1/newsletter/${publicationId}/recommendation`)
    },
    addRecommendations() {
      // Unused code
      // if (publication) {
      //   await $axios.$post(
      //     `/v2/newsletter/${publication.id}/recommendation`,
      //     recommendations.map((r) => r.id)
      //   )

      //   this.$patch({
      //     recommendations: [...oldRecos, ...recommendations],
      //   })
      // }
    },

    async inviteEmailForPublication(publicationId: string, email: string) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$post('/v1/subscription/subscribe', null, { params: { news_id: publicationId, email } })
    },
    async inviteEmailsForPublication(publicationId: string, users: CSV[]) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$post(`/v1/publication/${publicationId}/invite`, { users })
    },
  },
})
