import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=32833648&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarDashboard: require('/tmp/build_84effd69/apps/web/components/navbar/NavbarDashboard.vue').default,KFooter: require('/tmp/build_84effd69/apps/web/components/KFooter.vue').default})
