






































import { computed, defineComponent, PropType, toRefs } from '@nuxtjs/composition-api'

import { useThemeColor } from '~/composables/useTheme'

export default defineComponent({
  inheritAttrs: false,
  props: {
    expanded: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    iconLeft: {
      type: String,
      default: null,
    },
    direction: {
      type: String,
      default: 'right',
      validator: (value: string) => ['right', 'left'].includes(value),
    },
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl'>,
      default: 'md',
      validator: (value: string) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(value),
    },
    // Use slot insted of v-text if the btn have icon or loader
    loading: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full'>,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: 'button',
    },
  },
  setup(props) {
    const LOADER_ICON = 'loader'
    const { size, color, direction, rounded, active, loading, icon, iconLeft } = toRefs(props)

    const getSize = computed(() => ({
      ...(rounded.value && {
        'tw-aspect-square': true,
        'tw-button-rounded-xs': size.value === 'xs',
        'tw-button-rounded-sm': size.value === 'sm',
        'tw-button-rounded-md': size.value === 'md',
        'tw-button-rounded-lg': size.value === 'lg',
        'tw-button-rounded-xl': size.value === 'xl',
      }),
      ...(!rounded.value && {
        'tw-button-xs': size.value === 'xs',
        'tw-button-sm': size.value === 'sm',
        'tw-button-md': size.value === 'md',
        'tw-button-lg': size.value === 'lg',
        'tw-button-xl': size.value === 'xl',
      }),
    }))

    const { extractColorFromTheme, isHexColor } = useThemeColor()

    const getColor = computed(() => !isHexColor(color.value) && `tw-button-${color.value}`)
    const getBackgroundColor = computed(() => isHexColor(color.value) && { backgroundColor: color.value })
    const getTextColor = computed(() => {
      const { isDark, isValid } = extractColorFromTheme(color.value)

      return isValid && (isDark ? 'tw-text-white' : 'tw-text-black')
    })

    const getDirection = computed(() => ({
      'tw-flex-row': direction.value === 'right',
      'tw-flex-row-reverse': direction.value === 'left',
    }))
    const getActive = computed(() => ({
      '!tw-bg-gray-300': active.value,
    }))
    const getIcon = computed(() => (loading.value ? LOADER_ICON : icon.value))
    const getIconLeft = computed(() => (loading.value ? LOADER_ICON : iconLeft.value))

    return {
      getColor,
      getIcon,
      getIconLeft,
      getSize,
      getBackgroundColor,
      getTextColor,
      getDirection,
      getActive,
    }
  },
})
