import { render, staticRenderFns } from "./NavbarRegister.vue?vue&type=template&id=c94ecb36&"
import script from "./NavbarRegister.vue?vue&type=script&lang=ts&"
export * from "./NavbarRegister.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navbar: require('/tmp/build_84effd69/apps/web/components/navbar/Navbar.vue').default,Progress: require('/tmp/build_84effd69/apps/web/components/onboarding/Progress.vue').default})
