








import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    icon: { type: String, required: true },
    size: { type: String, required: false, default: 'md' },
  },
})

