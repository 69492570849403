












import { defineComponent, PropType } from '@nuxtjs/composition-api'

export type IconSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export default defineComponent({
  props: {
    icon: { type: String, required: true },
    variant: { type: String, default: 'outline' },
    size: {
      type: String as PropType<IconSizes>,
      required: false,
      default: 'lg',
      validator: (value: string) => ['xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value),
    },
  },
})
