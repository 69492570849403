import type { ImageOptions as NuxtImageOptions, ResolvedImage } from '@nuxt/image'
import { Editor } from '@tiptap/core'
import type { ImageOptions as TipTapImageOptions } from '@tiptap/extension-image'
import { Image as TipTapImage } from '@tiptap/extension-image'
import { VueNodeViewRenderer, mergeAttributes } from '@tiptap/vue-2'
import { Plugin, PluginKey } from 'prosemirror-state'

import { IMAGE_MAX_SIZE } from '@kessel/core'
import ImageElement from '~/components/editor/components/tiptap/TipTapImageElement.vue'
import { fileToBase64 } from '~/utils/base64ToBlob'
import { useUpload } from '~/stores/upload'

export interface ImageOptions extends TipTapImageOptions {
  getImage: (source: string, options?: NuxtImageOptions | undefined) => ResolvedImage
  error: Object | null
}

export { TipTapImage as Image }

export const Figure = TipTapImage.extend<ImageOptions>({
  editor: Editor,

  name: 'image',

  inline: true,

  group: 'inline',

  // content: 'inline*',

  addNodeView() {
    return VueNodeViewRenderer(ImageElement)
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey(this.name),
        props: {
          handleDrop(view, event) {
            if (event.dataTransfer?.files?.length !== 1) {
              return
            }

            const [image] = Array.from(event.dataTransfer.files).filter((file) => /image/i.test(file.type))
            if (image) {
              const { checkFileSize } = useUpload()
              event.preventDefault()
              event.stopImmediatePropagation()

              const { schema } = view.state
              const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY })

              checkFileSize(image, IMAGE_MAX_SIZE, ['image/gif'], () => {
                fileToBase64(image).then((base64) => {
                  const node = schema.nodes.image.create({
                    src: base64,
                    alt: image.name,
                    error: null,
                  })
                  const transaction = view.state.tr.insert(coordinates?.pos || view.state.selection.to, node)
                  view.dispatch(transaction)
                })
              }, (_, __, ___, error) => {
                const node = schema.nodes.image.create({
                  src: '',
                  alt: 'error',
                  error: { ...error, toast: false },
                })
                const transaction = view.state.tr.insert(coordinates?.pos || view.state.selection.to, node)
                view.dispatch(transaction)
              })
            }
          },
        },
      }),
    ]
  },

  addAttributes() {
    // Return an object with attribute configuration
    return {
      error: { default: null },
      href: { default: null },
      expanded: { default: false },
      src: { default: '' },
      crop: { default: { x: 0, y: 0, width: 0, height: 0 } },
      caption: {
        default: '',
      },
      width: {
        renderHTML: (attributes) => {
          return {
            width: attributes.width,
          }
        },
      },
      height: {
        renderHTML: (attributes) => {
          return {
            height: attributes.height,
          }
        },
      },
      isDraggable: {
        default: true,
        renderHTML: () => {
          return {}
        },
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: this.options.allowBase64 ? 'img[src]' : 'img[src]:not([src^="data:"])',
      },
      {
        tag: 'figure',
        contentElement: 'figcaption',
      },
    ]
  },

  renderHTML({ HTMLAttributes: { src, href, crop, caption, expanded, width, height } }) {
    const { getImage } = this.options

    const element = [
      'img',
      mergeAttributes(
        this.options.HTMLAttributes,
        {
          alt: caption,
          width,
          height,
        },
        {
          ...(getImage && {
            src:
              (src &&
                getImage(src, {
                  modifiers: {
                    ...(crop && { rect: `${crop.x},${crop.y},${crop.width},${crop.height}` }),
                    ...(width && { width }),
                    ...(height && { height }),
                  },
                }).url) ||
              null,
          }),
        }
      ),
    ]

    return [
      // Figure can be added in paragraph on rendering https://github.com/ueberdosis/tiptap/issues/2560
      'span',
      mergeAttributes(this.options.HTMLAttributes, {
        class: `element-image ${expanded ? 'expanded' : ''}`,
      }),
      href ? ['a', { href, target: '_blank' }, element] : element,
      caption && [
        'span',
        {
          class: 'caption',
        },
        caption,
      ],
    ]
  },
})
