import { Parameters } from '@kessel/core'
import debounce from 'lodash/debounce'
import { defineStore } from 'pinia'

export interface StatePagination {
  items: Object[]
  total: number
  page: number
  size: number
  /**
   * @requires 'infinite' to be true
   */
  loading: boolean
  event: boolean
  scroll: {
    infinite: boolean
    /**
     * @description if 'infinite' is true, the pagination will be automatically updated when the user scrolls to the bottom of the page
     * @default false
     */
    auto?: boolean
  }
  promiseParameters: { [key: string]: any }
  promise?: string
}

interface State {
  pagination: { [key: string]: StatePagination }
}

export const usePagination = defineStore('pagination', {
  state: (): State => ({
    pagination: {
      default: {
        items: [],
        total: 0,
        page: 1,
        size: 10,
        loading: false,
        event: false,
        scroll: {
          infinite: false,
          auto: false,
        },
        promiseParameters: {},
        promise: undefined,
      },
    },
  }),
  getters: {
    hasPrevPage:
      (state) =>
        (name: string, deep = 1) =>
          state.pagination[name].page - deep >= 1,
    hasNextPage(state) {
      return (name: string, deep = 1) => state.pagination[name].page + deep <= this.maxPage(name)
    },
    getInterval(state) {
      return (name: string, gap = 2) => {
        const pagination = state.pagination[name]
        const interval = [pagination.page]
        for (let i = 1; i <= gap; i++) {
          this.hasPrevPage(name, i) && interval.unshift(pagination.page - i)
          this.hasNextPage(name, i) && interval.push(pagination.page + i)
        }
        return interval
      }
    },
    isPageExist(state) {
      return (name: string) => state.pagination[name].page > 0 && this.maxPage(name) >= state.pagination[name].page
    },
    maxPage: (state) => (name: string) => Math.ceil(state.pagination[name].total / state.pagination[name].size),
    getPagination: (state) => (name: string) => state.pagination[name] as StatePagination,
  },
  actions: {
    async syncPagination({ name, ...params }: Parameters, reset = false) {
      const state = this.getPagination(name) as StatePagination

      if (state && !state.loading && state.promise) {
        try {
          this.toggleLoading(name)
          Object.assign(state.promiseParameters, params)

          const promise = this.findMethod(state.promise)
          const { items, ...result } = await promise(state.promiseParameters)

          state.scroll?.infinite && !reset && items.unshift(...state.items)
          this.$patch({ pagination: { [name]: { items, ...result } } })

          this.onEvent(name)
          return state.items
        } catch (e) {
          console.error(e)
        } finally {
          this.toggleLoading(name)
        }
      }
    },
    createPagination(name: string, params: Partial<StatePagination>) {
      if (!this.pagination[name]) {
        this.$patch({ pagination: { [name]: JSON.parse(JSON.stringify(this.pagination.default)) } }) // copy without reference
        this.setStateParameters(name, params)
      } else {
        this.setStateParameters(name, params)
      }
    },
    onEvent(name: string) {
      const {
        $nuxt: { $bus },
      } = this
      try {
        const state = this.getPagination(name) as StatePagination

        if (state.event && !$bus.$all.has(name)) {
          // add event listener if not exist
          $bus.$on(
            name,
            debounce(() => this.refreshPagination(name, state.scroll.infinite), 250)
          )
        }
      } catch (e) {
        console.error(e)
      }
    },
    async refreshPagination(name: string, reset = false) {
      await this.syncPagination({ name }, reset)
    },
    findMethod(name?: string): Function {
      if (!name) {
        throw new Error('Method name is required')
      }

      const store = Object.values(Object.fromEntries((this.$nuxt.$pinia as any)._s as Map<string, Object>)).find((value) =>
        Object.keys(value).includes(name)
      )

      if (!store) {
        throw new Error(`Method ${name} not found`)
      }

      const method = new Map(Object.entries(store)).get(name)

      if (typeof method === 'function') {
        return method
      } else {
        throw new TypeError(`Method ${name} is not a function`)
      }
    },
    toggleLoading(name: string) {
      this.$patch({ pagination: { [name]: { loading: !this.pagination[name].loading } } })
    },
    setStateParameters(name: string, value: Partial<StatePagination>) {
      this.$patch({ pagination: { [name]: value } })
    },
    setPromiseParameters(name: string, value: State['pagination'][typeof name]['promiseParameters']) {
      this.$patch({ pagination: { [name]: { promiseParameters: value } } })
    },
  },
})
