import { Extension } from '@tiptap/core'
import classnames from 'classnames'
import { isValid, toHexString } from '../color'

export interface BorderOptions {
  types: string[]
  defaultBorderWidth?: number
  defaultBorderColor?: string
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    borderColor: {
      /**
       * Set the border color attribute
       */
      setBorderColor: (color: string) => ReturnType
      /**
       * Unset the border color attribute
       */
      unsetBorderColor: () => ReturnType
    }
  }
}

export const BorderColor = Extension.create<BorderOptions>({
  name: 'borderColor',

  content: 'block+',

  group: 'block',

  defining: true,

  addOptions() {
    return {
      types: ['paragraph'],
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      borderColor: {
        default: this.options.defaultBorderColor,
      },
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          borderColor: {
            default: this.options?.defaultBorderColor,
            parseHTML: (element) => {
              const color = element.style.borderColor || this.options?.defaultBorderColor

              return (color !== 'transparent' && color && isValid(color) && toHexString(color)) || undefined
            },
            renderHTML: ({ borderWidth, borderColor }) => {
              const border = (borderWidth && typeof borderWidth === 'string' && (Number(borderWidth.replace('px', '')) > 0)) || !!borderColor

              return {
                style: Object.entries({
                  ...(border && borderColor !== 'transparent' && { borderColor }),
                  ...(border && { borderWidth: `${borderWidth?.replace('px', '') || this.options?.defaultBorderWidth}px` }),
                })
                  .map(([key, value]) => `${key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}:${value}`)
                  .join(';'),
                class: classnames({
                  'tw-px-4 tw-py-2': !!border,
                }),
              }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setBorderColor:
        (borderColor) =>
          ({ commands }) => {
            return this.options.types.every((type) => commands.updateAttributes(type, { borderColor }))
          },

      unsetBorderColor:
        () =>
          ({ commands }) => {
            return this.options.types.every((type) => commands.resetAttributes(type, this.name))
          },
    }
  },
})
