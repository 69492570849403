

































import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api'

import { Publication, SearchItem } from '@kessel/core'

export default defineComponent({
  props: {
    recommendations: {
      type: Array as PropType<SearchItem[]>,
      required: false,
      default: () => [],
    },
  },
  emits: ['onChange'],
  setup(_, { emit }) {
    const checkboxGroupRef = ref<Publication[]>([])

    watch(checkboxGroupRef, () => {
      emit('onChange', checkboxGroupRef.value.map((publication) => publication.id))
    }, { immediate: true })

    return {
      checkboxGroupRef,
    }
  },
})
