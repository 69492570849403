







































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { useRights } from '~/stores/right'
import { useUser } from '~/stores/user'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { isLogged } = storeToRefs(useUser())
    const { userIsOwner } = storeToRefs(useRights())

    const { $config: { dashboardV2Url } } = useContext()

    const loginUrl = computed(() =>
      `${dashboardV2Url.replace('newsletter', 'auth')}/login?callbackUrl=${process.client ? window.location : ''}`
    )

    return {
      loginUrl,
      userIsOwner: computed(() => userIsOwner.value()),
      isLogged,
      linkLogout: computed(() => '/'),
    }
  },
})
