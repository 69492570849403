













































import { defineComponent } from '@nuxtjs/composition-api'
import ErrorIcon from '~/assets/images/errors/error_404.svg?inline'
import KesselLogo from '~/assets/images/logo-kessel.svg?inline'
import KButton from '~/components/buttons/KButton.vue'
import { useDomain } from '~/stores/domain'

export default defineComponent({
  components: {
    KButton,
    KesselLogo,
    ErrorIcon,
  },
  setup() {
    const { goToHome } = useDomain()
    return { goToHome }
  },
})
