import { Subscription, SubscriptionsParameters } from '@kessel/core'
import { defineStore, storeToRefs } from 'pinia'
import { useTrackEvent } from '~/composables/useTrackEvent'
import { useOffer } from '~/stores/offer'

interface State {
  subscriptions: Subscription[]
  subscription: Subscription | null
}

export const useSubscription = defineStore('subscription', {
  state: (): State => ({
    subscriptions: [],
    subscription: null,
  }),
  actions: {
    async syncSubscriptions(params?: SubscriptionsParameters) {
      this.subscriptions = (await this.getSubscriptions(params)).items
    },
    async syncSubscription(subId: string) {
      const { $patch } = this
      const subscription = await this.getSubscription(subId)
      $patch({ subscription })
    },
    async getSubscriptions(params?: SubscriptionsParameters) {
      return await this.$nuxt.$axios.$get('/v1/subscription', { params })
    },
    async getSubscription(subId: string) {
      return await this.$nuxt.$axios.$get(`/v1/subscription/${subId}`)
    },
    async getSubscriptionByPublicationId(pubId: string) {
      return await this.$nuxt.$axios.$get(`/v1/subscription/publication/${pubId}`)
    },
    async unsubscribe(subId: string) {
      return await this.$nuxt.$axios.$delete(`/v1/subscription/${subId}`)
    },
    async unsubscribeMail(subId: string, mailingEnabled: boolean) {
      return await this.$nuxt.$axios.$put(`/v1/subscription/${subId}`, { mailing_enabled: mailingEnabled })
    },
    async attachPlan(pubId: string, priceId = 'price_free') {
      const { trackEvent } = useTrackEvent()

      const { offer, getReferralPubliation } = storeToRefs(useOffer())
      const referralToken = getReferralPubliation.value(pubId)

      if (offer.value) {
        const foundedKey = Object.entries(offer.value).find(([value]) => value === priceId)
        let priceValue: number | null = 0

        switch (foundedKey?.[0]) {
          case 'stripe_price_id_monthly':
            priceValue = offer.value.monthly_price
            break
          case 'stripe_price_id_premium':
            priceValue = offer.value.premium_price
            break
          case 'stripe_price_id_yearly':
            priceValue = offer.value.yearly_price
            break
          case 'stripe_price_id_free':
          default:
            priceValue = 0
            break
        }

        trackEvent('begin_checkout', {
          currency: 'EUR',
          value: (priceValue || 0) / 100,
          items: [
            {
              item_id: priceId,
              quantity: 1,
            },
          ],
        })
      }

      return await this.$nuxt.$axios.$post('/v1/subscription/attach_plan', null, {
        params: {
          news_id: pubId,
          ...(priceId && { price_id: priceId }),
          ...(referralToken && { referral_token: referralToken }),
        },
      })
    },
  },
})
