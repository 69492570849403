import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import { ToastProgrammatic } from 'buefy'
import { useApp } from '~/stores/app'
import { useAuth } from '~/stores/auth'

export default defineNuxtPlugin(async ({ route, localePath, i18n }) => {
  const { redirect: redirectPath, s: sToken, ...query } = route.query || {}

  const { initApp } = useApp()
  try {
    if (sToken && !process.server) {
      try {
        const { checklink } = useAuth()
        await checklink(sToken.toString())
      } catch {
        ToastProgrammatic.open({
          message: i18n.t('MAGICLINK_ERROR') as string,
          type: 'is-danger',
          duration: 5000,
        })
        console.error('on-start Invalid sToken')
      } finally {
        await initApp()

        const path = localePath({
          query,
          ...(redirectPath && { path: redirectPath as string }),
          ...(!redirectPath && { path: route.path as string }),
        })

        window.location.replace(path)
      }
    } else {
      await initApp()
    }
  } catch (e: any) {
    if (process.browser || (process.server && e.response?.status !== 401)) {
      console.error('on-start error', e)
    }
  }
})
