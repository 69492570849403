import mitt from 'mitt'
import { Plugin } from '@nuxt/types'

const emitter = mitt()

declare module '@nuxt/types' {
  interface Context {
    $bus: {
      $on: typeof emitter.on
      $emit: typeof emitter.emit
      $off: typeof emitter.off
      $all: typeof emitter.all
    }
  }
}

const mittPlugin: Plugin = (context, inject) => {
  context.$bus = {
    $on: emitter.on,
    $emit: emitter.emit,
    $off: emitter.off,
    $all: emitter.all,
  }

  inject('bus', context.$bus)
}

export default mittPlugin
