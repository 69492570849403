import { defineNuxtPlugin } from '@nuxtjs/composition-api'

import type { NuxtAxiosInstance } from '@nuxtjs/axios'

const REFRESH_TOKEN_ERROR_LIST = ['token.expired'].map((strError) => strError?.toLowerCase())

declare module '@nuxt/types' {
  interface Context {
    $axios: NuxtAxiosInstance
  }
}

export default defineNuxtPlugin(({ $axios, $config, res }) => {
  $axios.interceptors.request.use((config) => {
    if ($config.features.logAxiosRequests) {
      // eslint-disable-next-line no-console
      console.log('[Axios] Starting request:', `[${config.method}]${config.url}`)
    }
    return config
  })
  $axios.interceptors.response.use(
    (response) => {
      if ($config.features.logAxiosRequests) {
        const config = response.config
        // eslint-disable-next-line no-console
        console.log('[Axios] Response:', `[${config.method}]${config.url}`, JSON.stringify(response.data))
      }
      return response
    },
    async (error) => {
      if (
        typeof error?.response?.data?.detail === 'string' &&
        REFRESH_TOKEN_ERROR_LIST.includes(error?.response?.data?.detail?.toLowerCase())
      ) {
        try {
          const refreshData = await $axios.$get('/v1/auth/refresh_token')

          try {
            const newCookie = error?.response?.config?.headers?.cookie?.replace(
              /access_token=([^&]*)/gm,
              `access_token=${refreshData.access_token}`
            )

            if (process.server && !!newCookie) {
              res.setHeader('Set-Cookie', newCookie)
            }

            const retriedResponseData = await $axios(
              (process.server &&
              {
                ...error.config,
                headers: {
                  ...error.config.headers,
                  ...(newCookie && {
                    cookie: newCookie,
                  }),
                },
              }) ||
              error.config
            )

            return retriedResponseData
          } catch (e) {
            throw error
          }
        } catch (e) {
          throw error
        }
      }
      throw error
    }
  )
})
