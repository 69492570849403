import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { useFeatureFlip } from '~/stores/featureFlip'

export default defineNuxtMiddleware(({ route, error, $pinia }) => {
  const { hydration, isActive } = useFeatureFlip($pinia)
  hydration()

  if (route.path === '/studio' && !isActive('pageStudio')) {
    error({ statusCode: 404, message: 'Page not found' })
  }
})
