import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import TipTapButton from '~/components/editor/components/tiptap/TipTapButton.vue'
import { ALIGNEMENTS, TYPESBUTTON } from '~/utils/tiptap'

export interface ButtonOptions {
  HTMLAttributes?: Record<string, any>
  fullWidth: boolean
  title: string
  href?: string | null
  backgroundColor?: string | null
  color?: string | null
  textAlign?: string | null
  type: string
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
      button: {
        setButton: (options: ButtonOptions) => ReturnType
      }
    }
}

export const Button = Node.create<ButtonOptions>({
  name: 'button',
  group: 'block',
  draggable: true,
  addNodeView() {
    return VueNodeViewRenderer(TipTapButton)
  },
  renderHTML({ HTMLAttributes }) {
    const {
      color,
      backgroundColor,
      textAlign,
      title,
      href,
      fullWidth,
      type,
    } = HTMLAttributes

    const btnAlign = textAlign === ALIGNEMENTS.CENTER ? 'tw-text-center' : textAlign === ALIGNEMENTS.LEFT ? 'tw-text-start' : 'tw-text-right'
    return [
      'div',
      { class: `kessel-button ${btnAlign} ${fullWidth ? 'full-width' : ''} ` },
      [
        'a',
        mergeAttributes(HTMLAttributes,
          {
            class: `tw-tiptap-button ${(fullWidth ? ['tw-max-w-none', 'tw-w-full'] : ['tw-max-w-max']).join(' ')}`,
            title,
            style: `background-color: ${backgroundColor}; color: ${color}`,
            href,
            target: type === TYPESBUTTON.SUBSCRIBE ? '_self' : '_blank',
          }
        ),
        title,
      ],
    ]
  },
  addAttributes() {
    return {
      fullWidth: { default: false },
      href: { default: null },
      backgroundColor: { default: 'primary' },
      color: { default: 'secondary' },
      title: { default: null },
      type: { default: null },
      textAlign: { default: 'center' },
    }
  },
  addCommands() {
    return {
      setButton: (options) => {
        return ({ commands }) =>
          commands.insertContent({
            type: this.name,
            attrs: options,
          })
      },
    }
  },
})
