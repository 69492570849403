import { BaseUser } from '@kessel/core'
import { Context } from '@nuxt/types'
import { defineNuxtMiddleware, watch } from '@nuxtjs/composition-api'
import isEmpty from 'lodash/isEmpty'
import { storeToRefs } from 'pinia'
import { useTrackEvent } from '~/composables/useTrackEvent'
import { useUser } from '~/stores/user'

export default defineNuxtMiddleware((ctx: Context) => {
  const {
    redirect,
    route,
    app: { localePath },
  } = ctx
  const { user } = storeToRefs(useUser(ctx.$pinia))
  const { trackEvent } = useTrackEvent()

  const checkIfLoggedIn = async (user: BaseUser | null | undefined) => {
    trackEvent('login', {
      method: 'email',
      ...(user && {
        id: user.id,
        UserName: user.first_name,
        UserEmail: user.email,
      }),
    })

    const { redirect: redirectPath, ...omitQuery } = route.query || {}

    const query = {
      ...omitQuery,
      ...(!user && { redirect: route.path }),
    }

    if (redirectPath || isEmpty(user)) {
      await redirect(
        localePath({
          query,
          ...(redirectPath && { path: redirectPath as string }),
          ...(!user && { name: 'login', path: undefined, redirect: route.fullPath }),
        }),
        query
      )
    }
  }

  watch(() => user.value, checkIfLoggedIn, { immediate: true, deep: false })
})
