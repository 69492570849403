import { Context } from '@nuxt/types'
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { useApp } from '~/stores/app'
import { useOffer } from '~/stores/offer_v2'
import { usePublication } from '~/stores/publication_v2'
import { useRights } from '~/stores/right'

export default defineNuxtMiddleware(async (ctx: Context) => {
  const { route } = ctx

  const { syncSubscriptionDrawer } = useRights(ctx.$pinia)
  const { syncPublicationFromSubdomain, syncRecommendations } = usePublication(ctx.$pinia)
  const { syncColors } = useApp(ctx.$pinia)
  const { syncOffer } = useOffer(ctx.$pinia)

  try {
    const publication = await syncPublicationFromSubdomain(ctx)

    if (!publication) {
      ctx.error({ statusCode: 404, message: 'Publication not found', path: '/middleware/rights/syncPublicationFromSubdomain' })
      return
    }

    try {
      await syncColors()
    } catch (e) {
      console.error({ message: JSON.stringify(e), path: '/middleware/rights/syncColors' })
    }

    try {
      await syncOffer()
    } catch (e: any) {
      console.error({ message: JSON.stringify(e), path: '/middleware/rights/syncOffer' })
    }

    try {
      await syncSubscriptionDrawer()
    } catch (e) {
      console.error({ message: JSON.stringify(e), path: '/middleware/rights/syncSubscriptionDrawer' })
    }

    if ((route.query.r === 'success' || route.query.subscribe) && publication?.id) {
      try {
        await syncRecommendations(publication?.id)
      } catch (e: any) {
        console.error({ message: JSON.stringify(e), path: '/middleware/rights/syncRecommendations' })
      }
    }
  } catch (e: any) {
    console.error({ message: JSON.stringify(e), path: '/middleware/rights' })
    ctx.error({ statusCode: e.statusCode, message: e.message, path: '/middleware/rights/syncPublicationFromSubdomain' })
  }
})
