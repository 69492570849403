













































































import { Publication } from '@kessel/core'
import { PropType, computed, defineComponent, onMounted, reactive, ref, useContext } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'

import { useTrackEvent } from '~/composables/useTrackEvent'
import { useOffer } from '~/stores/offer_v2'

import { usePublication } from '~/stores/publication_v2'
import { useRights } from '~/stores/right'
import { useStripe } from '~/stores/stripe'
import { useSubscription } from '~/stores/subscription_v2'

export default defineComponent({
  props: {
    value: { type: Boolean, default: false },
    recommendations: {
      type: Array as PropType<Publication[]>,
      required: true,
    },
  },
  emits: ['update:active', 'submit'],
  setup(_, { root: { $buefy } }) {
    const checkboxGroupRef = ref([])
    const { i18n } = useContext()
    const { subscription } = storeToRefs(useRights())
    const { getAuthorName } = usePublication()
    const { publication } = storeToRefs(usePublication())
    const { stripeBilling } = useStripe()
    const { trackEvent } = useTrackEvent()
    const { forceCloseSubscriptionDrawer: forceClose } = useRights()
    const { attachPlan } = useSubscription()
    const { offer } = storeToRefs(useOffer())

    const loading = ref(false)

    const form = reactive<{ recommendations: string[] }>({
      recommendations: [],
    })

    const isFormValid = computed(() => !!form.recommendations.length)

    const onRecommandationChange = (publications: string[]) => {
      form.recommendations = publications
    }
    const submit = async () => {
      try {
        loading.value = true

        offer.value && (await Promise.all(form.recommendations.map((id) => attachPlan(id, offer.value?.stripe_price_id_free))))
        loading.value = false

        $buefy.toast.open({
          message: i18n.t('SUBSCRIBED_AUTHOR_RECOMMENDS_SUCCESS') as string,
          type: 'is-success',
        })
      } finally {
        loading.value = false
        forceClose()
      }
    }

    onMounted(async () => {
      try {
        const billing = subscription.value && (await stripeBilling(subscription.value.id))
        trackEvent('purchase', {
          currency: billing?.subscription?.items?.data[0]?.price?.currency || 'EUR',
          transaction_id: billing?.subscription.id,
          items: billing?.subscription.items.data.map(({ price: { id, unit_amount: unitAmout, nickname, currency } }) => ({
            item_id: id,
            item_name: nickname,
            price: unitAmout / 100,
            currency,
          })),
        })
      } catch (e) {
        console.error(e)
      }
    })

    return {
      canCancel: true,
      isFormValid,
      loading,
      submit,
      forceClose,
      checkboxGroupRef,
      publicationName: publication.value?.title,
      author: getAuthorName(publication.value?.owners[0]),
      onRecommandationChange,
    }
  },
})
