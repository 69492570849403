import { Context } from '@nuxt/types'
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import isEmpty from 'lodash/isEmpty'
import { useOffer } from '~/stores/offer_v2'
import { usePublication } from '~/stores/publication_v2'

export default defineNuxtMiddleware((ctx: Context) => {
  const {
    route,
  } = ctx
  const { setReferalToken } = useOffer(ctx.$pinia)
  const { publication } = usePublication(ctx.$pinia)
  const { ref: referralQuery } = route.query || {}

  if (!isEmpty(referralQuery) && publication) {
    setReferalToken(referralQuery as string, publication?.id)
  }
})
