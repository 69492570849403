import { stringify } from 'querystring'
import Ajv from 'ajv'
import pick from 'lodash/pick'
import { defineStore } from 'pinia'
import slugify from 'slugify'

import {
  BasePost,
  Pagination,
  Post,
  PostParameters,
  PostStatus,
  PostVisibilityLevel,
  PutPost,
  PutPostValidationSchema,
} from '@kessel/core'

import { useDomain } from '~/stores/domain'
import { usePublication } from '~/stores/publication'

const ajv = new Ajv()
const validate = ajv.compile(PutPostValidationSchema)

export interface State {
  posts: Post[]
  post: Post | null
  postPublished: Post | null
}

export const usePost = defineStore('post', {
  state: (): State => ({
    posts: [],
    post: null,
    postPublished: null,
  }),
  getters: {
    getPostSlug: () => (post: Post) => slugify(post.title || '', { lower: true, strict: true }),
    getPostStatus: (): PostStatus[] => [
      { level: PostVisibilityLevel.PUBLIC, icon: 'users', text: 'EVERYONE' },
      { level: PostVisibilityLevel.SUBSCRIBERS, icon: 'user', text: 'MY_SUBSCRIBERS' },
      { level: PostVisibilityLevel.PAID_SUBSCRIBERS, icon: 'lock', text: 'MY_PAID_SUBSCRIBERS' },
    ],
    getPostActiveStatus: () => {
      return (status: PostStatus[], post: Post): PostStatus => {
        return Object.values(status).find((val) => val.level === post.visibility_level) || (status.at(0) as PostStatus)
      }
    },
    getPostsWithoutCurrent: (state) => {
      return (postId?: string): Post[] => {
        return state.posts.filter((post: Post) => post.id !== postId)
      }
    },
    getPinnedPost: (state) => (posts?: Post[]) =>
      (posts || state.posts).find(
        ({ id, published_at: publishedAt, is_draft: idDraft, pinned }: Post) => !!id && !!pinned && publishedAt && !idDraft
      ) as Post,
    getNotPinnedPosts: (state) => (posts?: Post[]) =>
      (posts || state.posts).filter(
        ({ id, published_at: publishedAt, is_draft: idDraft, pinned }: Post) => !!id && !pinned && publishedAt && !idDraft
      ),
    isLiked: (state) => state.post?.is_current_user_liked || false,
  },
  actions: {
    async syncPosts(publicationId: string) {
      const { syncOwnPublication } = usePublication()
      const { $patch, getPosts } = this

      await syncOwnPublication(publicationId)
      const posts = (await getPosts({ publicationId, size: 10 })).items
      $patch({ posts })
    },
    async toggleLikePost(postId: string) {
      const {
        isLiked,
        post,
        $nuxt: { $axios },
        $patch,
      } = this
      try {
        await (isLiked ? $axios.$delete : $axios.$put)(`/v1/posts/${postId}/like`)
        $patch({
          post: {
            ...post,
            is_current_user_liked: !isLiked,
          },
        })
      } catch (e) {
        console.info(e)
      }
    },
    async syncPost(postId: string) {
      const { putLocalPost } = this
      const { syncPublication, publication } = usePublication()
      const post = await this.getPost(postId)
      if (post.publication_id !== publication?.id) {
        await syncPublication(post.publication_id)
      }

      putLocalPost({ ...post })
    },
    async addPost(publicationId: string, post: BasePost, pinned = false) {
      const {
        $patch,
        $nuxt: { $axios },
      } = this

      const query = stringify({ news_id: publicationId })
      const newPost = await $axios.$post(`/v1/posts?${query}`, { ...post, pinned })
      $patch((state: State) => state.posts.push(newPost))
      return newPost
    },
    async sendMailTest(postId: string, recipient: string) {
      const {
        $nuxt: { $axios },
      } = this

      const query = stringify({ recipient })
      return await $axios.$post(`/v1/posts/${postId}/send_test?${query}`)
    },
    async getPosts({ publicationId, ...params }: PostParameters): Promise<Pagination> {
      const {
        $nuxt: { $axios },
      } = this
      return await $axios.$get('/v1/posts', { params: { news_id: publicationId, ...params } })
    },
    async getOwnPosts({ publicationId, ...params }: PostParameters): Promise<Pagination> {
      const {
        $nuxt: { $axios },
      } = this
      return await $axios.$get('/v1/posts/own', { params: { news_id: publicationId, ...params } })
    },
    async getPost(postId: string) {
      const {
        $patch,
        posts,
        $nuxt: { $axios },
      } = this

      const post = await $axios.$get(`/v1/posts/${postId}`)

      const hasPost = posts.find((post: Post) => post.id === postId)

      if (!hasPost) {
        $patch((state: State) => {
          state.posts.push(post)
        })
      }

      return post
    },
    async deletePost(postId: string) {
      const {
        $patch,
        $nuxt: { $axios },
      } = this

      $patch((state: State) => ({ posts: state.posts.filter((p: Post) => p.id !== postId) }))
      return await $axios.$delete(`/v1/posts/${postId}`)
    },
    putLocalPost(post: PutPost | Partial<Post>) {
      const { $patch } = this

      $patch({
        post,
      })
    },
    async putPost(putPost: PutPost) {
      const {
        putLocalPost,
        post: previousPostData,
        $nuxt: { $axios },
      } = this

      const post: PutPost = pick(putPost, Object.keys(PutPostValidationSchema.properties))

      const isValid = validate(post)

      if (!isValid) {
        console.error(validate.errors)
        throw new Error(`Validation Error. ${JSON.stringify(validate.errors)}`)
      }

      putLocalPost({ ...previousPostData, ...post })

      const postId = putPost?.id || previousPostData?.id

      const updatedPost = !!Object.keys(post).length && postId && (await $axios.$put(`/v1/posts/${postId}`, { ...post }))

      if (updatedPost) {
        putLocalPost(updatedPost)
      }
    },
    async publishPost(postId: string | undefined) {
      const {
        post,
        $nuxt: { $axios },
        $patch,
      } = this

      if (postId) {
        await $axios.$post(`/v1/posts/${postId}/publish`)
        $patch({ postPublished: post })
      }
    },
    async duplicatePost(postId: string) {
      const { getPost, addPost } = this
      const post = (await getPost(postId)) as Post
      const redefined = {
        is_draft: true,
        mail: false,
        publication_scheduled_at: null,
      } as Partial<Post>
      await addPost(post.publication_id, Object.assign(post, redefined), false)
    },
    async syncPostsFromSubdomain() {
      const { syncPosts } = this

      const { parseHostname } = useDomain()
      const { getSubdomainPublicationId } = usePublication()
      const { subdomain } = parseHostname()
      const publicationId = await getSubdomainPublicationId(subdomain)

      await syncPosts(publicationId)
    },
  },
})
