import { Context } from '@nuxt/types'
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'

import { useDomain } from '~/stores/domain'

// This middleware is used to prevent subdomain (eg: hello.localhost) to access an user connected page
// like hello.localhost/dashboard
export default defineNuxtMiddleware((ctx: Context) => {
  const { redirect, route, $config } = ctx

  if (!$config.preventSubdomain) {
    return
  }

  const { getAppPath, getSubdomainPostsPath, parseHostname } = useDomain(ctx.$pinia)
  const parsedDomain = parseHostname(ctx)

  if (
    parsedDomain.subdomain &&
    parsedDomain.subdomain !== 'www' &&
    parsedDomain.subdomain !== 'dashboard' &&
    route.name?.startsWith('index')
  ) {
    return redirect(
      getSubdomainPostsPath({
        protocol: parsedDomain.protocol,
        host: parsedDomain.host,
        subdomain: parsedDomain.subdomain,
      }) + '/posts',
      route.query
    )
  }

  if (parsedDomain.subdomain && parsedDomain.subdomain === 'dashboard' && route.name?.startsWith('index')) {
    return redirect(
      getSubdomainPostsPath({
        protocol: parsedDomain.protocol,
        host: parsedDomain.host,
        subdomain: parsedDomain.subdomain,
      }) + '/dashboard',
      route.query
    )
  }

  if (parsedDomain.subdomain && parsedDomain.subdomain === 'www' && route.name?.startsWith('dashboard')) {
    return redirect(
      getSubdomainPostsPath({
        protocol: parsedDomain.protocol,
        host: parsedDomain.host,
        subdomain: 'dashboard',
      }) + '/dashboard',
      route.query
    )
  }

  if (
    parsedDomain.subdomain !== 'www' &&
    parsedDomain.subdomain !== 'dashboard' &&
    !route.name!.startsWith('posts') &&
    !route.name!.startsWith('embed') &&
    !route.name!.startsWith('unsubscribe') &&
    !route.name!.startsWith('desinscription')
  ) {
    return redirect(
      getAppPath({
        protocol: parsedDomain.protocol,
        host: parsedDomain.host,
        path: route.fullPath,
      }),
      route.query
    )
  }
})
