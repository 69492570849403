import { Domain } from '@kessel/core'
import { useRoute } from '@nuxtjs/composition-api'
import { defineStore } from 'pinia'
import { RawLocation } from 'vue-router'

interface State {
  max: number
  current: number
  progress: number
  loading: boolean
  callBack: () => Promise<any>
  callBackReset: () => any
  errMessage: string
  domain: { valid: boolean; val: Domain }
  skipped: boolean
  path: RawLocation[] | null
}

export const useOnboarding = defineStore('onboarding', {
  state: (): State => ({
    max: 6,
    current: 0,
    progress: 0,
    loading: false,
    callBack: async () => {},
    callBackReset: async () => {}, // reset all states as you want
    errMessage: 'SOMETHING_WENT_WRONG',
    domain: { valid: false, val: { name: '' } },
    skipped: false,
    path: null,
  }),
  getters: {
    current() {
      const route = useRoute()
      const split = route.value.path.split(/[/?]/g)
      return Number(split.reverse().at(0)) || 0
    },
    progress(state) {
      return (this.current / state.max) * 100
    },
  },
  actions: {
    async next(): Promise<() => void> {
      try {
        this.toggleLoading()
        return await this.callBack()
      } catch (e) {
        throw new Error(this.errMessage)
      } finally {
        this.toggleLoading()
      }
    },
    reset() {
      this.callBackReset()
      this.setDomain({ valid: false, val: { name: '' } })
      return this.path?.at(0) as RawLocation
    },
    setMax(value: State['max']) {
      this.$patch({ max: value })
    },
    toggleLoading() {
      this.$patch({ loading: !this.loading })
    },
    setCallBack(value: State['callBack'], errMessage?: State['errMessage']) {
      this.$patch({ callBack: value, errMessage })
    },
    setCallBackReset(value: State['callBackReset']) {
      this.$patch({ callBackReset: value })
    },
    setErrMessage(value: State['errMessage']) {
      this.$patch({ errMessage: value })
    },
    setDomain(domain: Partial<State['domain']>) {
      this.$patch({ domain })
    },
    setSkipped(value: State['skipped']) {
      this.$patch({ skipped: value })
    },
    setPath(value: State['path']) {
      this.$patch({ path: value })
    },
  },
})
