import { render, staticRenderFns } from "./public.vue?vue&type=template&id=29434e38&"
import script from "./public.vue?vue&type=script&lang=ts&"
export * from "./public.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarPublic: require('/tmp/build_84effd69/apps/web/components/navbar/NavbarPublic.vue').default,KFooter: require('/tmp/build_84effd69/apps/web/components/KFooter.vue').default})
