














































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import DropdownNavbar from '~/components/navbar/DropdownNavbar.vue'
import { useDomain } from '~/stores/domain'

export default defineComponent({
  components: { DropdownNavbar },
  props: {
    isLogged: { type: Boolean, default: false },
    navbarPosition: {
      type: String,
      default: 'relative',
      required: false,
      validator: (value: string) => ['absolute', 'relative', 'fixed', 'sticky'].includes(value),
    },
  },
  setup() {
    const { i18n, localePath } = useContext()
    const { pathToPublication } = useDomain()
    const links = computed(() => [
      {
        label: 'kessel-studio',
        title: i18n.t('STUDIO_TITLE'),
        to: localePath({ name: 'studio' }),
      },
      {
        label: 'resources',
        title: i18n.t('LANDING_RESSOURCES'),
        href: pathToPublication(null, 'help'),
      },
      {
        label: 'inbox',
        title: i18n.t('NEWSLETTER_INBOX'),
        href: pathToPublication(null, 'inbox'),
      },
      {
        label: 'faq',
        title: i18n.t('FAQ'),
        to: localePath({ name: 'faq' }),
      },
    ])
    return { links }
  },
})
