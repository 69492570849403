import { BaseUser } from '@kessel/core'
import { Context } from '@nuxt/types'
import { defineNuxtMiddleware, watch } from '@nuxtjs/composition-api'
import isEmpty from 'lodash/isEmpty'
import { storeToRefs } from 'pinia'
import { useTrackEvent } from '~/composables/useTrackEvent'
import { useUser } from '~/stores/user'

export default defineNuxtMiddleware((ctx: Context) => {
  const { user } = storeToRefs(useUser(ctx.$pinia))
  const { trackEvent } = useTrackEvent()
  const checkIfLoggedIn = async (user: BaseUser | null | undefined, oldUserValue: BaseUser | null | undefined) => {
    const {
      route,
      app: { localePath },
      redirect,
    } = ctx

    trackEvent('login', {
      method: 'email',
      ...(user && {
        id: user.id,
        UserName: user.first_name,
        UserEmail: user.email,
      }),
    })

    if (isEmpty(user)) {
      // app is not ready
      return {}
    }

    const { redirect: redirectPath, ...query } = route.query || {}

    if (redirectPath) {
      await redirect(localePath({ path: redirectPath as string, query }))
    }

    if (isEmpty(oldUserValue)) {
      await redirect(localePath({ name: 'dashboard', query }))
    }
  }

  watch(() => user.value, checkIfLoggedIn, { immediate: true, deep: false })
})
