//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref } from '@nuxtjs/composition-api'
import { useDomain } from '~/stores/domain'

export default defineComponent({
  inheritAttrs: false,
  props: {
    separator: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { goToHome } = useDomain()
    const toHome = ref('')
    if (!process.server) {
      toHome.value = goToHome()
    }
    return {
      toHome,
    }
  },
})
