











































































import { computed, defineComponent, ref, toRefs, useContext } from '@nuxtjs/composition-api'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { ALIGNEMENTS, ButtonOptions } from '~/utils/tiptap'

export default defineComponent({
  components: {
    NodeViewWrapper,
  },
  props: { ...nodeViewProps as any },
  setup(props) {
    const { updateAttributes, node: { value: { type: { name }, attrs: { textAlign } } } } = toRefs(props)
    const { $editor } = useContext()
    const active = computed(() => $editor.isActive(name))
    const buttonDrawer = ref(false)

    const remove = () => {
      $editor.chain().focus().deleteSelection().run()
    }

    const updateAlign = (textAlign: string) => {
      return updateAttributes.value({
        textAlign,
      })
    }

    const updateExpand = (fullWidth: boolean) => {
      return updateAttributes.value({
        fullWidth,
      })
    }

    const getAlign = computed(() => {
      switch (textAlign.value) {
        case ALIGNEMENTS.CENTER:
        case ALIGNEMENTS.JUSTIFY:
          return { 'text-align': true }
        case ALIGNEMENTS.RIGHT:
          return { 'tw-justify-end': true }
        case ALIGNEMENTS.LEFT:
        default:
          return { 'tw-justify-start': true }
      }
    })

    const updateButton = (attrs:ButtonOptions) => {
      return updateAttributes.value(attrs)
    }

    return {
      updateExpand,
      updateAlign,
      active,
      remove,
      buttonDrawer,
      updateButton,
      getAlign,
    }
  },
})
