
































import { defineComponent, PropType, toRefs } from '@nuxtjs/composition-api'
import KIcon, { IconSizes } from '~/components/icons/KIcon.vue'

export type MessageTypes = 'white' | 'dark' | 'info' | 'success' | 'warning' | 'error'
export type MessageSizes = 'sm' | 'md' | 'lg'
export type MessageProps = {
  toast?: boolean
  message?: string
  type: MessageTypes
  size?: MessageSizes
  active?: boolean
  closable?: boolean
  icon?: string
  iconSize?: IconSizes
}

export default defineComponent({
  components: { KIcon },
  props: {
    type: {
      type: String as PropType<MessageTypes>,
      default: 'success',
    },
    size: {
      type: String as PropType<MessageSizes>,
      default: 'md',
    },
    active: {
      type: Boolean,
      default: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: null,
    },
    iconSize: {
      type: String as PropType<IconSizes>,
      required: false,
      default: 'md',
      validator: (value: string) => ['xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value),
    },
  },
  setup(props, { emit }) {
    const { active } = toRefs(props)
    const close = () => {
      active.value = false
      emit('close', active.value)
    }

    return {
      close,
    }
  },
})
