import { Domain, ImageCropped } from '../types/utils'
import { Category } from './category'
import { Owner } from './user'

export enum MailTemplateEnum {
  WELCOME = 'welcome',
  INVITATION = 'invitation',
  BIOGRAPHY = 'biography',
  ABOUT = 'about',
  YEARLY_EXP = 'yearly_expiration',
}

export interface MailTemplate {
  key: MailTemplateEnum
  content: string
}

export interface PutMailTemplate extends MailTemplate {
  title?: string
  description?: string
  placeholder?: string
}

export interface BasePublication {
  id: string
  title?: string
  description?: string
  color_primary?: string
  categories?: Category[]
  cropped_banner_picture?: ImageCropped | null
  subdomain: Domain
  cropped_about_picture?: ImageCropped | null
  about_enabled: boolean
  comment_enabled: boolean
  about?: string | null
  about_biography?: string | null
  referral_enabled: boolean
  recommendation_enabled: boolean
  alias_enabled: boolean
  yearly_subscription_mail_enabled: boolean
  referral_description?: string | null
  mail_sender_mail?: string
  mail_sender_name?: string
  default_title_font?: string
  default_text_font?: string
}

export interface PutPublication extends Partial<BasePublication> {}

export interface PublicationOwner extends BasePublication {
  is_owner: boolean
}

export interface Publication extends PublicationOwner {
  title: string
  description: string
  payment_enabled: boolean
  likes_count: number
  color_primary: string
  color_secondary: string
  cropped_profile_picture?: ImageCropped
  cropped_banner_picture?: ImageCropped
  editor_rate: number
  subdomain: Domain
  owners: Owner[]
  alias: string
  total_posts: number
  total_drafts: number
  total_subscribers: number
  total_paid_subscribers: number
}

export interface PublicationAuthors extends Publication {
  text1: string
  text2: string
}

export interface PublicationSubscription
  extends Pick<Publication, 'id' | 'title' | 'cropped_banner_picture' | 'total_posts' | 'owners' | 'subdomain' | 'color_primary'> {}

export interface PublicationList
  extends Pick<
    Publication,
    | 'id'
    | 'title'
    | 'description'
    | 'owners'
    | 'cropped_banner_picture'
    | 'categories'
    | 'total_posts'
    | 'subdomain'
    | 'payment_enabled'
  > {}
