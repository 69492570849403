

export default (_to, _from, savedPosition) => {
      if (_to?.hash) {
        return { selector: _to.hash };
      }
      if (savedPosition) {
        return savedPosition;
      } else {
        return { y: 0, behavior: 'smooth' };
      }
    }
