var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('NodeViewWrapper',{staticClass:"image-element",class:{
    'expanded': _vm.node.attrs.expanded,
    'tw-w-full': !_vm.croppedFile
  }},[_c('b-tooltip',{staticClass:"tw-tooltip-editor tw-w-full",class:{
      'tw-flex-1': !_vm.croppedFile || _vm.node.attrs.expanded,
    },attrs:{"active":_vm.active,"triggers":['click'],"auto-close":['outside', 'escape'],"type":"is-white","position":"is-top","size":"is-large"}},[_c('KUploadFile',{ref:"kupload",staticClass:"tw-not-prose",class:{
        'tw-w-full': !_vm.croppedFile || _vm.node.attrs.expanded,
      },attrs:{"value":_vm.croppedFile,"is-crop-modal-active":_vm.isCropModalActive,"error":Object.assign({}, _vm.error, {toast: false}),"aspect":"free"},on:{"close-cropper":function($event){_vm.isCropModalActive = false},"input":_vm.updateCover}},[(_vm.croppedFile)?_c('div',{staticClass:"tw-w-full",attrs:{"slot":"image"},slot:"image"},[_c('div',{staticClass:"tw-relative tw-m-auto",class:{
            'tw-w-fit': !_vm.node.attrs.expanded,
          }},[_c('KImage',{staticClass:"element-image tw-not-prose",class:{
              'tw-aspect-banner': !_vm.croppedFile,
              'expanded': _vm.node.attrs.expanded,
            },style:(Object.assign({}, _vm.resizeFile && {
                width: !_vm.node.attrs.expanded && ((_vm.node.attrs.width) + "px")|| undefined,
                height: !_vm.node.attrs.expanded && ((_vm.node.attrs.height) + "px")|| undefined,
              })),attrs:{"alt":_vm.node.attrs.caption,"src":_vm.croppedFile,"lazy":false,"width":!_vm.node.attrs.expanded && _vm.node.attrs.width,"height":!_vm.node.attrs.expanded && _vm.node.attrs.height}}),_vm._v(" "),(_vm.featureResizedImage && _vm.croppedFile && !_vm.expanded)?_c('div',{ref:"image",staticClass:"resize-trigger"},[_c('div',{staticClass:"resize-button",on:{"mousedown":_vm.handler}},[_c('KIcon',{staticClass:"tw-absolute tw--translate-x-1 tw--translate-y-1 tw-left-3 tw-top-3",attrs:{"icon":"top-left","size":"xs"}}),_vm._v(" "),_c('KIcon',{staticClass:"tw-absolute tw-translate-x-1 tw-translate-y-1 tw-left-3 tw-top-3",attrs:{"icon":"bottom-right","size":"xs"}})],1)]):_vm._e()],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tw-hidden",attrs:{"slot":"actions"},slot:"actions"}),_vm._v(" "),_c('div',{staticClass:"tw-w-full",attrs:{"slot":"default"},slot:"default"},[_c('b-input',{attrs:{"value":_vm.node.attrs.caption,"size":"is-small","placeholder":_vm.$t('EDITOR_ADD_IMAGE_CAPTION_PLACEHOLDER'),"custom-class":"tw-w-full tw-text-center"},on:{"input":_vm.updateCaption}})],1)]),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-row tw-gap-2 tw-justify-center tw-items-center tw-p-1",attrs:{"slot":"content"},slot:"content"},[_c('Button',{attrs:{"icon":"bin","tooltip":_vm.$t('DELETE')},on:{"click":_vm.remove}}),_vm._v(" "),_c('Button',{attrs:{"icon":"edit","tooltip":_vm.$t('CHANGE')},on:{"click":_vm.forceUpload}}),_vm._v(" "),_c('Button',{attrs:{"icon":"crop","tooltip":_vm.$t('CROP')},on:{"click":function($event){_vm.isCropModalActive = true}}}),_vm._v(" "),(_vm.featureExpandedImage)?_c('Button',{attrs:{"icon":_vm.node.attrs.expanded ? 'minimise' : 'expand',"tooltip":_vm.$t(_vm.node.attrs.expanded ? 'MINIMISE' : 'EXPAND')},on:{"click":function($event){return _vm.expandImage(!_vm.node.attrs.expanded)}}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }