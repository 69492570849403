
































import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'

import { usePublication } from '~/stores/publication'

export default defineComponent({
  setup() {
    const route = useRoute()
    const { publication } = storeToRefs(usePublication())
    const isEditorView = computed(() => route.value.name && /(write|templates)/.test(route.value.name))
    const isSettings = computed(() => route.value.name && !isEditorView.value && /(settings)/.test(route.value.name))

    return {
      title: computed(() => publication.value?.title),
      route,
      isEditorView,
      isSettings,
    }
  },
})
