export default {
  type: 'object',
  properties: {
    mail: {
      enum: [
        true,
      ],
      type: 'boolean',
    },
    is_draft: {
      enum: [
        true,
      ],
      type: 'boolean',
    },
    id: {
      type: 'string',
    },
    title_draft: {
      type: 'string',
    },
    body_draft: {
      type: [
        'null',
        'string',
      ],
    },
    description_draft: {
      type: 'string',
    },
    cropped_image_draft: {},
    cropped_header_image_draft: {},
    pinned: {
      type: 'boolean',
    },
    publication_scheduled_at: {
      type: [
        'null',
        'string',
      ],
    },
    visibility_level: {},
    paywall_separator_position: {
      type: 'number',
    },
  },
  $schema: 'http://json-schema.org/draft-07/schema#',
}
