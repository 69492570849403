export const BASE64_URL_REGEX = /^data:.*s/gi

export const base64ToBlob = (dataURI) => {
  if (dataURI instanceof Blob) {
    return dataURI
  }
  const splitDataURI = dataURI.split(',')
  const byteString = splitDataURI[0].includes('base64') ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString, name: 'file.png' })
}

export const base64ToFile = (dataURI) => {
  const blob = base64ToBlob(dataURI)
  return new File([blob], 'file.png', { type: blob.type })
}

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => resolve(e.target.result)
    reader.onerror = (error) => reject(error)
  })
}
