import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { Context } from '@nuxt/types'
import { usePublication } from '~/stores/publication'
import { useDomain } from '~/stores/domain'

export default defineNuxtMiddleware((ctx: Context) => {
  try {
    const { redirect, isDev, req } = ctx

    // redirect to the publication subdomain got from api if the current Subdomain not match
    const { parseHostname } = useDomain(ctx.$pinia)
    const { publicationSubDomainName } = usePublication(ctx.$pinia)
    const { subdomain: currentSubdomain } = parseHostname(ctx)

    if (publicationSubDomainName && currentSubdomain !== publicationSubDomainName) {
      redirect(((isDev ? 'http://' : 'https://') + req.headers.host + req.url).replace(currentSubdomain, publicationSubDomainName))
    }
  } catch (e) {
    ctx.error({ statusCode: 404, message: 'validate sub domain middleware error', path: '/middleware/validateSubDomain.ts' })
  }
})
