
































































import { computed, defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { useDomain } from '~/stores/domain'
import { useUser } from '~/stores/user'
import { isEmail } from '~/utils/email'

export default defineComponent({
  setup(_, { root: { $buefy } }) {
    const { i18n, localePath, redirect } = useContext()
    const { pathToPublication } = useDomain()
    const { user } = storeToRefs(useUser())
    const email = ref(user.value?.email || '')
    const loading = ref(false)
    const error = ref('')

    const links = computed(() => [
      {
        label: i18n.t('ADVERTISERS'),
        href: 'mailto:publicite@kesselmedia.fr',
      },
      {
        label: i18n.t('FAQ'),
        to: localePath({ name: 'faq' }),
      },
      {
        label: i18n.t('RESSOURCES'),
        href: 'https://inbox.kessel.media/posts',
      },
      {
        label: i18n.t('CGU'),
        to: localePath({ name: 'cgu' }),
      },
      {
        label: i18n.t('PUBLICATION_TERMS'),
        to: localePath({ name: 'publication-terms' }),
      },
      {
        label: i18n.t('LEGAL_NOTICE_FOOTER'),
        to: localePath({ name: 'legal-notice' }),
      },
    ])

    const subscribe = () => {
      try {
        loading.value = true
        if (email.value && isEmail(email.value)) {
          return redirect(pathToPublication(null, 'hypertextes'), { email: email.value })
        }
        $buefy.toast.open({
          message: i18n.t('SUBSCRIBE_ERROR') as string,
          type: 'is-danger',
        })
      } finally {
        loading.value = false
      }
    }

    return {
      email,
      error,
      loading,
      links,
      subscribe,
    }
  },
})
