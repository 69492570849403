import { Context } from '@nuxt/types'
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { useLanding } from '~/stores/landing'

export default defineNuxtMiddleware(async (ctx: Context) => {
  const { syncLanding } = useLanding(ctx.$pinia)
  const { $sentry } = ctx

  try {
    await syncLanding()
  } catch (e) {
    console.error(e)
    $sentry?.captureException(e)
  }
})
