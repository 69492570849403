























import { computed, defineComponent, PropType, toRefs, useContext, useRoute } from '@nuxtjs/composition-api'

import { SubscriptionLevelName } from '@kessel/core'
import { useDomain } from '~/stores/domain'

export default defineComponent({
  props: {
    color: {
      type: String as PropType<'primary' | 'secondary' | 'normal'>,
      default: 'primary',
    },
    size: {
      type: String as PropType<'xs' | 'sm' | 'md' | 'lg' | 'xl'>,
      default: 'md',
      validator: (value: string) => ['xs', 'sm', 'md', 'lg', 'xl'].includes(value),
    },
    subscriptionLevelsLabel: {
      type: String as PropType<SubscriptionLevelName>,
      default: SubscriptionLevelName.UNKNOWN,
    },
    error: {
      type: Boolean,
      default: false,
    },
    userCanSubscribe: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { color, subscriptionLevelsLabel, userCanSubscribe, error } = toRefs(props)
    const route = useRoute()
    const { i18n, $config: { authorInscriptionDomain } } = useContext()
    const { parseHostname } = useDomain()

    const appDomainName = parseHostname().subdomain
    const inscriptionUrl = `${authorInscriptionDomain}/${appDomainName}`

    const subscribeButtonClick = () => {
      window.location.href = inscriptionUrl
    }

    const label = computed(() => i18n.t(subscriptionLevelsLabel.value))
    const isPreview = computed(() => route.value.path.startsWith('/publication'))
    const computedColor = computed(() => {
      if (error.value) {
        return 'warning'
      }
      if (!(userCanSubscribe.value && !isPreview.value)) {
        return 'normal'
      }

      return color.value
    })

    return {
      computedColor,
      subscribeButtonClick,
      label,
    }
  },
})
