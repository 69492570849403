






























import { defineComponent } from '@nuxtjs/composition-api'
import KTooltip from '~/components/KTooltip.vue'

export default defineComponent({
  components: { KTooltip },
  props: {
    rounded: {
      type: Boolean,
      default: true,
    },
    active: Boolean,
    disabled: Boolean,
    tooltip: {
      type: String,
      required: false,
      default: null,
    },
    tooltipPosition: {
      type: String,
      required: false,
      default: 'is-top',
    },
    tooltipAppendToBody: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
})
