import { Extension } from '@tiptap/core'

export interface TextIndentOptions {
  types: string[]
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    textIndent: {
      /**
       * Set the text indent attribute
       */
      setTextIndent: () => ReturnType
      unsetTextIndent: () => ReturnType
    }
  }
}

export const TextIndent = Extension.create<TextIndentOptions>({
  name: 'textIndent',

  group: 'block',

  defining: true,

  addOptions() {
    return {
      types: ['paragraph'],
      HTMLAttributes: {},
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          textIndent: {
            default: false,
            parseHTML: (element) => !!element.style.textIndent || undefined,
            renderHTML: (attributes) => {
              if (!attributes.textIndent) {
                return {}
              }

              return {
                class: 'tw-indent-8',
                style: 'text-indent:2rem;',
              }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setTextIndent:
        () =>
          ({ commands }) => {
            return this.options.types.every((type) => commands.updateAttributes(type, { textIndent: true }))
          },
      unsetTextIndent:
        () =>
          ({ commands }) => {
            return this.options.types.every((type) => commands.resetAttributes(type, 'textIndent'))
          },
    }
  },

  addKeyboardShortcuts() {
    return {
      'Shift-Tab': () => this.editor.commands.unsetTextIndent(),
      Tab: () => this.editor.commands.setTextIndent(),
    }
  },
})
