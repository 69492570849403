import { Context } from '@nuxt/types'
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { useOffer } from '~/stores/offer'

export default defineNuxtMiddleware(async (ctx: Context) => {
  const { syncOffer } = useOffer(ctx.$pinia)

  try {
    await syncOffer()
  } catch (e) {
    console.error(e)
    const { $sentry } = ctx
    $sentry?.captureException(e)
  }
})
