import {
  Category,
  CategoryParametersAuthors,
  CategoryParametersCategories,
  CategoryParametersNewsletters,
  CategoryParametersPosts,
  Publication,
} from '@kessel/core'
import { defineStore } from 'pinia'

interface State {
  categories: Category[]
  category: Category | null
  categoryNewsletters: Partial<Publication>[]
  categoriesViaSlug: Category[]
  categoryAuthors: Partial<Publication>[]
}

export const useCategory = defineStore('category', {
  state: (): State => ({
    categories: [],
    category: null,
    categoryNewsletters: [],
    categoriesViaSlug: [],
    categoryAuthors: [],
  }),
  actions: {
    async syncCategories() {
      const { $patch, getCategories } = this

      $patch({
        categories: (await getCategories()).items,
      })
    },
    async syncCategory(slug: string) {
      const {
        $patch,
        $nuxt: { error },
      } = this
      try {
        const category = await this.getCategory(slug)
        const newsletters = await this.getCategoryNewsletters(slug, { size: 10 })
        const categoriesViaSlug = await this.getCategoriesViaSlug(slug, { size: 8 })
        const authors = await this.getCategoryAuthor(slug, { size: 5 })
        $patch({
          category,
          categoryNewsletters: newsletters.items,
          categoriesViaSlug: categoriesViaSlug.items,
          categoryAuthors: authors.items,
        })
      } catch (e: any) {
        error({ statusCode: 404, message: e.message })
      }
    },
    async getCategory(slug: string) {
      const {
        $patch,
        categories,
        $nuxt: { $axios },
      } = this

      const category = await $axios.$get(`/v1/category/${slug}`)

      const hasCategory = categories.find((category: Category) => category.slug === slug)

      if (!hasCategory) {
        $patch((state: State) => {
          state.categories.push(category)
        })
      }

      return category
    },
    async getCategories() {
      return await this.$nuxt.$axios.$get('/v1/categories')
    },
    async getCategoryNewsletters(slug: string, params: CategoryParametersNewsletters) {
      return await this.$nuxt.$axios.$get(`/v1/category/${slug}/publications`, { params })
    },
    async getCategoryPosts({ slug, ...params }: CategoryParametersPosts) {
      return await this.$nuxt.$axios.$get(`/v1/category/${slug}/posts`, { params })
    },
    async getCategoriesViaSlug(slug: string, params: CategoryParametersCategories) {
      return await this.$nuxt.$axios.$get(`/v1/category/${slug}/categories`, { params })
    },
    async getCategoryAuthor(slug: string, params: CategoryParametersAuthors) {
      return await this.$nuxt.$axios.$get(`/v1/category/${slug}/authors`, { params })
    },
  },
})
