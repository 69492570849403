
























































import { computed, defineComponent, ref, toRefs, PropType } from '@nuxtjs/composition-api'
import { useThemeColor } from '~/composables/useTheme'

export default defineComponent({
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    colors: {
      type: Array as PropType<string[]>,
      required: true,
    },
    canReset: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { value, colors: propsColors } = toRefs(props)
    const customColor = ref()
    const { extractColorFromTheme } = useThemeColor()
    const colorList = computed(() => {
      if (props.value && !propsColors.value.includes(props.value)) {
        propsColors.value.push(props.value)
      }
      if (props.default) {
        if (propsColors.value.includes(props.default)) {
          propsColors.value.splice(propsColors.value.indexOf(props.default), 1)
        }
        propsColors.value.splice(1, 0, props.default)
      }
      if (props.canReset) {
        if (!propsColors.value.includes('none')) {
          propsColors.value.splice(0, 0, 'none')
        }
      }
      return propsColors.value
    })
    const setCustomColor = (value: string) => {
      customColor.value = value === 'none' ? undefined : value
      emit('input', customColor.value)
    }

    const colorExtracted = computed(() => extractColorFromTheme(value.value))

    return {
      isDark: computed(() => colorExtracted.value.isDark),
      colorList,
      setCustomColor,
      customColor,
    }
  },
})
