




















import { computed, defineComponent } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { usePublication } from '~/stores/publication_v2'

export default defineComponent({
  setup() {
    const { publication } = storeToRefs(usePublication())

    const isAuthorized = computed(() => publication.value?.about_enabled)
    return {
      isAuthorized,
    }
  },
})
