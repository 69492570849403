











import { DEFAULT_LOGO_PLACEHOLDER, ImageCropped } from '@kessel/core'
import { PropType, computed, defineComponent, toRefs } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { useApp } from '~/stores/app'

export default defineComponent({
  props: {
    src: {
      type: [Object, String] as PropType<ImageCropped | string>,
      default: null,
    },
    format: {
      type: String,
      required: false,
      default: null,
    },
    alt: {
      type: String,
      default: '',
    },
    modifiers: {
      type: Object,
      default: null,
    },
    fallbackColor: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { src, format, modifiers, fallbackColor } = toRefs(props)

    const { colors } = storeToRefs(useApp())

    return {
      computedModifiers: computed(() =>
        ({
          q: 100,
          auto: 'format',
          ...format.value && { fm: format.value },
          ...modifiers.value,
          ...src.value && typeof src.value === 'object' && src.value.width && src.value.height && { rect: `${src.value.x},${src.value.y},${src.value.width},${src.value.height}` },
          ...!src.value && {
            bg: (fallbackColor.value || colors.value?.colorPrimary || '000000').replace('#', ''),
            pad: 20,
          },
        })),
      computedSource: computed(() => {
        if (!src.value) {
          return DEFAULT_LOGO_PLACEHOLDER
        }
        if (typeof src.value === 'string') {
          return src.value || DEFAULT_LOGO_PLACEHOLDER
        }
        if (typeof src.value === 'object' && !!src.value?.filename) {
          return src.value.filename || DEFAULT_LOGO_PLACEHOLDER
        }
        return DEFAULT_LOGO_PLACEHOLDER
      }),
    }
  },
})

