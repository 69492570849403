import Vue from 'vue'
import Buefy from 'buefy'

Vue.use(Buefy, {
  "css": false,
  "materialDesignIcons": false,
  "materialDesignIconsHRef": "https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css",
  "async": true,
  "defaultProgrammaticPromise": true,
  "defaultIconComponent": "B2KIcon",
  "defaultIconPack": "teeny",
  "customIconPacks": {
    "teeny": {
      "sizes": {
        "default": "md",
        "is-small": "sm",
        "is-medium": "md",
        "is-large": "lg"
      },
      "internalIcons": {
        "eye-off": "eye-closed",
        "alert-circle": "exclamation-circle",
        "magnify": "search",
        "search": "search"
      }
    }
  }
})