import { Plugin } from '@nuxt/types'

import algoliaSearch, { SearchClient } from 'algoliasearch/lite'
import recommend, { RecommendClient } from '@algolia/recommend'

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $searchClient: SearchClient
    $recommendClient: RecommendClient
  }
  interface Context {
    $searchClient: SearchClient
    $recommendClient: RecommendClient
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $searchClient: SearchClient
    $recommendClient: RecommendClient
  }
}

const searchClient: Plugin = (context, inject) => {
  context.$searchClient = algoliaSearch(
    context.$config.algolia.applicationId,
    context.$config.algolia.apiKey
  )

  context.$recommendClient = recommend(
    context.$config.algolia.applicationId,
    context.$config.algolia.apiKey
  )

  inject('searchClient', () => context.$searchClient)
  inject('recommendClient', () => context.$recommendClient)
}

export default searchClient
