import type { Context } from '@nuxt/types'
import { PaginationNames } from '@kessel/core'
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { usePagination } from '~/stores/pagination'

export default defineNuxtMiddleware((ctx: Context) => {
  const { createPagination } = usePagination(ctx.$pinia)

  createPagination(PaginationNames.NEWSLETTER_POSTS, { scroll: { infinite: true, auto: true } })
  createPagination(PaginationNames.NEWSLETTER_POSTS_PINNED, { scroll: { infinite: true, auto: true } })
  createPagination(PaginationNames.NEWSLETTER_POST_COMMENTS, { scroll: { infinite: true }, event: true })

  createPagination(PaginationNames.CATEGORY_POSTS, { scroll: { infinite: true, auto: true } })

  createPagination(PaginationNames.DASHBOARD_SUBSCRIPTIONS, { scroll: { infinite: true, auto: true }, event: true })
  createPagination(PaginationNames.DASHBOARD_POSTS, { event: true })
  createPagination(PaginationNames.DASHBOARD_POSTS_PINNED, { scroll: { infinite: true, auto: true }, event: true })
  createPagination(PaginationNames.DASHBOARD_DRAFTS, { event: true })
  createPagination(PaginationNames.DASHBOARD_SUBSCRIBERS, { scroll: { infinite: true, auto: true }, event: true })
})
