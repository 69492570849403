var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.to ? 'nuxt-link' : _vm.href ? 'a' : 'button',_vm._b({tag:"component",staticClass:"tw-button-wrapper",class:[
    _vm.getColor,
    _vm.getTextColor,
    _vm.getSize,
    _vm.getDirection,
    _vm.getActive,
    { 'tw-cursor-not-allowed tw-opacity-50 tw-bg-none': _vm.$attrs.disabled },
    ("tw-rounded-" + (_vm.radius || 'lg')),
    _vm.expanded ? 'tw-max-w-none tw-w-full' : 'tw-max-w-max' ],style:([_vm.getBackgroundColor]),attrs:{"to":_vm.to,"href":_vm.to || _vm.href,"title":_vm.title},on:{"click":function($event){return _vm.$emit('click', $event)}}},'component',_vm.$attrs,false),[(_vm.iconLeft)?_c('KIcon',{attrs:{"icon":_vm.getIconLeft,"variant":"outline","size":_vm.size}}):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.icon || _vm.loading)?_c('KIcon',{class:{ 'tw-animate-spin': _vm.loading },attrs:{"icon":_vm.getIcon,"variant":"outline","size":_vm.size}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }