import tinycolor from 'tinycolor2'

export const generateLinear = (hex: string) => {
  return `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${hex};`
}

export const isHexColor = (color: string) => /(#)[a-zA-Z0-9]+/g.test(color)

export const extractColor = (theme: any, colorName = 'primary'): string | boolean => {
  let colorFromTheme = (!isHexColor(colorName) && theme.colors[colorName]) || colorName

  if (typeof colorFromTheme === 'string') {
    colorFromTheme = colorFromTheme.replace('var(', '').replace(')', '').replace(' ', '')
    if (!isHexColor(colorFromTheme) && !process.server) {
      try {
        const computedSyledColor = getComputedStyle(document.body).getPropertyValue(colorFromTheme)

        if (computedSyledColor && isHexColor(computedSyledColor)) {
          colorFromTheme = computedSyledColor
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  const detectedColor = tinycolor(colorFromTheme)

  return detectedColor.isValid() && detectedColor.toHexString()
}

export const isValidColor = (theme: any, color = 'primary'): boolean => {
  return !!extractColor(theme, color)
}

export const getHexColor = (theme: any, colorName = 'primary'): string => {
  const colorFromTheme = extractColor(theme, colorName) || colorName

  const detectedColor = tinycolor(colorFromTheme)

  return detectedColor.toHexString()
}
export const isValid = (color: string): string => {
  return tinycolor(color).isValid()
}
export const toHexString = (color: string): string => {
  return tinycolor(color).toHexString()
}

export const isDarkColor = (color: string) => {
  // return tinycolor(color).getBrightness() < 240 // the threshold to be optimised for a better UX
  // Line below is better for accessibility but not for UX
  return tinycolor.readability('#FFFFFF', color) > 3 // #FFFFFF should be replaced by dark or light theme color better UX
}

export const addAlpha = (color: string, alpha: number) => tinycolor(color).setAlpha(alpha).toRgbString()

export const isLightValue = (color: any) => {
  return !tinycolor(color).isLight()
}
