import { render, staticRenderFns } from "./NavbarDashboard.vue?vue&type=template&id=d8ba1038&"
import script from "./NavbarDashboard.vue?vue&type=script&lang=ts&"
export * from "./NavbarDashboard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KButton: require('/tmp/build_84effd69/apps/web/components/buttons/KButton.vue').default,AuthorPicture: require('/tmp/build_84effd69/apps/web/components/author/AuthorPicture.vue').default,KIcon: require('/tmp/build_84effd69/apps/web/components/icons/KIcon.vue').default,Navbar: require('/tmp/build_84effd69/apps/web/components/navbar/Navbar.vue').default})
