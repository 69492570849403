import { computed, useContext, useMeta } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { useApp } from '~/stores/app'
import { getHexColor, isDarkColor, isHexColor, isValidColor } from '~/utils/color'

export const useThemeColor = () => {
  const {
    $config: { theme },
  } = useContext()

  const extractColorFromTheme = (color?: string) => {
    const hexColor = getHexColor(theme, color)

    return {
      hexColor,
      isDark: isDarkColor(hexColor),
      isValid: isValidColor(theme, color),
    }
  }

  return { extractColorFromTheme, isHexColor }
}

export const useCustomTheme = () => {
  const {
    $config: { theme },
  } = useContext()
  const { colors } = storeToRefs(useApp())

  const { extractColorFromTheme } = useThemeColor()

  const primary = computed(() => extractColorFromTheme(colors.value.colorPrimary || theme.colors.primary))
  const secondary = computed(() => extractColorFromTheme(colors.value.colorSecondary || theme.colors.secondary))

  useMeta(() => {
    const cssVariables = {
      'color-primary': primary.value.hexColor,
      'color-secondary': secondary.value.hexColor,
    }

    const style = Object.entries(cssVariables)
      .map(([key, value]) => `--${key}:${value}`)
      .join(';')

    return {
      bodyAttrs: {
        style,
      },
    }
  })
}

export const useCustomThemeStyle = () => {
  const {
    $config: { theme },
  } = useContext()
  const { colors } = storeToRefs(useApp())

  const { extractColorFromTheme } = useThemeColor()

  const primary = computed(() => extractColorFromTheme(colors.value.colorPrimary || theme.colors.primary))
  const secondary = computed(() => extractColorFromTheme(colors.value.colorSecondary || theme.colors.secondary))

  const cssVariables = {
    'color-primary': primary.value.hexColor,
    'color-secondary': secondary.value.hexColor,
  }

  const style = Object.entries(cssVariables)
    .map(([key, value]) => `--${key}:${value}`)
    .join(';')

  return {
    style,
  }
}
