/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AccountType */
export enum KAccountType {
  Platform = 'platform',
  Connect = 'connect',
}

/** Address */
export interface KAddress {
  /** City */
  city?: string | null
  /** Country */
  country?: string | null
  /** Line1 */
  line1?: string | null
  /** Line2 */
  line2?: string | null
  /** Postal Code */
  postal_code?: string | null
  /** State */
  state?: string | null
}

/**
 * AttachPlanResponse
 * The subscription confirmation response
 */
export interface KAttachPlanResponse {
  /** State */
  state: string
  payment_info?: KPaymentInfoResponse | null
  /** Session */
  session?: null
  /** Publishable Key */
  publishable_key?: null
  subscription?: KReturnSubscriptionModel | null
}

/** BillingDetails */
export interface KBillingDetails {
  address?: KAddress | null
  /** Email */
  email?: string | null
  /** Name */
  name?: string | null
  /** Phone */
  phone?: string | null
}

/** Body__substack_import_newsletter__pub_id__substack_import_post */
export interface KBodySubstackImportNewsletterPubIdSubstackImportPost {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body__substack_import_publication__pub_id__substack_import_post */
export interface KBodySubstackImportPublicationPubIdSubstackImportPost {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_create_upload_file_files_upload_file_post */
export interface KBodyCreateUploadFileFilesUploadFilePost {
  /**
   * File
   * @format binary
   */
  file: File
}

/** Body_oauth_auth_oauth_post */
export interface KBodyOauthAuthOauthPost {
  /** Grant Type */
  grant_type?: string | null
  /** Username */
  username: string
  /** Password */
  password: string
  /**
   * Scope
   * @default ""
   */
  scope?: string
  /** Client Id */
  client_id?: string | null
  /** Client Secret */
  client_secret?: string | null
}

/** Body_post_unsubscribe_subscription_unsubscribe_post */
export interface KBodyPostUnsubscribeSubscriptionUnsubscribePost {
  /** List Unsubscribe */
  list_unsubscribe: string
}

/** Card */
export interface KCard {
  /** Brand */
  brand: string
  /** Exp Month */
  exp_month: number
  /** Exp Year */
  exp_year: number
  /** Last4 */
  last4: string
}

/**
 * CategoryModel
 * the category model in pydantic
 */
export interface KCategoryModel {
  /** Id */
  id?: string | null
  /** Name */
  name: string
  /** Slug */
  slug?: string | null
  /** Created At */
  created_at?: string | null
  /** Total Publications */
  total_publications?: number | null
  /** Weight */
  weight?: number | null
}

/**
 * ChangePasswordRequest
 * The password
 */
export interface KChangePasswordRequest {
  /** Newpass */
  newpass: string
}

/** CommentAuthorModel */
export interface KCommentAuthorModel {
  /** Id */
  id: string
  /** Full Name */
  full_name?: string | null
  cropped_picture?: KImageModel | null
}

/** CommentFilterEnum */
export type KCommentFilterEnum = any

/** CommentModel */
export interface KCommentModel {
  /** Id */
  id: string
  user: KCommentAuthorModel
  /**
   * Created At
   * @format date-time
   */
  created_at: string
  /** Updated At */
  updated_at?: string | null
  /** Content */
  content: string
  /** Likes Count */
  likes_count?: number | null
  /**
   * Is Current User Liked
   * @default false
   */
  is_current_user_liked?: boolean | null
}

/** CommentSortEnum */
export enum KCommentSortEnum {
  CreatedAt = 'created_at',
}

/** CommentStats */
export interface KCommentStats {
  /** Total */
  total: number
}

/** CommentUpdateModel */
export interface KCommentUpdateModel {
  /** Content */
  content: string
}

/**
 * DomainAvailabilityResponse
 * The response for domain availability request
 */
export interface KDomainAvailabilityResponse {
  /** Is Available */
  is_available: boolean
  /** Used By */
  used_by?: string | null
}

/**
 * FileMeta
 * The file meta to return
 */
export interface KFileMeta {
  /** Url */
  url: string
}

/**
 * FileUploadResponse
 * The file upload response for the uploading
 */
export interface KFileUploadResponse {
  /** Success */
  success: number
  /** The file meta to return */
  file: KFileMeta
}

/** HTTPValidationError */
export interface KHTTPValidationError {
  /** Detail */
  detail?: KValidationError[]
}

/** ImageModel */
export interface KImageModel {
  /** Filename */
  filename?: string | null
  /** X */
  x?: number | null
  /** Y */
  y?: number | null
  /** Width */
  width?: number | null
  /** Height */
  height?: number | null
}

/** ImportRequest */
export interface KImportRequest {
  /** Users */
  users: KSubscriberImportModel[]
}

/** InvitationList */
export interface KInvitationList {
  /** Users */
  users: KSubscriberImportModel[]
}

/** LightPublicationModel */
export interface KLightPublicationModel {
  /** Id */
  id: string
  /** Title */
  title?: string | null
  /** Owners */
  owners: KUserProfileModel[]
  subdomain?: KSubdomainModel | null
  cropped_banner_picture?: KImageModel | null
  /** Total Posts */
  total_posts?: number | null
  /** Color Primary */
  color_primary?: string | null
  /** Color Secondary */
  color_secondary?: string | null
  /** Color Accent */
  color_accent?: string | null
}

/** LikeStats */
export interface KLikeStats {
  /** Total */
  total: number
}

/** Link */
export interface KLink {
  /** Url */
  url: string
  /** Label */
  label?: string | null
  /**
   * Count
   * @default 0
   */
  count?: number
  /**
   * Percentage
   * @default 0
   */
  percentage?: number
}

/** ListPostModel */
export interface KListPostModel {
  /** Id */
  id?: string | null
  /** Title */
  title?: string | null
  cropped_image?: KImageModel | null
  cropped_header_image?: KImageModel | null
  /** Description */
  description?: string | null
  /** Keywords */
  keywords?: string[] | null
  /** Visibility Level */
  visibility_level?: number | null
  /** Created At */
  created_at?: string | null
  /** Last Updated */
  last_updated?: string | null
  /** Pinned */
  pinned?: boolean | null
  /** Is Draft */
  is_draft?: boolean | null
  publication?: KUltraLightPublicationModel | null
  /** Publication Id */
  publication_id?: string | null
  /** Subdomain */
  subdomain?: string | null
  /** Reading Time */
  reading_time?: number | null
  /** Published At */
  published_at?: string | null
  /** Likes Count */
  likes_count?: number | null
  /** Is Current User Liked */
  is_current_user_liked?: boolean | null
}

/** ListPostOwnerModel */
export interface KListPostOwnerModel {
  /** Id */
  id?: string | null
  /** Title */
  title?: string | null
  cropped_image?: KImageModel | null
  cropped_header_image?: KImageModel | null
  /** Description */
  description?: string | null
  /** Keywords */
  keywords?: string[] | null
  /** Visibility Level */
  visibility_level?: number | null
  /** Created At */
  created_at?: string | null
  /** Last Updated */
  last_updated?: string | null
  /** Pinned */
  pinned?: boolean | null
  /** Is Draft */
  is_draft?: boolean | null
  publication?: KLightPublicationModel | null
  /** Publication Id */
  publication_id?: string | null
  /** Subdomain */
  subdomain?: string | null
  /** Reading Time */
  reading_time?: number | null
  /** Published At */
  published_at?: string | null
  /** Likes Count */
  likes_count?: number | null
  /** Is Current User Liked */
  is_current_user_liked?: boolean | null
  stats?: KPostStatsModel | null
  /** Title Draft */
  title_draft?: string | null
  /** Description Draft */
  description_draft?: string | null
  cropped_image_draft?: KImageModel | null
  cropped_header_image_draft?: KImageModel | null
  /** Publication Scheduled At */
  publication_scheduled_at?: string | null
  /** Paywall Separator Position */
  paywall_separator_position?: number | null
  /**
   * Is Owner
   * @default true
   */
  is_owner?: boolean
}

/** ListPublicationModel */
export interface KListPublicationModel {
  /** Id */
  id?: string | null
  /** Title */
  title?: string | null
  /** Description */
  description?: string | null
  cropped_banner_picture?: KImageModel | null
  /** Total Posts */
  total_posts?: number | null
  /** Owners */
  owners: KUserProfileModel[]
  /** Categories */
  categories?: KCategoryModel[] | null
  subdomain?: KSubdomainModel | null
}

/**
 * ListSubscriptionModel
 * To return the subscription information
 */
export interface KListSubscriptionModel {
  /** Id */
  id: string
  /** Subscription Level */
  subscription_level: number
  /** Active Till */
  active_till?: string | null
  /** Last Payment */
  last_payment?: string | null
  /** Publication Id */
  publication_id: string
  publication: KLightPublicationModel
  payment_status?: KPaymentStatus | null
  /**
   * Created At
   * @format date-time
   */
  created_at: string
  /** Deleted At */
  deleted_at?: string | null
  /** Mailing Activated */
  mailing_activated?: boolean | null
  /** Mailing Enabled */
  mailing_enabled?: boolean | null
}

/** LoginModel */
export interface KLoginModel {
  /**
   * Username
   * @format email
   */
  username: string
  /** Password */
  password: string
}

/** MailEventClickStats */
export interface KMailEventClickStats {
  /**
   * Links
   * @default []
   */
  links?: KLink[]
  /**
   * Total
   * @default 0
   */
  total?: number
}

/** MailEventStats */
export interface KMailEventStats {
  /**
   * Delivery
   * @default 0
   */
  delivery?: number
  /**
   * Pre Send
   * @default 0
   */
  pre_send?: number
  /**
   * Send
   * @default 0
   */
  send?: number
  /**
   * Open
   * @default 0
   */
  open?: number
  /**
   * Proxy Open
   * @default 0
   */
  proxy_open?: number
  /**
   * First Open
   * @default 0
   */
  first_open?: number
  /**
   * Soft Bounce
   * @default 0
   */
  soft_bounce?: number
  /**
   * Hard Bounce
   * @default 0
   */
  hard_bounce?: number
  /**
   * Complaint
   * @default 0
   */
  complaint?: number
  /**
   * Click
   * @default 0
   */
  click?: number
  /**
   * Unsubscribed
   * @default 0
   */
  unsubscribed?: number
  /**
   * Opening Rate
   * @default 0
   */
  opening_rate?: number
  /**
   * Error
   * @default 0
   */
  error?: number
}

/** MailTemplateDeleteModel */
export interface KMailTemplateDeleteModel {
  /** Publication Id */
  publication_id: string
  key: KMailTemplateEnum
}

/** MailTemplateEnum */
export enum KMailTemplateEnum {
  About = 'about',
  Biography = 'biography',
  Welcome = 'welcome',
  Invitation = 'invitation',
  YearlyExpiration = 'yearly_expiration',
}

/** MailTemplateModel */
export interface KMailTemplateModel {
  /** Publication Id */
  publication_id: string
  /** Created At */
  created_at?: string | null
  /** Updated At */
  updated_at?: string | null
  key: KMailTemplateEnum
  /** Content */
  content: string
}

/**
 * OfferGetModel
 * Offer plus the prices
 */
export interface KOfferGetModel {
  /** Free Description */
  free_description?: string | null
  /** Monthly Price */
  monthly_price?: number | null
  /** Monthly Description */
  monthly_description?: string | null
  /** Yearly Price */
  yearly_price?: number | null
  /** Yearly Description */
  yearly_description?: string | null
  /** Premium Price */
  premium_price?: number | null
  /** Premium Description */
  premium_description?: string | null
  /** Premium Allow Bargin */
  premium_allow_bargin?: boolean | null
  /** Subscription Enabled */
  subscription_enabled?: boolean | null
  /** Premium Subscription Enabled */
  premium_subscription_enabled?: boolean | null
  /** Trial Eligible */
  trial_eligible?: boolean | null
  /**
   * Stripe Price Id Free
   * @default "price_free"
   */
  stripe_price_id_free?: string | null
  /** Stripe Price Id Monthly */
  stripe_price_id_monthly?: string | null
  /** Stripe Price Id Yearly */
  stripe_price_id_yearly?: string | null
  /** Stripe Price Id Premium */
  stripe_price_id_premium?: string | null
}

/**
 * OfferModel
 * The pydantic model of the offer
 */
export interface KOfferModel {
  /** Free Description */
  free_description?: string | null
  /** Monthly Price */
  monthly_price?: number | null
  /** Monthly Description */
  monthly_description?: string | null
  /** Yearly Price */
  yearly_price?: number | null
  /** Yearly Description */
  yearly_description?: string | null
  /** Premium Price */
  premium_price?: number | null
  /** Premium Description */
  premium_description?: string | null
  /** Premium Allow Bargin */
  premium_allow_bargin?: boolean | null
  /** Subscription Enabled */
  subscription_enabled?: boolean | null
  /** Premium Subscription Enabled */
  premium_subscription_enabled?: boolean | null
  /** Trial Eligible */
  trial_eligible?: boolean | null
}

/** OwnerPostModel */
export interface KOwnerPostModel {
  /** Id */
  id?: string | null
  /** Title */
  title?: string | null
  cropped_image?: KImageModel | null
  cropped_header_image?: KImageModel | null
  cropped_header_image_draft?: KImageModel | null
  /** Description */
  description?: string | null
  /** Body */
  body?: string | null
  /** Keywords */
  keywords?: string[] | null
  /** Visibility Level */
  visibility_level?: number | null
  /** Created At */
  created_at?: string | null
  /** Last Updated */
  last_updated?: string | null
  /** Pinned */
  pinned?: boolean | null
  /** Is Draft */
  is_draft?: boolean | null
  publication?: KLightPublicationModel | null
  /** Publication Id */
  publication_id?: string | null
  /** Subdomain */
  subdomain?: string | null
  /** Body Size */
  body_size?: number | null
  /** Reading Time */
  reading_time?: number | null
  /** Published At */
  published_at?: string | null
  /** Publication Scheduled At */
  publication_scheduled_at?: string | null
  stats?: KPostStatsModel | null
  /** Likes Count */
  likes_count?: number | null
  /** Mail */
  mail?: boolean | null
  /** Title Draft */
  title_draft?: string | null
  /** Body Draft */
  body_draft?: string | null
  cropped_image_draft?: KImageModel | null
  /** Description Draft */
  description_draft?: string | null
  /** Republished At */
  republished_at?: string | null
  /** Newsletter Sent */
  newsletter_sent?: boolean | null
  /** Is Current User Liked */
  is_current_user_liked?: boolean | null
  /**
   * Type
   * @default "owner_post"
   */
  type?: string
  /** Paywall Separator Position */
  paywall_separator_position?: number | null
  /**
   * Is Owner
   * @default true
   */
  is_owner?: boolean
}

/** Page[CategoryModel] */
export interface KPageCategoryModel {
  /** Items */
  items: KCategoryModel[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** Page[CommentModel] */
export interface KPageCommentModel {
  /** Items */
  items: KCommentModel[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** Page[ListPostModel] */
export interface KPageListPostModel {
  /** Items */
  items: KListPostModel[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** Page[ListPostOwnerModel] */
export interface KPageListPostOwnerModel {
  /** Items */
  items: KListPostOwnerModel[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** Page[ListPublicationModel] */
export interface KPageListPublicationModel {
  /** Items */
  items: KListPublicationModel[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** Page[ListSubscriptionModel] */
export interface KPageListSubscriptionModel {
  /** Items */
  items: KListSubscriptionModel[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** Page[PublicationReadOwnerModel] */
export interface KPagePublicationReadOwnerModel {
  /** Items */
  items: KPublicationReadOwnerModel[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** Page[SubscriptionReadModel] */
export interface KPageSubscriptionReadModel {
  /** Items */
  items: KSubscriptionReadModel[]
  /** Total */
  total: number | null
  /** Page */
  page: number | null
  /** Size */
  size: number | null
  /** Pages */
  pages?: number | null
}

/** PaymentInfoResponse */
export interface KPaymentInfoResponse {
  /** Subscription Id */
  subscription_id: string
  /** Client Secret */
  client_secret: string
  /** Publishable Key */
  publishable_key: string
  /** Hosted Invoice Url */
  hosted_invoice_url: string
  /** Paid */
  paid: boolean
}

/** PaymentMethod */
export interface KPaymentMethod {
  /** Id */
  id: string
  /** Object */
  object: string
  /** Metadata */
  metadata?: object | null
  billing_details: KBillingDetails
  card: KCard
  /** Customer */
  customer: string
}

/** PaymentStatus */
export enum KPaymentStatus {
  UNKNOWN = 'UNKNOWN',
  ACTIVE = 'ACTIVE',
  INCOMPLETE = 'INCOMPLETE',
  PAST_DUE = 'PAST_DUE',
  CANCELLATION = 'CANCELLATION',
  CANCELED = 'CANCELED',
}

/** PostByCategoryFilterEnum */
export enum KPostByCategoryFilterEnum {
  LatestPosts = 'latest_posts',
}

/** PostByCategorySortEnum */
export enum KPostByCategorySortEnum {
  TotalSubscribers = 'total_subscribers',
  LastUpdated = 'last_updated',
}

/**
 * PostPostModel
 * For receiving POST
 */
export interface KPostPostModel {
  /** Id */
  id?: string | null
  /** Title Draft */
  title_draft?: string | null
  /** Body Draft */
  body_draft?: string | null
  /** Description Draft */
  description_draft?: string | null
  cropped_image_draft?: KImageModel | null
  cropped_header_image?: KImageModel | null
  cropped_header_image_draft?: KImageModel | null
  /** Publication Scheduled At */
  publication_scheduled_at?: string | null
  /** Mail */
  mail?: boolean | null
  /** Is Draft */
  is_draft?: boolean | null
  /** Pinned */
  pinned?: boolean | null
  /** Visibility Level */
  visibility_level?: number | null
  /** Paywall Separator Position */
  paywall_separator_position?: number | null
}

/** PostStatsModel */
export interface KPostStatsModel {
  email?: KMailEventStats | null
  click?: KMailEventClickStats | null
  view?: KViewStats | null
  comment?: KCommentStats | null
  like?: KLikeStats | null
  subscription?: KSubscriptionStats | null
  traffic?: KTrafficStats | null
}

/** PostsFilterEnum */
export enum KPostsFilterEnum {
  Public = 'public',
  Published = 'published',
  Draft = 'draft',
  MailSent = 'mail_sent',
  Pinned = 'pinned',
}

/** PostsSortEnum */
export enum KPostsSortEnum {
  LastUpdated = 'last_updated',
  PublishedAt = 'published_at',
  ScheduledAt = 'scheduled_at',
  CreatedAt = 'created_at',
  Title = 'title',
  TitleDraft = 'title_draft',
  Likes = 'likes',
  ReadingTime = 'reading_time',
}

/** Price */
export interface KPrice {
  /** Id */
  id: string
  /** Object */
  object: string
  /** Metadata */
  metadata?: object | null
  /** Active */
  active: boolean
  /** Currency */
  currency: string
  /** Nickname */
  nickname?: string | null
  /** Product */
  product: string
  /** Unit Amount */
  unit_amount: number
}

/** PublicFullPostModel */
export interface KPublicFullPostModel {
  /** Id */
  id?: string | null
  /** Title */
  title?: string | null
  cropped_image?: KImageModel | null
  cropped_header_image?: KImageModel | null
  cropped_header_image_draft?: KImageModel | null
  /** Description */
  description?: string | null
  /** Body */
  body?: string | null
  /** Keywords */
  keywords?: string[] | null
  /** Visibility Level */
  visibility_level?: number | null
  /** Created At */
  created_at?: string | null
  /** Last Updated */
  last_updated?: string | null
  /** Pinned */
  pinned?: boolean | null
  /** Is Draft */
  is_draft?: boolean | null
  publication?: KLightPublicationModel | null
  /** Publication Id */
  publication_id?: string | null
  /** Subdomain */
  subdomain?: string | null
  /** Body Size */
  body_size?: number | null
  /** Reading Time */
  reading_time?: number | null
  /** Published At */
  published_at?: string | null
  /** Publication Scheduled At */
  publication_scheduled_at?: string | null
  stats?: KPostStatsModel | null
  /** Likes Count */
  likes_count?: number | null
  /** Mail */
  mail?: boolean | null
  /** Title Draft */
  title_draft?: string | null
  /** Body Draft */
  body_draft?: string | null
  cropped_image_draft?: KImageModel | null
  /** Description Draft */
  description_draft?: string | null
  /** Republished At */
  republished_at?: string | null
  /** Newsletter Sent */
  newsletter_sent?: boolean | null
  /** Is Current User Liked */
  is_current_user_liked?: boolean | null
  /**
   * Type
   * @default "public_full_post"
   */
  type?: string
}

/** PublicationLikesStatsModel */
export interface KPublicationLikesStatsModel {
  /** Total */
  total: number
}

/** PublicationReadModel */
export interface KPublicationReadModel {
  /** Title */
  title?: string | null
  /** Categories */
  categories?: KCategoryModel[] | null
  /** Description */
  description?: string | null
  /** Created At */
  created_at?: string | null
  /** Payment Enabled */
  payment_enabled?: boolean | null
  /** Payment Activated */
  payment_activated?: boolean | null
  /** Color Primary */
  color_primary?: string | null
  /** Color Secondary */
  color_secondary?: string | null
  /** Color Accent */
  color_accent?: string | null
  cropped_about_picture?: KImageModel | null
  cropped_banner_picture?: KImageModel | null
  cropped_profile_picture?: KImageModel | null
  /** About Enabled */
  about_enabled?: boolean | null
  /** About Activated */
  about_activated?: boolean | null
  /** Referral Enabled */
  referral_enabled?: boolean | null
  /** Referral Description */
  referral_description?: string | null
  /** Comment Enabled */
  comment_enabled?: boolean | null
  /** Alias Enabled */
  alias_enabled?: boolean | null
  /** Recommendation Enabled */
  recommendation_enabled?: boolean | null
  /** Mail Sender Name */
  mail_sender_name?: string | null
  /** Default Title Font */
  default_title_font?: string | null
  /** Default Text Font */
  default_text_font?: string | null
  /** Yearly Subscription Mail Enabled */
  yearly_subscription_mail_enabled?: boolean | null
  /** Id */
  id?: string | null
  /** Likes Count */
  likes_count?: number | null
  subdomain?: KSubdomainModel | null
  /** Total Posts */
  total_posts?: number | null
  /** Owners */
  owners: (KUserProfileOwnerModel | KUserProfileModel)[]
  /**
   * Is Owner
   * @default false
   */
  is_owner?: boolean | null
  /** Alias */
  alias?: string | null
}

/** PublicationReadOwnerModel */
export interface KPublicationReadOwnerModel {
  /** Title */
  title?: string | null
  /** Categories */
  categories?: KCategoryModel[] | null
  /** Description */
  description?: string | null
  /** Created At */
  created_at?: string | null
  /** Payment Enabled */
  payment_enabled?: boolean | null
  /** Payment Activated */
  payment_activated?: boolean | null
  /** Color Primary */
  color_primary?: string | null
  /** Color Secondary */
  color_secondary?: string | null
  /** Color Accent */
  color_accent?: string | null
  cropped_about_picture?: KImageModel | null
  cropped_banner_picture?: KImageModel | null
  cropped_profile_picture?: KImageModel | null
  /** About Enabled */
  about_enabled?: boolean | null
  /** About Activated */
  about_activated?: boolean | null
  /** Referral Enabled */
  referral_enabled?: boolean | null
  /** Referral Description */
  referral_description?: string | null
  /** Comment Enabled */
  comment_enabled?: boolean | null
  /** Alias Enabled */
  alias_enabled?: boolean | null
  /** Recommendation Enabled */
  recommendation_enabled?: boolean | null
  /** Mail Sender Name */
  mail_sender_name?: string | null
  /** Default Title Font */
  default_title_font?: string | null
  /** Default Text Font */
  default_text_font?: string | null
  /** Yearly Subscription Mail Enabled */
  yearly_subscription_mail_enabled?: boolean | null
  /** Id */
  id?: string | null
  /** Likes Count */
  likes_count?: number | null
  subdomain?: KSubdomainModel | null
  /** Total Posts */
  total_posts?: number | null
  /** Owners */
  owners: (KUserProfileOwnerModel | KUserProfileModel)[]
  /**
   * Is Owner
   * @default true
   */
  is_owner?: boolean | null
  /** Alias */
  alias?: string | null
  /** Total Drafts */
  total_drafts?: number | null
  /** Mail Sender Mail */
  mail_sender_mail?: string | null
  /** Total Subscribers */
  total_subscribers?: number | null
  /** Total Paid Subscribers */
  total_paid_subscribers?: number | null
  /** Editor Rate */
  editor_rate?: number | null
  /** Can Import */
  can_import?: boolean | null
}

/** PublicationRevenueStatsModel */
export interface KPublicationRevenueStatsModel {
  total_before: KRevenueStatsModel
  total_after: KRevenueStatsModel
  /** Buckets */
  buckets: KRevenueStatsModel[]
  total: KRevenueStatsModel
}

/** PublicationStatsModel */
export interface KPublicationStatsModel {
  email: KMailEventStats
  revenues: KPublicationRevenueStatsModel
  users: KPublicationSubscriberStatsModel
  likes: KPublicationLikesStatsModel
}

/** PublicationSubscriberStatsModel */
export interface KPublicationSubscriberStatsModel {
  total_before: KUserStatsModel
  /** Buckets */
  buckets: KUserStatsModel[]
  total_after: KUserStatsModel
  total: KUserStatsModel
}

/** PublicationWriteModel */
export interface KPublicationWriteModel {
  /** Title */
  title?: string | null
  /** Categories */
  categories?: KCategoryModel[] | null
  /** Description */
  description?: string | null
  /** Created At */
  created_at?: string | null
  /** Payment Enabled */
  payment_enabled?: boolean | null
  /** Payment Activated */
  payment_activated?: boolean | null
  /** Color Primary */
  color_primary?: string | null
  /** Color Secondary */
  color_secondary?: string | null
  /** Color Accent */
  color_accent?: string | null
  cropped_about_picture?: KImageModel | null
  cropped_banner_picture?: KImageModel | null
  cropped_profile_picture?: KImageModel | null
  /** About Enabled */
  about_enabled?: boolean | null
  /** About Activated */
  about_activated?: boolean | null
  /** Referral Enabled */
  referral_enabled?: boolean | null
  /** Referral Description */
  referral_description?: string | null
  /** Comment Enabled */
  comment_enabled?: boolean | null
  /** Alias Enabled */
  alias_enabled?: boolean | null
  /** Recommendation Enabled */
  recommendation_enabled?: boolean | null
  /** Mail Sender Name */
  mail_sender_name?: string | null
  /** Default Title Font */
  default_title_font?: string | null
  /** Default Text Font */
  default_text_font?: string | null
  /** Yearly Subscription Mail Enabled */
  yearly_subscription_mail_enabled?: boolean | null
  subdomain?: KSubdomainModel | null
}

/**
 * PutPostModel
 * For receiving PUT
 */
export interface KPutPostModel {
  /** Id */
  id?: string | null
  /** Title Draft */
  title_draft?: string | null
  /** Body Draft */
  body_draft?: string | null
  /** Description Draft */
  description_draft?: string | null
  cropped_image_draft?: KImageModel | null
  cropped_header_image?: KImageModel | null
  cropped_header_image_draft?: KImageModel | null
  /** Publication Scheduled At */
  publication_scheduled_at?: string | null
  /** Mail */
  mail?: boolean | null
  /** Is Draft */
  is_draft?: boolean | null
  /** Pinned */
  pinned?: boolean | null
  /** Visibility Level */
  visibility_level?: number | null
  /** Paywall Separator Position */
  paywall_separator_position?: number | null
}

/** ReferralCount */
export interface KReferralCount {
  /** All Subscriptions */
  all_subscriptions: number
  /** Free Subscriptions */
  free_subscriptions: number
  /** Monthly Offers */
  monthly_offers: number
  /** Yearly Offers */
  yearly_offers: number
  /** Premium Subscriptions */
  premium_subscriptions: number
  /** Paid Subscriptions */
  paid_subscriptions: number
}

/** RegisterForm */
export interface KRegisterForm {
  /**
   * Email
   * @format email
   */
  email: string
  /** Password */
  password?: string | null
  /**
   * Cgu Accepted
   * @default false
   */
  cgu_accepted?: boolean
}

/**
 * ReturnSubscriptionModel
 * To return the subscription information
 */
export interface KReturnSubscriptionModel {
  /** Id */
  id: string
  /** Subscription Level */
  subscription_level: number
  /** Active Till */
  active_till?: string | null
  /** Last Payment */
  last_payment?: string | null
  /** Publication Id */
  publication_id: string
  publication: KLightPublicationModel
  payment_status?: KPaymentStatus | null
  /**
   * Created At
   * @format date-time
   */
  created_at: string
  /** Deleted At */
  deleted_at?: string | null
}

/** RevenueStatsModel */
export interface KRevenueStatsModel {
  /**
   * Amount
   * @default 0
   */
  amount?: number
  /**
   * Currency
   * @default "EUR"
   */
  currency?: string
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string
}

/** StripeAccountResponse */
export interface KStripeAccountResponse {
  /** Stripe */
  stripe: any
  /** Status */
  status: string
}

/** SubdomainModel */
export interface KSubdomainModel {
  /** Name */
  name: string
}

/** SubscriberImportModel */
export interface KSubscriberImportModel {
  /** Email */
  email: string
  /** First Name */
  first_name?: string | null
  /** Last Name */
  last_name?: string | null
}

/** SubscriberModel */
export interface KSubscriberModel {
  /** First Name */
  first_name?: string | null
  /** Last Name */
  last_name?: string | null
  /** Biography */
  biography?: string | null
  cropped_picture?: KImageModel | null
  /** Cgu Accepted */
  cgu_accepted?: boolean | null
  /** Id */
  id: string
  /** Email */
  email: string
  /** Verified At */
  verified_at?: string | null
}

/** SubscriberStatsModel */
export interface KSubscriberStatsModel {
  referral_count: KSubscriptionReferralCountModel
  email: KMailEventStats
  revenues: KSubscriptionRevenueStatsModel
}

/** SubscribersFilterEnum */
export enum KSubscribersFilterEnum {
  Free = 'free',
  Paid = 'paid',
  Monthly = 'monthly',
  Yearly = 'yearly',
  Premium = 'premium',
  Active = 'active',
  Unsubscribed = 'unsubscribed',
}

/** SubscribersSortEnum */
export enum KSubscribersSortEnum {
  SubscriptionDate = 'subscription_date',
  Unsubscribed = 'unsubscribed',
  SubscriptionLevel = 'subscription_level',
  FirstName = 'first_name',
  LastName = 'last_name',
}

/** Subscription */
export interface KSubscription {
  /** Id */
  id: string
  /** Object */
  object: string
  /** Metadata */
  metadata?: object | null
  /** Cancel At Period End */
  cancel_at_period_end: boolean
  /** Canceled At */
  canceled_at?: number | null
  /** Created */
  created: number
  /** Current Period End */
  current_period_end: number
  /** Customer */
  customer: string
  /** Days Until Due */
  days_until_due?: number | null
  /** Default Payment Method */
  default_payment_method?: string | null
  /** Ended At */
  ended_at?: number | null
  items: KSubscriptionItems
  /** Start Date */
  start_date?: number | null
  /** Status */
  status: string
  /** Trial End */
  trial_end?: number | null
  /** Trial Start */
  trial_start?: number | null
}

/**
 * SubscriptionBillingModel
 * The model for the billing informations
 */
export interface KSubscriptionBillingModel {
  subscription: KSubscription
  payment_method?: KPaymentMethod | null
  /** Payment Setup Url */
  payment_setup_url?: string | null
}

/** SubscriptionItem */
export interface KSubscriptionItem {
  /** Id */
  id: string
  /** Object */
  object: string
  /** Metadata */
  metadata?: object | null
  price: KPrice
}

/** SubscriptionItems */
export interface KSubscriptionItems {
  /** Data */
  data: KSubscriptionItem[]
  /** Has More */
  has_more: boolean
}

/** SubscriptionReadModel */
export interface KSubscriptionReadModel {
  /** Id */
  id: string
  /** Subscription Level */
  subscription_level: number
  subscriber: KSubscriberModel
  payment_status?: KPaymentStatus | null
  /** Active Till */
  active_till?: string | null
  /** Created At */
  created_at?: string | null
  /** Deleted At */
  deleted_at?: string | null
  /** Mailing Enabled */
  mailing_enabled?: boolean | null
  referral_count?: KReferralCount | null
  /** Total Revenues */
  total_revenues?: number | null
  stats?: KSubscriberStatsModel | null
}

/** SubscriptionReferralCountModel */
export interface KSubscriptionReferralCountModel {
  /** All Subscriptions */
  all_subscriptions: number
  /** Free Subscriptions */
  free_subscriptions: number
  /** Monthly Offers */
  monthly_offers: number
  /** Yearly Offers */
  yearly_offers: number
  /** Premium Subscriptions */
  premium_subscriptions: number
  /** Paid Subscriptions */
  paid_subscriptions: number
}

/** SubscriptionRevenueStatsModel */
export interface KSubscriptionRevenueStatsModel {
  /** Total */
  total: number
  /**
   * Currency
   * @default "EUR"
   */
  currency?: string
}

/** SubscriptionStats */
export interface KSubscriptionStats {
  /**
   * Free
   * @default 0
   */
  free?: number
  /**
   * Monthly
   * @default 0
   */
  monthly?: number
  /**
   * Yearly
   * @default 0
   */
  yearly?: number
  /**
   * Premium
   * @default 0
   */
  premium?: number
  /**
   * Paid
   * @default 0
   */
  paid?: number
  /**
   * Free To Paid
   * @default 0
   */
  free_to_paid?: number
}

/**
 * SubscriptionWriteModel
 * The model for user to update the subscription settings
 */
export interface KSubscriptionWriteModel {
  /** Mailing Enabled */
  mailing_enabled?: boolean | null
}

/**
 * TimeBucketEnum
 * The common time bucket
 */
export enum KTimeBucketEnum {
  D = 'd',
  Value3D = '3d',
  W = 'w',
  M = 'm',
  Value3M = '3m',
  Value100Y = '100y',
}

/** TokenSchema */
export interface KTokenSchema {
  /** Access Token */
  access_token: string
  /** Token Type */
  token_type: string
}

/** TrafficStats */
export interface KTrafficStats {
  /**
   * Sources
   * @default []
   */
  sources?: KTrafficType[]
  /**
   * Total
   * @default 0
   */
  total?: number
}

/** TrafficType */
export interface KTrafficType {
  /** Name */
  name: string
  /** Label */
  label?: string | null
  /**
   * Count
   * @default 0
   */
  count?: number
  /**
   * Percentage
   * @default 0
   */
  percentage?: number
}

/** UltraLightPublicationModel */
export interface KUltraLightPublicationModel {
  /** Id */
  id?: string | null
  /** Title */
  title?: string | null
  /** Owners */
  owners: KUserProfileModel[]
  subdomain?: KSubdomainModel | null
}

/** UserIsSubscribedModel */
export interface KUserIsSubscribedModel {
  /** Subscribed */
  subscribed: boolean
  subscription?: KReturnSubscriptionModel | null
}

/** UserProfileGetMeModel */
export interface KUserProfileGetMeModel {
  /** First Name */
  first_name?: string | null
  /** Last Name */
  last_name?: string | null
  /** Biography */
  biography?: string | null
  cropped_picture?: KImageModel | null
  /** Cgu Accepted */
  cgu_accepted?: boolean | null
  /** Id */
  id: string
  /** Email */
  email: string
  /** Verified At */
  verified_at?: string | null
  /** Full Name */
  full_name?: string | null
  /** Has Password */
  has_password?: boolean | null
  role?: KUserRoleEnum | null
}

/** UserProfileModel */
export interface KUserProfileModel {
  /** First Name */
  first_name?: string | null
  /** Last Name */
  last_name?: string | null
  /** Biography */
  biography?: string | null
  cropped_picture?: KImageModel | null
  /** Cgu Accepted */
  cgu_accepted?: boolean | null
}

/** UserProfileOwnerModel */
export interface KUserProfileOwnerModel {
  /** First Name */
  first_name?: string | null
  /** Last Name */
  last_name?: string | null
  /** Biography */
  biography?: string | null
  cropped_picture?: KImageModel | null
  /** Cgu Accepted */
  cgu_accepted?: boolean | null
  /** Id */
  id: string
  /** Email */
  email: string
  /** Verified At */
  verified_at?: string | null
}

/** UserRoleEnum */
export enum KUserRoleEnum {
  Admin = 'admin',
  Customer = 'customer',
}

/** UserStatsModel */
export interface KUserStatsModel {
  /**
   * Timestamp
   * @format date-time
   */
  timestamp: string
  /** Free Users */
  free_users: number
  /** Monthly Users */
  monthly_users: number
  /** Yearly Users */
  yearly_users: number
  /** Premium Users */
  premium_users: number
  /**
   * Paying Users
   * @default 0
   */
  paying_users?: number
  /**
   * Total Paid
   * @default 0
   */
  total_paid?: number
}

/** ValidationError */
export interface KValidationError {
  /** Location */
  loc: (string | number)[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}

/**
 * ViewEventModel
 * This is the stats model for the endpoint
 */
export interface KViewEventModel {
  /** User Id */
  user_id?: number | null
  /** Post Id */
  post_id: string
  /** Ua */
  ua?: string | null
  /** Ip */
  ip?: string | null
}

/** ViewStats */
export interface KViewStats {
  /**
   * Count Users
   * @default 0
   */
  count_users?: number
  /**
   * Count Views
   * @default 0
   */
  count_views?: number
}

export type KRegisterAuthRegisterPostData = any

export type KRegisterAuthRegisterPostError = KHTTPValidationError

export type KOauthAuthOauthPostData = KTokenSchema

export type KOauthAuthOauthPostError = KHTTPValidationError

export type KLoginAuthLoginPostData = KUserProfileGetMeModel

export type KLoginAuthLoginPostError = KHTTPValidationError

export interface KGenerateLinkAuthGeneratelinkGetParams {
  /**
   * Email
   * @format email
   */
  email: string
  /** Pub Id */
  pub_id?: string | null
  /**
   * Redirect
   * @default "dashboard"
   */
  redirect?: string
  /**
   * Tag
   * @default "login:"
   */
  tag?: string
}

export type KGenerateLinkAuthGeneratelinkGetData = any

export type KGenerateLinkAuthGeneratelinkGetError = KHTTPValidationError

export type KChecklinkAuthChecklinkTokenGetData = KTokenSchema

export type KChecklinkAuthChecklinkTokenGetError = KHTTPValidationError

export type KLogoutAuthLogoutGetData = any

export interface KRefreshTokenAuthRefreshTokenGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
}

export type KRefreshTokenAuthRefreshTokenGetData = any

export type KRefreshTokenAuthRefreshTokenGetError = KHTTPValidationError

export type KGetCategoryCategoryCatSlugGetData = KCategoryModel

export type KGetCategoryCategoryCatSlugGetError = KHTTPValidationError

export interface KGetPublicationsCategoryCatSlugPublicationsGetParams {
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
  /** Cat Slug */
  catSlug: string
}

export type KGetPublicationsCategoryCatSlugPublicationsGetData = KPageListPublicationModel

export type KGetPublicationsCategoryCatSlugPublicationsGetError = KHTTPValidationError

export interface KGetPostsCategoryCatSlugPostsGetParams {
  /** Filter */
  filter?: KPostByCategoryFilterEnum | null
  /**
   * Sort
   * @default "last_updated"
   */
  sort?: KPostByCategorySortEnum | null
  /**
   * Sort Dir
   * @default "desc"
   */
  sort_dir?: string | null
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
  /** Cat Slug */
  catSlug: string
}

export type KGetPostsCategoryCatSlugPostsGetData = KPageListPostModel

export type KGetPostsCategoryCatSlugPostsGetError = KHTTPValidationError

export interface KGetCategoriesCategoryCatSlugCategoriesGetParams {
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
  /** Cat Slug */
  catSlug: string
}

export type KGetCategoriesCategoryCatSlugCategoriesGetData = KPageCategoryModel

export type KGetCategoriesCategoryCatSlugCategoriesGetError = KHTTPValidationError

export interface KGetAuthorsCategoryCatSlugAuthorsGetParams {
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
  /** Cat Slug */
  catSlug: string
}

export type KGetAuthorsCategoryCatSlugAuthorsGetData = KPageListPublicationModel

export type KGetAuthorsCategoryCatSlugAuthorsGetError = KHTTPValidationError

export interface KGetCategoriesCategoriesGetParams {
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
}

export type KGetCategoriesCategoriesGetData = KPageCategoryModel

export type KGetCategoriesCategoriesGetError = KHTTPValidationError

export interface KPutDomainForPublicationPublicationSubdomainDomainPutParams {
  /**
   * Pub Id
   * The publication id
   */
  pub_id: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Domain */
  domain: string
}

export type KPutDomainForPublicationPublicationSubdomainDomainPutData = any

export type KPutDomainForPublicationPublicationSubdomainDomainPutError = KHTTPValidationError

export interface KCreateDomainForPublicationPublicationSubdomainDomainPostParams {
  /**
   * Pub Id
   * The publication id
   */
  pub_id: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Domain */
  domain: string
}

export type KCreateDomainForPublicationPublicationSubdomainDomainPostData = any

export type KCreateDomainForPublicationPublicationSubdomainDomainPostError = KHTTPValidationError

export type KGetDomainAvailabilityPublicationSubdomainDomainGetData = KDomainAvailabilityResponse

export type KGetDomainAvailabilityPublicationSubdomainDomainGetError = KHTTPValidationError

export type KGetDomainPubIdPublicationSubdomainDomainIdGetData = any

export type KGetDomainPubIdPublicationSubdomainDomainIdGetError = KHTTPValidationError

export interface KGetUserPublicationsPublicationGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
}

export type KGetUserPublicationsPublicationGetData = KPagePublicationReadOwnerModel

export type KGetUserPublicationsPublicationGetError = KHTTPValidationError

export interface KPostPublicationPublicationPostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
}

export type KPostPublicationPublicationPostData = any

export type KPostPublicationPublicationPostError = KHTTPValidationError

export type KGetPublicationByIdPublicationPubIdGetData = KPublicationReadModel

export type KGetPublicationByIdPublicationPubIdGetError = KHTTPValidationError

export interface KDeletePublicationPublicationPubIdDeleteParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KDeletePublicationPublicationPubIdDeleteData = any

export type KDeletePublicationPublicationPubIdDeleteError = KHTTPValidationError

export interface KPutPublicationPublicationPubIdPutParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KPutPublicationPublicationPubIdPutData = any

export type KPutPublicationPublicationPubIdPutError = KHTTPValidationError

export interface KGetPublicationOwnByIdPublicationPubIdOwnGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Pub Id */
  pubId: string
}

export type KGetPublicationOwnByIdPublicationPubIdOwnGetData = KPublicationReadOwnerModel

export type KGetPublicationOwnByIdPublicationPubIdOwnGetError = KHTTPValidationError

/** Body */
export type KInviteUsersPublicationPubIdInvitePostPayload = KInvitationList

export interface KInviteUsersPublicationPubIdInvitePostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KInviteUsersPublicationPubIdInvitePostData = any

export type KInviteUsersPublicationPubIdInvitePostError = KHTTPValidationError

export type KInviteUserPublicationPubIdInviteEmbedPostData = any

export type KInviteUserPublicationPubIdInviteEmbedPostError = KHTTPValidationError

export type KGetPublicationOfferPublicationPubIdOfferGetData = KOfferGetModel

export type KGetPublicationOfferPublicationPubIdOfferGetError = KHTTPValidationError

export interface KSetPublicationOfferPublicationPubIdOfferPutParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KSetPublicationOfferPublicationPubIdOfferPutData = any

export type KSetPublicationOfferPublicationPubIdOfferPutError = KHTTPValidationError

export interface KGetRecommendationsPublicationPubIdRecommendationGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Pub Id */
  pubId: string
}

/** Response Get Recommendations Publication  Pub Id  Recommendation Get */
export type KGetRecommendationsPublicationPubIdRecommendationGetData = KLightPublicationModel[]

export type KGetRecommendationsPublicationPubIdRecommendationGetError = KHTTPValidationError

/** Pub Ids To Add */
export type KCreateRecommendationsPublicationPubIdRecommendationPostPayload = string[] | string

export interface KCreateRecommendationsPublicationPubIdRecommendationPostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KCreateRecommendationsPublicationPubIdRecommendationPostData = any

export type KCreateRecommendationsPublicationPubIdRecommendationPostError = KHTTPValidationError

/** Pub Ids To Remove */
export type KDeleteRecommendationsPublicationPubIdRecommendationDeletePayload = string[] | string

export interface KDeleteRecommendationsPublicationPubIdRecommendationDeleteParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KDeleteRecommendationsPublicationPubIdRecommendationDeleteData = any

export type KDeleteRecommendationsPublicationPubIdRecommendationDeleteError = KHTTPValidationError

/** Response Get Recommended By Publication  Pub Id  Recommended By Get */
export type KGetRecommendedByPublicationPubIdRecommendedByGetData = KLightPublicationModel[]

export type KGetRecommendedByPublicationPubIdRecommendedByGetError = KHTTPValidationError

export interface KGetStripeAccountInfoPublicationPubIdStripeAccountGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KGetStripeAccountInfoPublicationPubIdStripeAccountGetData = KStripeAccountResponse

export type KGetStripeAccountInfoPublicationPubIdStripeAccountGetError = KHTTPValidationError

export interface KDetachStripeAccountPublicationPubIdStripeAccountDeleteParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KDetachStripeAccountPublicationPubIdStripeAccountDeleteData = any

export type KDetachStripeAccountPublicationPubIdStripeAccountDeleteError = KHTTPValidationError

export interface KPostEnableStripePublicationPubIdEnableStripePostParams {
  /**
   * Recreate
   * Set to true will recreate the account
   * @default false
   */
  recreate?: boolean | null
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KPostEnableStripePublicationPubIdEnableStripePostData = any

export type KPostEnableStripePublicationPubIdEnableStripePostError = KHTTPValidationError

export interface KGetPublicationSubscribersPublicationPubIdSubscribersGetParams {
  /**
   * Filter
   * @default []
   */
  filter?: KSubscribersFilterEnum[]
  /**
   * Sort
   * @default "subscription_date"
   */
  sort?: KSubscribersSortEnum
  /**
   * Sort Dir
   * @default "desc"
   */
  sort_dir?: string
  /** Q */
  q?: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KGetPublicationSubscribersPublicationPubIdSubscribersGetData = KPageSubscriptionReadModel

export type KGetPublicationSubscribersPublicationPubIdSubscribersGetError = KHTTPValidationError

export interface KGetPublicationSubscribersExportPublicationPubIdSubscribersExportGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KGetPublicationSubscribersExportPublicationPubIdSubscribersExportGetData = any

export type KGetPublicationSubscribersExportPublicationPubIdSubscribersExportGetError = KHTTPValidationError

export interface KImportSubscribersRoutePublicationPubIdSubscribersImportPostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KImportSubscribersRoutePublicationPubIdSubscribersImportPostData = any

export type KImportSubscribersRoutePublicationPubIdSubscribersImportPostError = KHTTPValidationError

export interface KSubstackImportPublicationPubIdSubstackImportPostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KSubstackImportPublicationPubIdSubstackImportPostData = any

export type KSubstackImportPublicationPubIdSubstackImportPostError = KHTTPValidationError

/** Response List Templates Publication  Pub Id  Templates Get */
export type KListTemplatesPublicationPubIdTemplatesGetData = KMailTemplateModel[]

export type KListTemplatesPublicationPubIdTemplatesGetError = KHTTPValidationError

export interface KUpsertTemplatePublicationPubIdTemplatePostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KUpsertTemplatePublicationPubIdTemplatePostData = KMailTemplateModel

export type KUpsertTemplatePublicationPubIdTemplatePostError = KHTTPValidationError

export interface KUpsertTemplatePublicationPubIdTemplatePutParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KUpsertTemplatePublicationPubIdTemplatePutData = KMailTemplateModel

export type KUpsertTemplatePublicationPubIdTemplatePutError = KHTTPValidationError

export interface KDeleteTemplatePublicationPubIdTemplateDeleteParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KDeleteTemplatePublicationPubIdTemplateDeleteData = any

export type KDeleteTemplatePublicationPubIdTemplateDeleteError = KHTTPValidationError

export interface KPutDomainForPublicationNewsletterSubdomainDomainPutParams {
  /**
   * Pub Id
   * The publication id
   */
  pub_id: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Domain */
  domain: string
}

export type KPutDomainForPublicationNewsletterSubdomainDomainPutData = any

export type KPutDomainForPublicationNewsletterSubdomainDomainPutError = KHTTPValidationError

export interface KCreateDomainForPublicationNewsletterSubdomainDomainPostParams {
  /**
   * Pub Id
   * The publication id
   */
  pub_id: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Domain */
  domain: string
}

export type KCreateDomainForPublicationNewsletterSubdomainDomainPostData = any

export type KCreateDomainForPublicationNewsletterSubdomainDomainPostError = KHTTPValidationError

export type KGetDomainAvailabilityNewsletterSubdomainDomainGetData = KDomainAvailabilityResponse

export type KGetDomainAvailabilityNewsletterSubdomainDomainGetError = KHTTPValidationError

export type KGetDomainPubIdNewsletterSubdomainDomainIdGetData = any

export type KGetDomainPubIdNewsletterSubdomainDomainIdGetError = KHTTPValidationError

export interface KGetUserPublicationsNewsletterGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
}

export type KGetUserPublicationsNewsletterGetData = KPagePublicationReadOwnerModel

export type KGetUserPublicationsNewsletterGetError = KHTTPValidationError

export interface KPostPublicationNewsletterPostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
}

export type KPostPublicationNewsletterPostData = any

export type KPostPublicationNewsletterPostError = KHTTPValidationError

export type KGetPublicationByIdNewsletterPubIdGetData = KPublicationReadModel

export type KGetPublicationByIdNewsletterPubIdGetError = KHTTPValidationError

export interface KDeletePublicationNewsletterPubIdDeleteParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KDeletePublicationNewsletterPubIdDeleteData = any

export type KDeletePublicationNewsletterPubIdDeleteError = KHTTPValidationError

export interface KPutPublicationNewsletterPubIdPutParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KPutPublicationNewsletterPubIdPutData = any

export type KPutPublicationNewsletterPubIdPutError = KHTTPValidationError

export interface KGetPublicationOwnByIdNewsletterPubIdOwnGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Pub Id */
  pubId: string
}

export type KGetPublicationOwnByIdNewsletterPubIdOwnGetData = KPublicationReadOwnerModel

export type KGetPublicationOwnByIdNewsletterPubIdOwnGetError = KHTTPValidationError

/** Body */
export type KInviteUsersNewsletterPubIdInvitePostPayload = KInvitationList

export interface KInviteUsersNewsletterPubIdInvitePostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KInviteUsersNewsletterPubIdInvitePostData = any

export type KInviteUsersNewsletterPubIdInvitePostError = KHTTPValidationError

export type KInviteUserNewsletterPubIdInviteEmbedPostData = any

export type KInviteUserNewsletterPubIdInviteEmbedPostError = KHTTPValidationError

export type KGetPublicationOfferNewsletterPubIdOfferGetData = KOfferGetModel

export type KGetPublicationOfferNewsletterPubIdOfferGetError = KHTTPValidationError

export interface KSetPublicationOfferNewsletterPubIdOfferPutParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KSetPublicationOfferNewsletterPubIdOfferPutData = any

export type KSetPublicationOfferNewsletterPubIdOfferPutError = KHTTPValidationError

export interface KGetRecommendationsNewsletterPubIdRecommendationGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Pub Id */
  pubId: string
}

/** Response Get Recommendations Newsletter  Pub Id  Recommendation Get */
export type KGetRecommendationsNewsletterPubIdRecommendationGetData = KLightPublicationModel[]

export type KGetRecommendationsNewsletterPubIdRecommendationGetError = KHTTPValidationError

/** Pub Ids To Add */
export type KCreateRecommendationsNewsletterPubIdRecommendationPostPayload = string[] | string

export interface KCreateRecommendationsNewsletterPubIdRecommendationPostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KCreateRecommendationsNewsletterPubIdRecommendationPostData = any

export type KCreateRecommendationsNewsletterPubIdRecommendationPostError = KHTTPValidationError

/** Pub Ids To Remove */
export type KDeleteRecommendationsNewsletterPubIdRecommendationDeletePayload = string[] | string

export interface KDeleteRecommendationsNewsletterPubIdRecommendationDeleteParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KDeleteRecommendationsNewsletterPubIdRecommendationDeleteData = any

export type KDeleteRecommendationsNewsletterPubIdRecommendationDeleteError = KHTTPValidationError

/** Response Get Recommended By Newsletter  Pub Id  Recommended By Get */
export type KGetRecommendedByNewsletterPubIdRecommendedByGetData = KLightPublicationModel[]

export type KGetRecommendedByNewsletterPubIdRecommendedByGetError = KHTTPValidationError

export interface KGetStripeAccountInfoNewsletterPubIdStripeAccountGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KGetStripeAccountInfoNewsletterPubIdStripeAccountGetData = KStripeAccountResponse

export type KGetStripeAccountInfoNewsletterPubIdStripeAccountGetError = KHTTPValidationError

export interface KDetachStripeAccountNewsletterPubIdStripeAccountDeleteParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KDetachStripeAccountNewsletterPubIdStripeAccountDeleteData = any

export type KDetachStripeAccountNewsletterPubIdStripeAccountDeleteError = KHTTPValidationError

export interface KPostEnableStripeNewsletterPubIdEnableStripePostParams {
  /**
   * Recreate
   * Set to true will recreate the account
   * @default false
   */
  recreate?: boolean | null
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KPostEnableStripeNewsletterPubIdEnableStripePostData = any

export type KPostEnableStripeNewsletterPubIdEnableStripePostError = KHTTPValidationError

export interface KGetPublicationSubscribersNewsletterPubIdSubscribersGetParams {
  /**
   * Filter
   * @default []
   */
  filter?: KSubscribersFilterEnum[]
  /**
   * Sort
   * @default "subscription_date"
   */
  sort?: KSubscribersSortEnum
  /**
   * Sort Dir
   * @default "desc"
   */
  sort_dir?: string
  /** Q */
  q?: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KGetPublicationSubscribersNewsletterPubIdSubscribersGetData = KPageSubscriptionReadModel

export type KGetPublicationSubscribersNewsletterPubIdSubscribersGetError = KHTTPValidationError

export interface KGetPublicationSubscribersExportNewsletterPubIdSubscribersExportGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KGetPublicationSubscribersExportNewsletterPubIdSubscribersExportGetData = any

export type KGetPublicationSubscribersExportNewsletterPubIdSubscribersExportGetError = KHTTPValidationError

export interface KImportSubscribersRouteNewsletterPubIdSubscribersImportPostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KImportSubscribersRouteNewsletterPubIdSubscribersImportPostData = any

export type KImportSubscribersRouteNewsletterPubIdSubscribersImportPostError = KHTTPValidationError

export interface KSubstackImportNewsletterPubIdSubstackImportPostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KSubstackImportNewsletterPubIdSubstackImportPostData = any

export type KSubstackImportNewsletterPubIdSubstackImportPostError = KHTTPValidationError

/** Response List Templates Newsletter  Pub Id  Templates Get */
export type KListTemplatesNewsletterPubIdTemplatesGetData = KMailTemplateModel[]

export type KListTemplatesNewsletterPubIdTemplatesGetError = KHTTPValidationError

export interface KUpsertTemplateNewsletterPubIdTemplatePostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KUpsertTemplateNewsletterPubIdTemplatePostData = KMailTemplateModel

export type KUpsertTemplateNewsletterPubIdTemplatePostError = KHTTPValidationError

export interface KUpsertTemplateNewsletterPubIdTemplatePutParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KUpsertTemplateNewsletterPubIdTemplatePutData = KMailTemplateModel

export type KUpsertTemplateNewsletterPubIdTemplatePutError = KHTTPValidationError

export interface KDeleteTemplateNewsletterPubIdTemplateDeleteParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Pub Id
   * The publication id
   */
  pubId: string
}

export type KDeleteTemplateNewsletterPubIdTemplateDeleteData = any

export type KDeleteTemplateNewsletterPubIdTemplateDeleteError = KHTTPValidationError

export interface KConfirmDeletionUserConfirmDeletionGetParams {
  /**
   * Token
   * If empty, a mail will be sent with the token
   */
  token: string
}

export type KConfirmDeletionUserConfirmDeletionGetData = any

export type KConfirmDeletionUserConfirmDeletionGetError = KHTTPValidationError

export interface KDeleteMyselfUserDeleteParams {
  /**
   * Token
   * If empty, a mail will be sent with the token
   */
  token?: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
}

export type KDeleteMyselfUserDeleteData = any

export type KDeleteMyselfUserDeleteError = KHTTPValidationError

export interface KDeleteUserUserDeleteUserIdPostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** User Id */
  userId: string
}

export type KDeleteUserUserDeleteUserIdPostData = any

export type KDeleteUserUserDeleteUserIdPostError = KHTTPValidationError

export interface KAnonymizeUserUserAnonymizeUserIdPostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** User Id */
  userId: string
}

export type KAnonymizeUserUserAnonymizeUserIdPostData = any

export type KAnonymizeUserUserAnonymizeUserIdPostError = KHTTPValidationError

export interface KResetPasswordUserPasswordResetPostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
}

export type KResetPasswordUserPasswordResetPostData = any

export type KResetPasswordUserPasswordResetPostError = KHTTPValidationError

export interface KForgetPasswordUserPasswordForgetPostParams {
  /**
   * Email
   * @format email
   */
  email: string
}

export type KForgetPasswordUserPasswordForgetPostData = any

export type KForgetPasswordUserPasswordForgetPostError = KHTTPValidationError

export interface KGetMeUserProfileGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
}

export type KGetMeUserProfileGetData = KUserProfileGetMeModel

export type KGetMeUserProfileGetError = KHTTPValidationError

export interface KUpdateProfileUserProfilePutParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
}

export type KUpdateProfileUserProfilePutData = any

export type KUpdateProfileUserProfilePutError = KHTTPValidationError

export interface KVerifyEmailUserEmailVerifyGetParams {
  /** Token */
  token: string
}

export type KVerifyEmailUserEmailVerifyGetData = any

export type KVerifyEmailUserEmailVerifyGetError = KHTTPValidationError

export type KWebhookStripeWebhookAccountTypePostData = any

export type KWebhookStripeWebhookAccountTypePostError = KHTTPValidationError

export interface KPlatformWebhookStripeOauthAuthorizeGetParams {
  /** Code */
  code: string
  /** State */
  state: string
  /** Error */
  error?: string | null
  /** Error Description */
  error_description?: string | null
}

export type KPlatformWebhookStripeOauthAuthorizeGetData = any

export type KPlatformWebhookStripeOauthAuthorizeGetError = KHTTPValidationError

export interface KPublishPostsPostIdPublishPostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Post Id
   * The id of the post
   */
  postId: string
}

export type KPublishPostsPostIdPublishPostData = any

export type KPublishPostsPostIdPublishPostError = KHTTPValidationError

export interface KSendTestPostsPostIdSendTestPostParams {
  /** Recipient */
  recipient: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Post Id
   * The id of the post
   */
  postId: string
}

export type KSendTestPostsPostIdSendTestPostData = any

export type KSendTestPostsPostIdSendTestPostError = KHTTPValidationError

export interface KLikePostPostsPostIdLikePutParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Post Id */
  postId: string
}

export type KLikePostPostsPostIdLikePutData = any

export type KLikePostPostsPostIdLikePutError = KHTTPValidationError

export interface KUnlikePostPostsPostIdLikeDeleteParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Post Id */
  postId: string
}

export type KUnlikePostPostsPostIdLikeDeleteData = any

export type KUnlikePostPostsPostIdLikeDeleteError = KHTTPValidationError

export interface KGetPostsByPublicationPostsGetParams {
  /** Pub Id */
  pub_id: string
  /**
   * Filter
   * @default []
   */
  filter?: KPostsFilterEnum[]
  /**
   * Sort
   * @default "published_at"
   */
  sort?: KPostsSortEnum
  /**
   * Sort Dir
   * @default "desc"
   */
  sort_dir?: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
}

export type KGetPostsByPublicationPostsGetData = KPageListPostModel

export type KGetPostsByPublicationPostsGetError = KHTTPValidationError

export interface KPostPostsPostsPostParams {
  /**
   * Pub Id
   * The publication id
   */
  pub_id: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
}

export type KPostPostsPostsPostData = any

export type KPostPostsPostsPostError = KHTTPValidationError

export interface KGetPostsByPublicationPostsGet2Params {
  /** Pub Id */
  pub_id: string
  /**
   * Filter
   * @default []
   */
  filter?: KPostsFilterEnum[]
  /**
   * Sort
   * @default "published_at"
   */
  sort?: KPostsSortEnum
  /**
   * Sort Dir
   * @default "desc"
   */
  sort_dir?: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
}

export type KGetPostsByPublicationPostsGet2Data = KPageListPostModel

export type KGetPostsByPublicationPostsGet2Error = KHTTPValidationError

export interface KPostPostsPostsPost2Params {
  /**
   * Pub Id
   * The publication id
   */
  pub_id: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
}

export type KPostPostsPostsPost2Data = any

export type KPostPostsPostsPost2Error = KHTTPValidationError

export interface KGetPostsByPublicationOwnPostsOwnGetParams {
  /**
   * Pub Id
   * The publication id
   */
  pub_id: string
  /**
   * Filter
   * @default []
   */
  filter?: KPostsFilterEnum[]
  /**
   * Sort
   * @default "last_updated"
   */
  sort?: KPostsSortEnum
  /**
   * Sort Dir
   * @default "desc"
   */
  sort_dir?: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
}

export type KGetPostsByPublicationOwnPostsOwnGetData = KPageListPostOwnerModel

export type KGetPostsByPublicationOwnPostsOwnGetError = KHTTPValidationError

export interface KGetPostPostsPostIdGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Post Id
   * The id of the post
   */
  postId: string
}

/** Response Get Post Posts  Post Id  Get */
export type KGetPostPostsPostIdGetData = KOwnerPostModel | KPublicFullPostModel

export type KGetPostPostsPostIdGetError = KHTTPValidationError

export interface KPutPostsPostsPostIdPutParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Post Id
   * The id of the post
   */
  postId: string
}

export type KPutPostsPostsPostIdPutData = KOwnerPostModel

export type KPutPostsPostsPostIdPutError = KHTTPValidationError

export interface KDeletePostPostsPostIdDeleteParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Post Id
   * The id of the post
   */
  postId: string
}

export type KDeletePostPostsPostIdDeleteData = any

export type KDeletePostPostsPostIdDeleteError = KHTTPValidationError

export interface KGetCommentsCommentGetParams {
  /** Parent Id */
  parent_id: string
  /**
   * Filter
   * @default []
   */
  filter?: any[]
  /**
   * Sort
   * @default "created_at"
   */
  sort?: KCommentSortEnum
  /**
   * Sort Dir
   * @default "asc"
   */
  sort_dir?: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
}

export type KGetCommentsCommentGetData = KPageCommentModel

export type KGetCommentsCommentGetError = KHTTPValidationError

export interface KPostCommentCommentPostParams {
  /** Parent Id */
  parent_id: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
}

export type KPostCommentCommentPostData = any

export type KPostCommentCommentPostError = KHTTPValidationError

export interface KEditCommentCommentCommentIdPutParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Comment Id */
  commentId: string
}

export type KEditCommentCommentCommentIdPutData = any

export type KEditCommentCommentCommentIdPutError = KHTTPValidationError

export interface KDeleteCommentCommentCommentIdDeleteParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Comment Id */
  commentId: string
}

export type KDeleteCommentCommentCommentIdDeleteData = any

export type KDeleteCommentCommentCommentIdDeleteError = KHTTPValidationError

export interface KLikeCommentCommentComIdLikePutParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Com Id */
  comId: string
}

export type KLikeCommentCommentComIdLikePutData = any

export type KLikeCommentCommentComIdLikePutError = KHTTPValidationError

export interface KUnlikeCommentCommentComIdLikeDeleteParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Com Id */
  comId: string
}

export type KUnlikeCommentCommentComIdLikeDeleteData = any

export type KUnlikeCommentCommentComIdLikeDeleteError = KHTTPValidationError

export interface KGetUnsubscribeSubscriptionUnsubscribeGetParams {
  /** Token */
  token: string
}

export type KGetUnsubscribeSubscriptionUnsubscribeGetData = any

export type KGetUnsubscribeSubscriptionUnsubscribeGetError = KHTTPValidationError

export interface KPostUnsubscribeSubscriptionUnsubscribePostParams {
  /** Token */
  token: string
}

export type KPostUnsubscribeSubscriptionUnsubscribePostData = any

export type KPostUnsubscribeSubscriptionUnsubscribePostError = KHTTPValidationError

export interface KListUserSubscriptionsSubscriptionGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /**
   * Page
   * Page number
   * @min 1
   * @default 1
   */
  page?: number
  /**
   * Size
   * Page size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number
}

export type KListUserSubscriptionsSubscriptionGetData = KPageListSubscriptionModel

export type KListUserSubscriptionsSubscriptionGetError = KHTTPValidationError

export type KUserSubscriptionByIdSubscriptionSubIdGetData = KReturnSubscriptionModel

export type KUserSubscriptionByIdSubscriptionSubIdGetError = KHTTPValidationError

/**
 * Update
 * The update to the subscription model
 */
export type KUpdateSubscriptionSettingsSubscriptionSubIdPutPayload = KSubscriptionWriteModel

export type KUpdateSubscriptionSettingsSubscriptionSubIdPutData = any

export type KUpdateSubscriptionSettingsSubscriptionSubIdPutError = KHTTPValidationError

export interface KDeleteSubscriptionSubscriptionSubIdDeleteParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Sub Id */
  subId: string
}

export type KDeleteSubscriptionSubscriptionSubIdDeleteData = any

export type KDeleteSubscriptionSubscriptionSubIdDeleteError = KHTTPValidationError

export interface KUserSubscriptionByPubIdSubscriptionPublicationPubIdGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Pub Id */
  pubId: string
}

export type KUserSubscriptionByPubIdSubscriptionPublicationPubIdGetData = KUserIsSubscribedModel

export type KUserSubscriptionByPubIdSubscriptionPublicationPubIdGetError = KHTTPValidationError

export interface KGetSubscriptionBillingInformationSubscriptionSubIdBillingGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Sub Id */
  subId: string
}

export type KGetSubscriptionBillingInformationSubscriptionSubIdBillingGetData = KSubscriptionBillingModel

export type KGetSubscriptionBillingInformationSubscriptionSubIdBillingGetError = KHTTPValidationError

export interface KStripeSetupSessionSubscriptionSubIdStripeSetupGetParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
  /** Sub Id */
  subId: string
}

export type KStripeSetupSessionSubscriptionSubIdStripeSetupGetData = any

export type KStripeSetupSessionSubscriptionSubIdStripeSetupGetError = KHTTPValidationError

export interface KPostAttachPlanToSubscriptionSubscriptionAttachPlanPostParams {
  /**
   * Pub Id
   * The publication to subscribe to
   */
  pub_id?: string
  /**
   * News Id
   * The publication to subscribe to
   */
  news_id?: string
  /**
   * Price Id
   * The price_id of the offer for the publication
   * @default "price_free"
   */
  price_id?: string
  /**
   * Referral Token
   * Referral token if present
   */
  referral_token?: string | null
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
}

export type KPostAttachPlanToSubscriptionSubscriptionAttachPlanPostData = KAttachPlanResponse

export type KPostAttachPlanToSubscriptionSubscriptionAttachPlanPostError = KHTTPValidationError

export type KPostStatsStatsPostsPostIdGetData = KPostStatsModel

export type KPostStatsStatsPostsPostIdGetError = KHTTPValidationError

/**
 * Event
 * The event from the front-end
 */
export type KStatsPageViewStatsPostsPageViewPostPayload = KViewEventModel

export interface KStatsPageViewStatsPostsPageViewPostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
}

export type KStatsPageViewStatsPostsPageViewPostData = any

export type KStatsPageViewStatsPostsPageViewPostError = KHTTPValidationError

export type KGetPublicationAllTimeRevenueStatsPublicationsPubIdAllRevenuesGetData = KRevenueStatsModel

export type KGetPublicationAllTimeRevenueStatsPublicationsPubIdAllRevenuesGetError = KHTTPValidationError

export interface KGetPublicationRevenuesStatsPublicationsPubIdRevenuesGetParams {
  /**
   * Start
   * @format date-time
   */
  start: string
  /**
   * End
   * @format date-time
   */
  end: string
  /** The common time bucket */
  bucket: KTimeBucketEnum
  /** Pub Id */
  pubId: string
}

export type KGetPublicationRevenuesStatsPublicationsPubIdRevenuesGetData = KPublicationRevenueStatsModel

export type KGetPublicationRevenuesStatsPublicationsPubIdRevenuesGetError = KHTTPValidationError

export interface KGetSubscribersStatsStatsPublicationsPubIdSubscribersGetParams {
  /**
   * Start
   * @format date-time
   */
  start: string
  /**
   * End
   * @format date-time
   */
  end: string
  /** The common time bucket */
  bucket: KTimeBucketEnum
  /** Pub Id */
  pubId: string
}

export type KGetSubscribersStatsStatsPublicationsPubIdSubscribersGetData = KPublicationSubscriberStatsModel

export type KGetSubscribersStatsStatsPublicationsPubIdSubscribersGetError = KHTTPValidationError

export interface KGetSubscribersStatsStatsPublicationsPubIdUsersGetParams {
  /**
   * Start
   * @format date-time
   */
  start: string
  /**
   * End
   * @format date-time
   */
  end: string
  /** The common time bucket */
  bucket: KTimeBucketEnum
  /** Pub Id */
  pubId: string
}

export type KGetSubscribersStatsStatsPublicationsPubIdUsersGetData = KPublicationSubscriberStatsModel

export type KGetSubscribersStatsStatsPublicationsPubIdUsersGetError = KHTTPValidationError

export interface KGetPublicationStatsStatsPublicationsPubIdGetParams {
  /**
   * Start
   * @format date-time
   */
  start: string
  /**
   * End
   * @format date-time
   */
  end: string
  /** The common time bucket */
  bucket: KTimeBucketEnum
  /** Pub Id */
  pubId: string
}

export type KGetPublicationStatsStatsPublicationsPubIdGetData = KPublicationStatsModel

export type KGetPublicationStatsStatsPublicationsPubIdGetError = KHTTPValidationError

export type KGetPublicationAllTimeRevenueStatsNewslettersPubIdAllRevenuesGetData = KRevenueStatsModel

export type KGetPublicationAllTimeRevenueStatsNewslettersPubIdAllRevenuesGetError = KHTTPValidationError

export interface KGetPublicationRevenuesStatsNewslettersPubIdRevenuesGetParams {
  /**
   * Start
   * @format date-time
   */
  start: string
  /**
   * End
   * @format date-time
   */
  end: string
  /** The common time bucket */
  bucket: KTimeBucketEnum
  /** Pub Id */
  pubId: string
}

export type KGetPublicationRevenuesStatsNewslettersPubIdRevenuesGetData = KPublicationRevenueStatsModel

export type KGetPublicationRevenuesStatsNewslettersPubIdRevenuesGetError = KHTTPValidationError

export interface KGetSubscribersStatsStatsNewslettersPubIdSubscribersGetParams {
  /**
   * Start
   * @format date-time
   */
  start: string
  /**
   * End
   * @format date-time
   */
  end: string
  /** The common time bucket */
  bucket: KTimeBucketEnum
  /** Pub Id */
  pubId: string
}

export type KGetSubscribersStatsStatsNewslettersPubIdSubscribersGetData = KPublicationSubscriberStatsModel

export type KGetSubscribersStatsStatsNewslettersPubIdSubscribersGetError = KHTTPValidationError

export interface KGetSubscribersStatsStatsNewslettersPubIdUsersGetParams {
  /**
   * Start
   * @format date-time
   */
  start: string
  /**
   * End
   * @format date-time
   */
  end: string
  /** The common time bucket */
  bucket: KTimeBucketEnum
  /** Pub Id */
  pubId: string
}

export type KGetSubscribersStatsStatsNewslettersPubIdUsersGetData = KPublicationSubscriberStatsModel

export type KGetSubscribersStatsStatsNewslettersPubIdUsersGetError = KHTTPValidationError

export interface KGetPublicationStatsStatsNewslettersPubIdGetParams {
  /**
   * Start
   * @format date-time
   */
  start: string
  /**
   * End
   * @format date-time
   */
  end: string
  /** The common time bucket */
  bucket: KTimeBucketEnum
  /** Pub Id */
  pubId: string
}

export type KGetPublicationStatsStatsNewslettersPubIdGetData = KPublicationStatsModel

export type KGetPublicationStatsStatsNewslettersPubIdGetError = KHTTPValidationError

export type KGetUploadedFileFilesFilenameGetData = any

export type KGetUploadedFileFilesFilenameGetError = KHTTPValidationError

export interface KFetchSaveExternalUrlFilesFetchUrlPostParams {
  /** Url */
  url: string
}

export type KFetchSaveExternalUrlFilesFetchUrlPostData = KFileUploadResponse

export type KFetchSaveExternalUrlFilesFetchUrlPostError = KHTTPValidationError

export type KCreateUploadFileFilesUploadFilePostData = KFileUploadResponse

export type KCreateUploadFileFilesUploadFilePostError = KHTTPValidationError

export type KHandleWebhookMailWebhookPostData = any

export interface KCreateAliasesActionCreateAliasesPostParams {
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
}

export type KCreateAliasesActionCreateAliasesPostData = any

export type KCreateAliasesActionCreateAliasesPostError = KHTTPValidationError

/** Emails */
export type KSendNewsletterToEmailsActionSendNewsletterToEmailsPostPayload = string[]

export interface KSendNewsletterToEmailsActionSendNewsletterToEmailsPostParams {
  /** Post Id */
  post_id: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
}

export type KSendNewsletterToEmailsActionSendNewsletterToEmailsPostData = any

export type KSendNewsletterToEmailsActionSendNewsletterToEmailsPostError = KHTTPValidationError

export interface KSendNewsletterActionSendNewsletterPostParams {
  /** Post Id */
  post_id: string
  /**
   * Impersonate User Id
   * Use when login as admin, the user returned will be this user
   */
  impersonate_user_id?: string | null
}

export type KSendNewsletterActionSendNewsletterPostData = any

export type KSendNewsletterActionSendNewsletterPostError = KHTTPValidationError

export type KHomeGetData = any

export type KInfoInfoGetData = any
