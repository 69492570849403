






















































import { Product, Subscription } from '@kessel/core'
import { PropType, computed, defineComponent, onMounted, ref, toRefs, watch } from '@nuxtjs/composition-api'
import { useTrackEvent } from '~/composables/useTrackEvent'

export default defineComponent({
  props: {
    defaultPriceId: {
      type: String,
      required: false,
      default: null,
    },
    subscription: {
      type: Object as PropType<Subscription>,
      required: false,
      default: null,
    },
    products: {
      type: Array as PropType<Product[]>,
      required: false,
      default: null,
    },
  },
  emits: ['onChangePlanId'],
  setup: (props, { emit }) => {
    const { products, subscription, defaultPriceId } = toRefs(props)
    const { trackEvent } = useTrackEvent()

    const items = computed(() =>
      [
        ...(products.value && (products.value.map(({ name, description, prices }) =>
          prices.map((price) => ({ ...price, name, description })))
          .flat(1))),
      ].filter(({ metadata }) => subscription.value?.subscription_level !== metadata.kessel_subscription_level)
    )

    const selectedItem = items.value
      .find(({ metadata }) => subscription.value?.subscription_level === metadata.kessel_subscription_level) ||
      items.value.find(({ id }) => id === defaultPriceId.value) || items.value.find(({ id }) => id)
    const plan = ref<string>(selectedItem?.id || '')

    watch(plan, () => {
      trackEvent('select_item', {
        items: [{
          item_id: plan.value,
          item_name: plan.value,
        },
        ],
      })
      emit('onChangePlanId', plan.value)
    }, { immediate: !!plan.value })

    onMounted(() => {
      trackEvent('view_item_list', {
        items: items.value.map(({ id }) => ({
          item_id: id,
          item_name: id,
        })),
      })
    })

    return {
      plan,
      items,
    }
  },
})
