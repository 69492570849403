export enum CardBrand {
  Amex = 'amex',
  Mastercard = 'mastercard',
  Visa = 'visa',
  Unknown = 'unknown'
}

export enum StripeStatus {
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  Trialing = 'trialing',
  Active = 'active',
  PastDue = 'past_due',
  Canceled = 'canceled',
  Unpaid = 'unpaid'
}

export interface StripeBilling {
  subscription: {
    id: string
    object: string
    metadata: Record<string, unknown>
    cancel_at_period_end: boolean
    canceled_at: number
    created: number
    current_period_end: number
    customer: string
    days_until_due: number
    default_payment_method: string
    ended_at: number
    items: {
      data: {
        id: string
        object: string
        metadata: Record<string, unknown>
        price: {
          id: string
          object: string
          metadata: Record<string, unknown>
          active: boolean
          currency: string
          nickname: string
          product: string
          unit_amount: number
        }
      }[]
      has_more: boolean
    }
    start_date: number
    status: StripeStatus
    trial_end: number
    trial_start: number
  }
  payment_method: {
    id: string
    object: string
    metadata: Record<string, unknown>
    billing_details: {
      address: {
        city: string
        country: string
        line1: string
        line2: string
        postal_code: string
        state: string
      }
      email: string
      name: string
      phone: string
    }
    card: {
      brand: CardBrand
      exp_month: number
      exp_year: number
      last4: string
    }
    customer: string
  }
  payment_setup_url: string
}
