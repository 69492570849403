


























import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    progress: { type: Number, default: 0 },
  },
})
