



























































import { defineComponent, toRefs } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { useOnboarding } from '~/stores/onboarding'

export default defineComponent({
  props: {
    navigation: {
      type: Boolean,
      default: null,
    },
    navNext: {
      type: Boolean,
      default: true,
    },
    skipStep: {
      type: String,
      default: null,
    },
  },
  setup(props, { root: { $buefy, $i18n, $router, localePath } }) {
    const { navNext, skipStep } = toRefs(props)
    const { current, progress, loading, max, path } = storeToRefs(useOnboarding())
    const { next, setSkipped } = useOnboarding()

    const onNext = async () => {
      if (navNext) {
        try {
          const funcVoid = await next()
          if (funcVoid) {
            funcVoid()
          } else {
            await $router.push(path.value ? path.value[current.value] : localePath({ name: `register-${current.value + 1}` }))
          }
        } catch (e) {
          $buefy.toast.open({
            message: $i18n.t((e as Error).message) as string,
            type: 'is-danger',
          })
        }
      }
    }

    const skipped = () => {
      setSkipped(true)
      $router.push(skipStep.value)
    }

    return {
      current,
      max,
      progress,
      loading,
      onNext,
      skipped,
    }
  },
})
