// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/druk-wide/DrukWide-Medium.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/druk-wide/DrukWide-MediumItalic.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Druk-Wide-Medium\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\");font-weight:500;font-style:normal;font-display:swap}@font-face{font-family:\"Druk-Wide-Medium-Italic\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\");font-weight:500;font-style:italic;font-display:swap}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
