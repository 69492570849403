const middleware = {}

middleware['aboutRight'] = require('../middleware/aboutRight.ts')
middleware['aboutRight'] = middleware['aboutRight'].default || middleware['aboutRight']

middleware['categoryToSlug'] = require('../middleware/categoryToSlug.ts')
middleware['categoryToSlug'] = middleware['categoryToSlug'].default || middleware['categoryToSlug']

middleware['customMailTemplates'] = require('../middleware/customMailTemplates.ts')
middleware['customMailTemplates'] = middleware['customMailTemplates'].default || middleware['customMailTemplates']

middleware['customMailTemplatesV2'] = require('../middleware/customMailTemplatesV2.ts')
middleware['customMailTemplatesV2'] = middleware['customMailTemplatesV2'].default || middleware['customMailTemplatesV2']

middleware['dashboard'] = require('../middleware/dashboard.ts')
middleware['dashboard'] = middleware['dashboard'].default || middleware['dashboard']

middleware['featureFlip'] = require('../middleware/featureFlip.ts')
middleware['featureFlip'] = middleware['featureFlip'].default || middleware['featureFlip']

middleware['landingModal'] = require('../middleware/landingModal.ts')
middleware['landingModal'] = middleware['landingModal'].default || middleware['landingModal']

middleware['onboarding'] = require('../middleware/onboarding.ts')
middleware['onboarding'] = middleware['onboarding'].default || middleware['onboarding']

middleware['pagination'] = require('../middleware/pagination.ts')
middleware['pagination'] = middleware['pagination'].default || middleware['pagination']

middleware['postToID'] = require('../middleware/postToID.ts')
middleware['postToID'] = middleware['postToID'].default || middleware['postToID']

middleware['postToSlug'] = require('../middleware/postToSlug.ts')
middleware['postToSlug'] = middleware['postToSlug'].default || middleware['postToSlug']

middleware['preventSubdomain'] = require('../middleware/preventSubdomain.ts')
middleware['preventSubdomain'] = middleware['preventSubdomain'].default || middleware['preventSubdomain']

middleware['preventToken'] = require('../middleware/preventToken.ts')
middleware['preventToken'] = middleware['preventToken'].default || middleware['preventToken']

middleware['publication'] = require('../middleware/publication.ts')
middleware['publication'] = middleware['publication'].default || middleware['publication']

middleware['publicationRights'] = require('../middleware/publicationRights.ts')
middleware['publicationRights'] = middleware['publicationRights'].default || middleware['publicationRights']

middleware['recommendations'] = require('../middleware/recommendations.ts')
middleware['recommendations'] = middleware['recommendations'].default || middleware['recommendations']

middleware['referral'] = require('../middleware/referral.ts')
middleware['referral'] = middleware['referral'].default || middleware['referral']

middleware['requiresAuth'] = require('../middleware/requiresAuth.ts')
middleware['requiresAuth'] = middleware['requiresAuth'].default || middleware['requiresAuth']

middleware['requiresNoAuth'] = require('../middleware/requiresNoAuth.ts')
middleware['requiresNoAuth'] = middleware['requiresNoAuth'].default || middleware['requiresNoAuth']

middleware['rights'] = require('../middleware/rights.ts')
middleware['rights'] = middleware['rights'].default || middleware['rights']

middleware['searchToSlug'] = require('../middleware/searchToSlug.ts')
middleware['searchToSlug'] = middleware['searchToSlug'].default || middleware['searchToSlug']

middleware['stripe'] = require('../middleware/stripe.ts')
middleware['stripe'] = middleware['stripe'].default || middleware['stripe']

middleware['syncLanding'] = require('../middleware/syncLanding.ts')
middleware['syncLanding'] = middleware['syncLanding'].default || middleware['syncLanding']

middleware['syncOffer'] = require('../middleware/syncOffer.ts')
middleware['syncOffer'] = middleware['syncOffer'].default || middleware['syncOffer']

middleware['validateSubDomain'] = require('../middleware/validateSubDomain.ts')
middleware['validateSubDomain'] = middleware['validateSubDomain'].default || middleware['validateSubDomain']

export default middleware
