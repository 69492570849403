





















import { ImageCropped } from '@kessel/core'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    value: { type: Object as PropType<ImageCropped>, default: null },
    label: { type: String, required: false, default: null },
    destination: { type: String, default: null },
    color: { type: String, default: 'white' },
    header: { type: Boolean, default: true },
    border: { type: Boolean, default: false },
  },
})
