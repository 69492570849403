












import { Owner } from '@kessel/core'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    picture: {
      type: Object as PropType<Owner['cropped_picture']>,
      required: false,
      default: null,
    },
  },
})
