

























































import { Publication } from '@kessel/core'
import { computed, defineComponent, PropType, toRefs, useMeta, reactive, useRoute, useContext, ref, useRouter } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { AxiosError } from 'axios'
import { useMetaInfo } from '~/stores/metaInfo'
import { usePublication } from '~/stores/publication'
import { useOffer } from '~/stores/offer_v2'
import { useUser } from '~/stores/user'
import { useAuth } from '~/stores/auth'
import { useSubscription } from '~/stores/subscription'

export default defineComponent({
  middleware: ['rights'],
  props: {
    publication: {
      type: Object as PropType<Publication>,
      required: true,
    },
  },
  setup(props) {
    const { publication } = toRefs(props)
    const { getPublicationMeta } = useMetaInfo()
    const { getAuthorPublication } = usePublication()
    const { products } = storeToRefs(useOffer())
    const { attachPlan } = useSubscription()
    const { user, isLogged } = storeToRefs(useUser())
    const { signUp, generateMagicLink } = useAuth()
    const { i18n, localePath } = useContext()
    useMeta(getPublicationMeta(publication.value))

    const errorRegister = ref()
    const subscribeLoader = ref(false)

    const route = useRoute()
    const router = useRouter()

    const author = computed(() => getAuthorPublication(publication.value))
    const freeStarterProduct = products.value.find((product: any) => product.metadata.kessel_type === 'starter' && product.active)
    const isOnlyOneFreeStarter = computed(() => products.value.length === 1 && freeStarterProduct)

    const form = reactive<{ email: string, agree: boolean }>({
      email: (user.value?.email || ''),
      agree: false,
    })

    const getRedirectPatch = () => {
      return localePath({
        name: 'login',
        query: {
          email: form.email,
          publicationId: publication.value.id,
          redirect: route.value.fullPath,
        },
      })
    }

    const handleError = async (error: AxiosError): Promise<string> => {
      switch (error.response?.status) {
        case 409:
          await generateMagicLink(form.email as string, route.value.fullPath, publication.value.id)
          return i18n.t('EMAIL_ALREADY_REGISTERED_MAGIC_LINK', {
            link: getRedirectPatch(),
          }) as string

        default :
          return i18n.t('SOMETHING_WENT_WRONG') as string
      }
    }

    const submit = async () => {
      try {
        subscribeLoader.value = true
        errorRegister.value = ''
        if (!form.agree) {
          errorRegister.value = i18n.t('YOU_MUST_AGREE_TO_TERMS') as string
          return
        }
        if (!isLogged.value) {
          try {
            await signUp(String(form.email), undefined, form.agree)
          } catch (error) {
            errorRegister.value = i18n.t(await handleError(error as AxiosError))
            subscribeLoader.value = false
            return
          }
        }

        const { session } = await attachPlan(publication.value.id)
        subscribeLoader.value = false

        if (session?.url) {
          window.location.href = session.url
          return
        }

        await router.replace({ path: route.value.path, query: { ...route.value.query, landing: undefined, subscribe: undefined, r: 'success' } })
        // FIXME find a way to reload without using location ( $nuxt.refresh() )
        location.reload()
      } catch (error: any) {
        errorRegister.value = error?.message
        // TODO error message to toast
        subscribeLoader.value = false
      }
    }

    const isFormValid = computed(() => !!form.email)
    return {
      author,
      isOnlyOneFreeStarter,
      form,
      isLogged,
      redirectPath: computed(() => getRedirectPatch()),
      isFormValid,
      submit,
      subscribeLoader,
      errorRegister,

    }
  },
  head: {},
  methods: {
    onDirectSignupChange({ agree, email }:{ agree: boolean, email: string }) {
      this.form.agree = agree
      if (email) {
        this.form.email = email
      }
    },
  },
})
