import { Extension } from '@tiptap/core'
import { HardBreak } from '@tiptap/extension-hard-break'
import { Fragment, Node, DOMParser as ProseMirrorDOMParser, Schema, Slice } from 'prosemirror-model'
import { Plugin, PluginKey } from 'prosemirror-state'
import { Converter } from 'showdown'
// @ts-expect-error DOMParser for ssr
import DOMParser from 'universal-dom-parser'
interface PasteOptions {
  node: string
  notAfter: string[]
}

export const Paste = Extension.create<PasteOptions>({
  name: 'paste',
  addProseMirrorPlugins() {
    const plugin = new PluginKey(this.name)

    const transformPasted = (slice: Slice) => {
      const recurse = (item: Node | Fragment) => {
        if (item instanceof Fragment) {
          const nodes = (item as any).content.map(recurse)
          return Fragment.from(nodes)
        } else if (item instanceof Node) {
          const fragment = recurse(item.content)
          let node

          if (item.type.isBlock && item.content.size === 1 && item.content.firstChild?.type.name === HardBreak.name) {
            node = item.copy()
          } else {
            node = item.copy(fragment)
          }

          return node.content
        }
      }

      const fragment = recurse(slice.content) || Fragment.empty

      return new Slice(fragment, slice.openStart, slice.openEnd)
    }

    return [
      new Plugin({
        key: plugin,
        props: {
          transformPasted,
          handlePaste: (view, event) => {
            if (view.props.editable && !view.props.editable(view.state)) {
              return false
            }
            if (!event.clipboardData) {
              return false
            }
            const parser = new DOMParser()
            const text = event?.clipboardData?.getData('text/plain')
            const html = event?.clipboardData?.getData('text/html')

            const converter = new Converter({
              headerLevelStart: 2, // start at h2 because h1 is for page title
              simpleLineBreaks: false,
            })

            const IS_MARKDOWN = /(^\|(?<tableSeparator>[\s\-|]+)\|\n)/gm

            const markdown = IS_MARKDOWN.test(text)
            const contentString = html || (markdown && converter.makeHtml(text)) || text
            const { body } = parser.parseFromString(contentString, 'text/html')

            const pasteSchema = new Schema({
              ...view.state.schema.spec,
              topNode: 'paragraph',
            })

            const state = ProseMirrorDOMParser.fromSchema(pasteSchema).parseSlice(body)
            try {
              const parsed = state.toJSON()

              this.editor.commands.deleteSelection()
              this.editor.commands.insertContentAt(view.state.selection, parsed.content, {
                updateSelection: true,
                parseOptions: {
                  preserveWhitespace: 'full',
                },
              })
            } catch (error) {
              console.error(error)
            }

            return true
          },
        },
      }),
    ]
  },
})
