import { NuxtI18nInstance } from '@nuxtjs/i18n'
import {
  CharacterCount as TipTapCharacterCount,
  CharacterCountOptions as TipTapCharacterCountOptions,
} from '@tiptap/extension-character-count'
import { NodeWithPos } from '@tiptap/vue-2'
import { Node as ProsemirrorNode } from 'prosemirror-model'
import { Plugin, PluginKey, Selection } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

export interface CharacterCountValues {
  words: number
  characters: number
}

export interface CharacterCountOptions extends TipTapCharacterCountOptions {
  i18n: NuxtI18nInstance
}

export const CharacterCount = TipTapCharacterCount.extend<Partial<CharacterCountOptions>>({
  name: 'characterCount',

  addProseMirrorPlugins() {
    const plugin = new PluginKey(this.name)

    const renderCountElement = ({ words, characters }: CharacterCountValues) => {
      const widget: HTMLElement = document.createElement('div')

      widget.className = 'character-count'
      widget.innerHTML = `${this.options.i18n?.t('EDITOR_UI_CHARACTERS', { characters })} ${this.options.i18n?.t(
        'EDITOR_UI_WORDS',
        { words }
      )}`

      return widget
    }

    const findBlockNodes = (doc: ProsemirrorNode, selection: Pick<Selection, 'from' | 'to'>) => {
      const results: NodeWithPos[] = []

      doc.nodesBetween(selection.from, selection.to, (node, pos) => {
        results.push({ node, pos })
      })

      return results.filter((child) => child.node.isTextblock)
    }

    const decorateNodes = (nodes: NodeWithPos[], countValues: CharacterCountValues) =>
      nodes.map((item) => [Decoration.widget(item.pos, renderCountElement(countValues))]).flat()

    return [
      new Plugin({
        key: plugin,
        filterTransaction: (transaction, state) => {
          const limit = this.options.limit

          // Nothing has changed or no limit is defined. Ignore it.
          if (!transaction.docChanged || limit === 0 || limit === null || limit === undefined) {
            return true
          }

          const oldSize = this.storage.characters({ node: state.doc as any })
          const newSize = this.storage.characters({ node: transaction.doc as any })

          // Everything is in the limit. Good.
          if (newSize <= limit) {
            return true
          }

          // The limit has already been exceeded but will be reduced.
          if (oldSize > limit && newSize > limit && newSize <= oldSize) {
            return true
          }

          // The limit has already been exceeded and will be increased further.
          if (oldSize > limit && newSize > limit && newSize > oldSize) {
            return false
          }

          const isPaste = transaction.getMeta('paste')

          // Block all exceeding transactions that were not pasted.
          if (!isPaste) {
            return false
          }

          // For pasted content, we try to remove the exceeding content.
          const pos = transaction.selection.$head.pos
          const over = newSize - limit
          const from = pos - over
          const to = pos

          // It’s probably a bad idea to mutate transactions within `filterTransaction`
          // but for now this is working fine.
          transaction.deleteRange(from, to)

          // In some situations, the limit will continue to be exceeded after trimming.
          // This happens e.g. when truncating within a complex node (e.g. table)
          // and ProseMirror has to close this node again.
          // If this is the case, we prevent the transaction completely.
          const updatedSize = this.storage.characters({ node: transaction.doc as any })

          if (updatedSize > limit) {
            return false
          }

          return true
        },
        state: {
          init: (_, state) => {
            return DecorationSet.create(state.doc, [])
          },
          apply: (tr) => {
            const selection = {
              from: tr.doc.content.size - (tr.doc.lastChild?.nodeSize || 0),
              to: tr.doc.content.size,
            }

            const matching = findBlockNodes(tr.doc, selection).filter((item) => item.node.isBlock)

            return DecorationSet.create(
              tr.doc,
              decorateNodes(matching, {
                words: this.editor.storage.characterCount.words(),
                characters: this.editor.storage.characterCount.characters(),
              })
            )
          },
        },
      }),
    ]
  },
})
