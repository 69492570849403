import { defineStore } from 'pinia'
import { MessageProps } from '~/components/KMessage.vue'

interface UploadStoreState {
  loading: boolean
}

type CheckCallBack<T> = (type: string, size: number, max: number, error: MessageProps | null) => T

export const useUpload = defineStore('upload', {
  state: (): UploadStoreState => ({
    loading: false,
  }),
  getters: {
    getFileSizeMo: () => (file: File) => {
      return file.size / (1024 * 1024) // convert to Mo
    },
    getFileTypePattern: () => (file: File) => {
      return file.type.split('/')[1].toUpperCase()
    },
  },
  actions: {
    async doFetch(url: string) {
      try {
        const {
          data: { file: resultFile },
        } = await this.$nuxt.$axios.post(`/v1/files/fetch_url?url=${url}`)
        return resultFile?.url
      } catch (e) {
        console.error(e)
        return null
      }
    },
    async doUpload(file: File) {
      const formData = new FormData()
      formData.append('file', file, file.name)

      try {
        const {
          data: { file: resultFile },
        } = await this.$nuxt.$axios.post('/v1/files/upload_file', formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })

        return resultFile?.url
      } catch {
        return null
      }
    },
    async checkFileSize(file: File, max: number, typesToCheck: string[], callback: CheckCallBack<Promise<void> | void>, errCallback?: CheckCallBack<void>) {
      const fileType = file.type
      const fileTypePattern = this.getFileTypePattern(file)
      const size = this.getFileSizeMo(file)
      const isTooBig = typesToCheck.includes(fileType) && size > max
      if (isTooBig) {
        const error: MessageProps = {
          message: String(this.$nuxt.i18n.t('FILE_TOO_BIG_WARNING', { type: fileTypePattern, max })),
          type: 'error',
          closable: false,
          toast: true,
        }
        errCallback && errCallback(fileTypePattern, size, max, error)
        return
      }
      await callback(fileTypePattern, size, max, null)
    },
  },
})
