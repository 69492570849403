
































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { ButtonOptions } from '~/utils/tiptap'

export default defineComponent({
  props: {
    value: { type: Boolean, default: false },
    options: {
      type: Object as PropType<ButtonOptions>,
      required: true,
    },
  },
  setup(_, { emit }) {
    const submit = (val: ButtonOptions) => {
      emit('close')
      emit('submit', val)
    }

    const close = () => {
      emit('close')
    }

    return {
      canCancel: true,
      close,
      submit,
    }
  },
})
