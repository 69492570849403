import type { Context } from '@nuxt/types'
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { useStripe } from '~/stores/stripe'

export default defineNuxtMiddleware(async (ctx: Context) => {
  try {
    const { syncStripe } = useStripe(ctx.$pinia)
    await syncStripe()
  } catch (e) {
    console.error({ statusCode: 404, message: JSON.stringify(e), path: '/middleware/stripe' })
  }
})
