import { Post, Publication, PublicationList } from '@kessel/core'
import { Context } from '@nuxt/types'
import get from 'lodash/get'
import { defineStore } from 'pinia'
import { usePost } from '~/stores/post_v2'

export const useDomain = defineStore('domain', {
  actions: {
    getLocation(context?: Context): Location {
      return { ...context?.req, ...context?.req?.headers, ...global.window?.location } as Location
    },
    parseHostname(context?: Context) {
      const location: Location = this.getLocation(context)

      const host = location?.host || 'www.' + process.env.DOMAIN_NAME?.split('://')[1] || ''
      const protocol = 'https:'

      const domains = host.split('.')
      const subdomain = (domains && domains.length > 1 && domains.shift()?.toString()) || 'www'

      return {
        host: domains.join('.'),
        subdomain,
        protocol,
      }
    },
    getAppPath({ protocol, host, path }: { protocol: string; host: string; path?: string }) {
      return `${protocol ?? 'https:'}//${host}` + (path || '')
    },
    getSubdomainPostsPath({
      protocol,
      subdomain,
      postId,
      postSlug,
      host,
    }: {
      protocol: string
      subdomain: string
      host?: string
      postId?: string
      postSlug?: string
    }) {
      return `${protocol ?? 'https:'}//${subdomain ? subdomain + '.' : ''}${host}` + this.getPostsPath({ postId, postSlug })
    },
    getPostsPath({ postId, postSlug }: { postId?: string; postSlug?: string }) {
      return postId ? `/posts/${postId}${postSlug ? `/${postSlug}` : ''}` : ''
    },
    goToHome(context?: { protocol: string; host?: string }) {
      if (!context) {
        const { protocol, host } = this.parseHostname()
        return `${protocol}//${host}`
      }
      return `${context.protocol}//${context?.host}`
    },
    pathToPublication(publication: PublicationList | Publication | null, subdomain?: string) {
      return this.getSubdomainPostsPath({
        ...this.parseHostname(),
        subdomain: publication ? get(publication, 'subdomain.name', 'www') : subdomain,
      })
    },
    pathToPost(post: Post) {
      const { getPostSlug } = usePost()
      return this.getSubdomainPostsPath({
        ...this.parseHostname(),
        subdomain: post.subdomain,
        postId: post.id,
        postSlug: getPostSlug(post),
      })
    },
    relativePathToPost(post: Post) {
      const { getPostSlug } = usePost()
      return this.getPostsPath({
        postId: post.id,
        postSlug: getPostSlug(post),
      })
    },
    async validateDomain(domain: string) {
      return await this.$nuxt.$axios.$get(`/v1/newsletter/subdomain/${domain}`)
    },
  },
})
