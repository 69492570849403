import { Plugin } from '@nuxt/types'
import { Blockquote } from '@tiptap/extension-blockquote'
import { BulletList } from '@tiptap/extension-bullet-list'
import { Heading } from '@tiptap/extension-heading'
import { Highlight } from '@tiptap/extension-highlight'
import { HorizontalRule } from '@tiptap/extension-horizontal-rule'
import { Link } from '@tiptap/extension-link'
import { OrderedList } from '@tiptap/extension-ordered-list'
import { Placeholder } from '@tiptap/extension-placeholder'
import { Superscript } from '@tiptap/extension-superscript'
import { TextAlign } from '@tiptap/extension-text-align'
import { Typography } from '@tiptap/extension-typography'
import { Underline } from '@tiptap/extension-underline'
import { StarterKit } from '@tiptap/starter-kit'
import { Editor, Extension } from '@tiptap/vue-2'

import { CharacterCount as TipTapCharacterCount } from '@tiptap/extension-character-count'

import { BubbleMenu } from '@tiptap/extension-bubble-menu'
import { Code } from '@tiptap/extension-code'
import { Color } from '@tiptap/extension-color'
import Focus from '@tiptap/extension-focus'
import { Table } from '@tiptap/extension-table'
import { TableCell } from '@tiptap/extension-table-cell'
import { TableHeader } from '@tiptap/extension-table-header'
import { TableRow } from '@tiptap/extension-table-row'
import { TextStyle } from '@tiptap/extension-text-style'
import { ALIGNEMENTS, TYPES, fonts } from '~/utils/tiptap'
import { BackgroundColor } from '~/utils/tiptap/backgroundColor'
import { PaywallSeparator } from '~/utils/tiptap/paywallSeparator'

import { CharacterCount } from '~/utils/tiptap/characterCount'
import { Paste } from '~/utils/tiptap/handelPaste'
import { TextIndent } from '~/utils/tiptap/indent'
import { TrailingNode } from '~/utils/tiptap/trailingNode'

import { BorderColor } from '~/utils/tiptap/borderColor'
import { Button } from '~/utils/tiptap/button'
import { Embed } from '~/utils/tiptap/embed'
import { Figure } from '~/utils/tiptap/figure'
import { FontFamily } from '~/utils/tiptap/fontFamily'

export const Enter = Extension.create({
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        return this.editor.commands.first(({ commands }) => [
          () => commands.newlineInCode(),
          () => commands.splitListItem('listItem'),
          () => commands.createParagraphNear(),
          () => commands.liftEmptyBlock(),
          () => commands.createParagraphNear(),
          () => !commands.splitBlock({ keepMarks: true }),
          // () => !commands.unsetAllMarks(),
          ...[TYPES.PARAGRAPH, TYPES.HEADING].map(
            (type) => () =>
              commands.resetAttributes(type, [
                // ...this.editor.extensionManager.extensions
                //   .filter(({ type }) => type === 'extension')
                //   .filter(({ name }) => name !== 'textStyle')
                //   .filter(({ name }) => name !== FontFamily.name)
                //   .map(({ name }) => name),
                TextIndent.name,
                BorderColor.name,
                BackgroundColor.name,
              ])
          ),
        ])
      },
    }
  },
})

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $editor: Editor
    $editorLight: () => Editor
  }
  interface Context {
    $editor: Editor
    $editorLight: () => Editor
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $editor: Editor
    $editorLight: () => Editor
  }
}

const tiptap: Plugin = (context, inject) => {
  const {
    i18n,
    $img,
    $config: { theme, features },
  } = context

  const emptyNodeClass =
    'first:before:tw-text-gray-400 first:before:tw-absolute first:before:tw-content-[attr(data-placeholder)] first:before:tw-pointer-events-none'

  const extensionsLight = [
    Placeholder.configure({
      showOnlyWhenEditable: false,
      placeholder: i18n.t('EDITOR_LIGHT_PLACEHOLDER') as string,
      includeChildren: false,
      emptyNodeClass,
    }),
    StarterKit.configure({
      heading: {
        levels: [2, 3, 4, 5, 6],
      },
      dropcursor: {
        width: 2,
        color: theme.colors.primary,
      },
      history: {
        newGroupDelay: 200,
      },
    }),
    TipTapCharacterCount.configure({
      limit: 240,
      mode: 'textSize',
    }),
  ]

  const extensions = [
    Button,
    FontFamily.configure({
      allowlist: fonts.map(({ name }) => name),
    }),
    TextStyle,
    Paste,
    TrailingNode,
    Typography,
    TextIndent,
    BubbleMenu.configure({
      pluginKey: 'bubbleMenuTable',
      element: (!process.server && document?.querySelector('.tw-bubble-menu')) as HTMLElement | null,
      tippyOptions: {
        duration: 0,
      },
      shouldShow: ({ editor }) => {
        return editor.isActive(TYPES.TABLE)
      },
    }),
    ...(features?.tiptapTable === 'true'
      ? [
          Table.configure({
            resizable: false,
            lastColumnResizable: false,
            allowTableNodeSelection: true,
            HTMLAttributes: {
              class: 'not-prose',
            },
          }),
          TableRow,
          TableHeader,
          TableCell,
        ]
      : []),
    CharacterCount.configure({
      i18n,
    }),
    PaywallSeparator.configure({
      label: `${i18n?.t('EDITOR_UI_PAYWALL')}`,
    }),
    BackgroundColor.configure({
      types: [TYPES.PARAGRAPH, TYPES.HEADING, TYPES.TABLE, TYPES.TABLE_CELL],
    }),
    BorderColor.configure({
      types: [TYPES.PARAGRAPH, TYPES.HEADING, TYPES.TABLE, TYPES.TABLE_CELL],
      defaultBorderWidth: 4,
    }),
    Highlight.configure({
      multicolor: true,
    }),
    Code,
    Color.configure({
      types: ['textStyle'],
    }),
    Focus.configure({
      className: 'tw-has-focus',
      mode: 'shallowest',
    }),
    Figure.configure({
      allowBase64: false,
      inline: true,
      getImage: $img.getImage,
      error: null,
    }),
    Embed.configure({
      inline: true,
      getImage: $img.getImage,
    }),
    Link.configure({
      validate: (href: string) => /^https?:\/\//.test(href),
      openOnClick: false,
    }),
    Superscript,
    TextAlign.configure({
      defaultAlignment: ALIGNEMENTS.LEFT,
      types: [TYPES.PARAGRAPH, TYPES.HEADING, TYPES.BUTTON],
      alignments: [ALIGNEMENTS.LEFT, ALIGNEMENTS.RIGHT, ALIGNEMENTS.CENTER, ALIGNEMENTS.JUSTIFY],
    }),
    Underline,
    Blockquote,
    HorizontalRule,
    Heading,
    BulletList,
    OrderedList,
    StarterKit.configure({
      heading: {
        levels: [2, 3, 4, 5, 6],
      },
      dropcursor: {
        width: 2,
        color: theme.colors.primary,
      },
      history: {
        newGroupDelay: 200,
      },
      hardBreak: {
        keepMarks: true,
      },
    }),
    Placeholder.configure({
      showOnlyWhenEditable: false,
      placeholder: i18n.t('EDITOR_ADD_FIRST_PLACEHOLDER') as string,
      includeChildren: false,
      emptyNodeClass,
    }),
    Enter,
  ]

  if (!process.server) {
    context.$editor = new Editor({
      autofocus: true,
      editable: true,
      extensions: extensions as Extension[],
      parseOptions: {
        preserveWhitespace: 'full',
      },
      editorProps: {
        attributes: {
          class: 'tw-prose tw-min-h-screen tw-max-w-none dark:prose-invert focus:tw-outline-none',
        },
      },
    })

    context.$editor.extensionManager.splittableMarks.push('textStyle')

    context.$editorLight = () =>
      new Editor({
        autofocus: false,
        editable: true,
        extensions: extensionsLight as Extension[],
        editorProps: {
          attributes: {
            class: 'tw-prose dark:prose-invert focus:tw-outline-none',
          },
        },
      })
  } else {
    context.$editor = {
      extensionManager: { extensions },
    } as Editor
    context.$editorLight = () =>
      ({
        extensionManager: { extensions: extensionsLight },
        storage: {
          paywallSeparator: {
            position: 0,
          },
        },
      } as unknown as Editor)
  }

  inject('editor', context.$editor)
  inject('editorLight', context.$editorLight)
}

export default tiptap
