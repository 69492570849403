import type { Context } from '@nuxt/types'
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { usePublication } from '~/stores/publication'
import { useSubscription } from '~/stores/subscription'
import { useUser } from '~/stores/user'

export default defineNuxtMiddleware(async (ctx: Context) => {
  const { syncPublications } = usePublication(ctx.$pinia)
  // const { syncPublicationsStats } = useStats(ctx.$pinia)
  const { syncSubscriptions } = useSubscription(ctx.$pinia)
  const { user } = useUser(ctx.$pinia)

  try {
    if (user) {
      await syncPublications()
      // await syncPublicationsStats()
      await syncSubscriptions()
    }
  } catch (e) {
    console.error(e)
  }
})
