















































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    label: { type: String, required: true },
    icon: { type: String, required: false, default: 'double-caret-down' },
    size: { type: String, required: false, default: 'md' },
  },
  setup() {
    const { $editor, i18n } = useContext()

    const up = () => {
      $editor
        .chain()
        .setPaywallSeparator({
          enabled: true,
          position: $editor.storage.paywallSeparator.position - 1,
        })
        .run()
    }
    const remove = () => {
      $editor.chain().togglePaywallSeparator().run()
    }
    const down = () => {
      $editor
        .chain()
        .setPaywallSeparator({
          enabled: true,
          position: $editor.storage.paywallSeparator.position + 1,
        })
        .run()
    }

    const canUp = computed(() => {
      return $editor.storage.paywallSeparator.position > 1
    })
    const canDown = computed(() => {
      return $editor.storage.paywallSeparator.position < $editor.state.doc.childCount - 1
    })

    const tooltipUp = computed(() => i18n.t('EDITOR_MOVE_UP'))
    const tooltipDown = computed(() => i18n.t('EDITOR_MOVE_DOWN'))
    const tooltipDel = computed(() => i18n.tc('EDITOR_TOOL_ACTIVE', Number(!$editor.storage.paywallSeparator.enabled)))

    return { tooltipUp, tooltipDown, tooltipDel, canUp, canDown, up, remove, down }
  },
})
