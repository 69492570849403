var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"tw-min-h-screen",class:{
      'tw-pb-24': !_vm.isEditorView,
    }},[(!(_vm.isEditorView && !_vm.isSettings))?_c('NavbarDashboard',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('nuxt-link',{attrs:{"to":_vm.localePath({
              name: 'publication-id',
              params: {
                id: _vm.route.params.id,
              },
            })}},[_c('span',{staticClass:"tw-text-lg tw-line-clamp-1 tw-text-ellipsis tw-text-center m-md:tw-text-left tw-font-whyte"},[_vm._v("\n            "+_vm._s(_vm.title)+"\n          ")])])]},proxy:true}],null,false,990613294)}):_vm._e(),_vm._v(" "),_c('NuxtChild',{attrs:{"show-header":!(_vm.isSettings || _vm.isEditorView)}})],1),_vm._v(" "),(!_vm.isEditorView)?_c('KFooter'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }