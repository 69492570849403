




























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'

export interface TooltipItem {
  text: string
  position: 'is-top' | 'is-bottom' | 'is-left' | 'is-right'
}

export default defineComponent({
  props: {
    text: {
      type: String,
      default: null,
    },
    position: {
      type: String as PropType<TooltipItem['position']>,
      default: 'top',
    },
    fit: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open', 'close'],
  setup() {
    const tooltipOpen = ref<HTMLElement | null>(null)

    return {
      tooltipOpen,
    }
  },
})
