import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { usePublication } from '~/stores/publication'
import { removeLocalSuffix } from '~/utils/route'

export default defineNuxtMiddleware(({ redirect, localePath, route, $pinia }) => {
  const { publication } = storeToRefs(usePublication($pinia))

  const protectedRoutes = ['register-3', 'publication-create-2'] // routes that require a publication

  const routeName = removeLocalSuffix(route.name as string) // remove locale suffix
  const routeStep = routeName?.split(/(.*?)([0-9])/g) // split route name into [name, step]

  const routeNamePrefix = String(routeStep?.at(1)) // get route name prefix
  const backStep = Number(routeStep?.at(2)) - 1 // get previous step

  if (routeName && protectedRoutes.includes(routeName) && !publication.value) {
    redirect(localePath(routeNamePrefix + backStep)) // redirect to previous step
  }
})
