export default {
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    title: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    color_primary: {
      type: 'string',
    },
    categories: {
      type: 'array',
      items: {},
    },
    cropped_banner_picture: {},
    subdomain: {},
    cropped_about_picture: {},
    about_enabled: {
      type: 'boolean',
    },
    comment_enabled: {
      type: 'boolean',
    },
    about: {
      type: [
        'null',
        'string',
      ],
    },
    about_biography: {
      type: [
        'null',
        'string',
      ],
    },
    referral_enabled: {
      type: 'boolean',
    },
    recommendation_enabled: {
      type: 'boolean',
    },
    alias_enabled: {
      type: 'boolean',
    },
    yearly_subscription_mail_enabled: {
      type: 'boolean',
    },
    referral_description: {
      type: [
        'null',
        'string',
      ],
    },
    mail_sender_mail: {
      type: 'string',
    },
    mail_sender_name: {
      type: 'string',
    },
    default_title_font: {
      type: 'string',
    },
    default_text_font: {
      type: 'string',
    },
  },
  $schema: 'http://json-schema.org/draft-07/schema#',
}
