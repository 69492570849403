



























import { computed, defineComponent, reactive, useRoute, useRouter, ref } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { useRights } from '~/stores/right'
import { useUser } from '~/stores/user'

export default defineComponent({
  setup() {
    const formRef = ref()
    const route = useRoute()
    const router = useRouter()
    const { subscriptionError, userCanSubscribe, subscriptionLevelsLabel } = storeToRefs(useRights())
    const { user, isLogged } = storeToRefs(useUser())
    const mailQuery = ref(typeof route.value?.query?.email === 'string' ? route.value.query?.email : null)

    const form = reactive({
      email: user.value?.email || mailQuery.value || '',
    })

    const submit = (e:Event) => {
      if (formRef?.value?.isValid) {
        e.preventDefault()
        router.push({ query: { ...route.value.query, landing: undefined, email: form.email, subscribe: 'true' } })
      }
    }

    return {
      formRef,
      submit,
      form,
      userCanSubscribe: computed(() => userCanSubscribe.value()),
      subscriptionLevelsLabel: computed(() => subscriptionLevelsLabel.value()),
      subscriptionError: computed(() => subscriptionError.value()),
      isLogged,
    }
  },
})
