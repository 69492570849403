import { AuthPayload, TSigninForm } from '@kessel/core'
import { defineStore } from 'pinia'
import { useDomain } from '~/stores/domain'
import { useUser } from '~/stores/user'

interface State {
  payload: AuthPayload | null
}

export const useAuth = defineStore('auth', {
  state: (): State => ({
    payload: null,
  }),
  actions: {
    async login({ email, password }: TSigninForm) {
      const { syncUser } = useUser()
      if (!(email && password)) {
        throw new Error('Login: missing data')
      }
      const params = new URLSearchParams()
      params.append('username', email)
      params.append('password', password)
      await this.$nuxt.$axios.$post('/v1/auth/oauth', params)
      await syncUser(false)
    },
    async signUp(email: string, password?: string, cguAccepted?: boolean) {
      const { putUser } = useUser()
      const prefix = email.split('@')[0]
      await this.$nuxt.$axios.$post('/v1/auth/register', { email: email.toLowerCase(), password, cgu_accepted: cguAccepted })
      await putUser({
        first_name: prefix.split('.')[0] || '',
        last_name: prefix.split('.')[1] || '',
        biography: '',
        has_password: !!password,
      })
    },
    async checklink(token: string) {
      return await this.$nuxt.$axios.$get(`/v1/auth/checklink/${token}`)
    },
    async generateMagicLink(email: string, redirect?: string, publicationId?: string) {
      await this.$nuxt.$axios.$get('/v1/auth/generatelink', {
        params: {
          email,
          ...(redirect && { redirect }),
          ...(publicationId && { news_id: publicationId }),
        },
      })
    },
    async sendForgetPasswordLink(email: string) {
      await this.$nuxt.$axios.$post('/v1/user/password/forget', null, {
        params: {
          email,
        },
      })
    },
    async logout(): Promise<string> {
      const { goToHome } = useDomain()
      useUser().$reset()
      await this.$nuxt.$axios.$get('/v1/auth/logout')
      return goToHome()
    },
    async deleteAccount() {
      await this.$nuxt.$axios.$delete('/v1/user')
    },
    async definePassword(password: string) {
      await this.$nuxt.$axios.$post('/v1/user/password/reset', { newpass: password })
    },
    setPayload(payload: AuthPayload) {
      const { $patch } = this
      $patch({ payload })
    },
  },
})
