import { Context } from '@nuxt/types'
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { useFeatureFlip } from '~/stores/featureFlip'
import { useSearch } from '~/stores/search'

export default defineNuxtMiddleware(async (ctx: Context) => {
  const { route, error } = ctx

  const { isActive } = useFeatureFlip(ctx.$pinia)

  if (!isActive('search')) {
    error({ statusCode: 404, message: 'Page not found' })
  }

  const { search } = useSearch(ctx.$pinia)

  const slug = route.params.slug as string
  await search(slug)
})
