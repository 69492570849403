






































































import { computed, defineComponent, onBeforeUnmount, PropType, Ref, ref, toRefs, useContext, watch } from '@nuxtjs/composition-api'
import { useThemeColor } from '~/composables/useTheme'

export interface DropdownItem {
  label: string
  title?: string
  color?: string
  font?: {
    size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' // default: xs
    bold?: boolean
    family?: string
  }
  icon?: {
    name: string
    color?: string
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' // default: sm
    position_right?: boolean
  }
  action?: () => void
  href?: string
  to?: string
  children?: {
    id: string
    items: DropdownItem[]
    itemsClass?: string
    active?: boolean
    disabled?: boolean
    isPage?: boolean
  }
  show?: boolean// if false, item will not be shown
  separator?: boolean
}

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<DropdownItem[]>,
      required: true,
    },
    itemsClass: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isPage: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { id, items } = toRefs(props)
    const { i18n } = useContext()
    const { extractColorFromTheme } = useThemeColor()

    const currentDropdown: Ref<DropdownItem['children']> = ref(items.value.map((i) => {
      i.children && (i.children.isPage = true)
      return i
    }).find((i) => i.children?.active)?.children || props)

    const navigationID = computed(() => `${id.value}_${currentDropdown.value?.id}`)
    const isNavigate = ref(false)

    const getNavigationItems = () => {
      if (currentDropdown.value?.isPage) { // if dropdown is a page/children or main dropdown
        currentDropdown.value.items.at(0)?.label !== navigationID.value && currentDropdown.value.items.unshift({
          label: navigationID.value,
          title: String(i18n.t('BACK')),
          font: currentDropdown.value.items[0].font,
          icon: {
            name: 'arrow-left',
          },
        }) // add back button if not already exist
        return currentDropdown.value.items
      }

      return items.value
    }

    const currentNavigationItems: Ref<DropdownItem[]> = ref(getNavigationItems())
    watch(currentDropdown, () => {
      currentNavigationItems.value = getNavigationItems()
    }, { deep: true })

    const dropdownId = computed(() => `#k-dropdown-items__${currentDropdown.value?.id || id.value}`)
    const localActive = ref(currentDropdown.value?.active || false)

    const toggle = () => {
      document.addEventListener('click', close)
    }

    /*
     * Close dropdown if click outside & not in navigation
     */
    const close = (e: any) => {
      const parentElement = document.querySelector(`[id='${dropdownId.value}']`)
      const clickOnDropdown = parentElement?.contains(e.target)

      if (!clickOnDropdown && !isNavigate.value) {
        if (localActive.value && (typeof currentDropdown.value?.active === 'undefined' || currentDropdown.value?.active)) {
          localActive.value = false
          actionManager(undefined, true)
          document.removeEventListener('click', close)
        } else {
          localActive.value = true
        }
        emit('update:active', localActive.value)
      }
      isNavigate.value = false
    }

    const getColor = (color: string) => {
      const { hexColor } = extractColorFromTheme(color)
      return hexColor
    }

    /*
     * Manage action on item clicked
     */
    const actionManager = (item?: DropdownItem, reset = false) => {
      if (item?.action && !reset) {
        item.action()
        isNavigate.value = false
      } else if (item?.children || item?.label === navigationID.value || reset) {
        currentDropdown.value = item?.children
          ? { ...item.children, isPage: true }
          : { ...props, isPage: false }
        isNavigate.value = true
      }
    }

    onBeforeUnmount(() => {
      document.removeEventListener('click', close)
    })

    return {
      currentNavigationItems,
      dropdownId,
      navigationID,
      close,
      toggle,
      localActive,
      getColor,
      actionManager,
    }
  },
})
