export default {
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    biography: {
      type: [
        'null',
        'string',
      ],
    },
    email: {
      type: 'string',
    },
    first_name: {
      type: [
        'null',
        'string',
      ],
    },
    last_name: {
      type: [
        'null',
        'string',
      ],
    },
    cropped_picture: {
    },
    has_password: {
      type: 'boolean',
    },
    role: {
      type: 'string',
    },
  },
  $schema: 'http://json-schema.org/draft-07/schema#',
}
