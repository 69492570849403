import { Category, PostList, PublicationAuthors, PublicationList, User } from '@kessel/core'
import { defineStore } from 'pinia'

// This interface must remain private
interface State {
  publications: PublicationList[]
  posts: PostList[]
  publicationsAuthors: PublicationAuthors[]
  categories: Category[]
  publicationsAuthorsToFollow: PublicationAuthors[]
}

export const useLanding = defineStore('landing', {
  state: (): State => ({
    publications: [],
    posts: [],
    publicationsAuthors: [],
    categories: [],
    publicationsAuthorsToFollow: [],
  }),
  actions: {
    async syncLanding() {
      const { $axios } = this.$nuxt

      const [publications, posts, publicationsAuthors, publicationsAuthorsToFollow] = await Promise.all([
        $axios.$get('/v1/landing/publication'),
        $axios.$get('/v1/landing/post'),
        $axios.$get('/v1/landing/author'),
        $axios.$get('/v1/landing/author_to_follow'),
      ])

      this.$patch({
        publications,
        posts,
        publicationsAuthors,
        categories: publications.reduce((acc: User[], { categories = [] }: PublicationList) => [...acc, ...categories], []),
        publicationsAuthorsToFollow,
      })
    },
  },
})
