









































































































































import { computed, defineComponent, ref, toRefs, useContext } from '@nuxtjs/composition-api'

import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2'

import { ImageCropped } from '@kessel/core'
import { useFeatureFlip } from '~/stores/featureFlip'
import { BASE64_URL_REGEX } from '~/utils/base64ToBlob'

const IMAGE_MIN_SIZE = 50

export default defineComponent({
  components: {
    NodeViewWrapper,
  },
  props: { ...nodeViewProps as any },
  setup(props) {
    const { updateAttributes, node: { value: { type: { name }, attrs: { src: filename, crop, width, height, error } } } } = toRefs(props)
    const { $editor } = useContext()
    const active = computed(() => $editor.isActive(name))
    const kupload = ref()
    const image = ref()
    const { isActive } = useFeatureFlip()
    const featureExpandedImage = computed(() => isActive('expandedImage'))
    const featureResizedImage = computed(() => isActive('resizedImage'))

    const croppedFile = ref<ImageCropped | null>(
      (filename && filename.length && {
        filename,
        ...crop,
      }) || null)

    const resizeFile = ref<Pick<ImageCropped, 'width' | 'height'>>(
      { width, height }
    )

    const isCropModalActive = ref(false)

    const updateImageStyle = ({
      width,
      height,
    }:Pick<ImageCropped, 'width' | 'height'>) => {
      resizeFile.value = {
        width,
        height,
      }
      if (image.value?.style) {
        image.value.style.width = width ? `${width}px` : ''
        image.value.style.height = height ? `${height}px` : ''
        image.value.style.marginLeft = width ? `-${width * 0.5}px` : ''
        image.value.style.opacity = width ? '1' : ''
      }
    }

    const handler = (mouseDownEvent: MouseEvent) => {
      const target: Element = (mouseDownEvent.currentTarget as Element)

      if (target?.className !== 'resize-button') {
        return
      }

      mouseDownEvent.preventDefault()

      const parent = (mouseDownEvent.target as Element).closest('.image-element')
      const image = parent?.querySelector('img') ?? null
      if (image === null) {
        return
      }
      const startSize = { x: image.clientWidth, y: image.clientHeight }
      const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY }

      function onMouseMove(mouseEvent: MouseEvent) {
        if (image === null || parent === null) {
          return
        }
        const width = Math.max(IMAGE_MIN_SIZE, Math.min(parent.clientWidth, startSize.x - startPosition.x + mouseEvent.pageX))

        const ratio = width / image.width

        updateImageStyle({
          width,
          height: image.height * ratio,
        })
      }
      function onMouseUp() {
        updateAttributes.value(resizeFile.value)

        document.body.removeEventListener('mousemove', onMouseMove)
      }

      document.body.addEventListener('mousemove', onMouseMove)
      document.body.addEventListener('mouseup', onMouseUp, { once: true })
    }

    const updateCaption = (caption: string) => {
      return updateAttributes.value({
        caption,
      })
    }

    const updateLink = (href: string) => {
      return updateAttributes.value({
        href,
      })
    }

    const forceUpload = () => {
      kupload.value?.$refs?.inputEl?.$el?.click()
    }

    const expandImage = (value: boolean) => {
      if (value) {
        updateImageStyle({
          width: undefined,
          height: undefined,
        })
      }

      return updateAttributes.value({
        expanded: value,
        ...!value && resizeFile.value,
      })
    }

    const updateCover = (data: ImageCropped) => {
      console.info('updateCover ===> ', data)
      if (!data) {
        remove()
        return
      }

      const { filename, ...restCrop } = data || { x: 0, y: 0, width: 0, height: 0 }

      updateImageStyle({
        width: undefined,
        height: undefined,
      })

      croppedFile.value = data

      if (filename && BASE64_URL_REGEX.test(filename)) {
        console.warn('Don‘t update editor image if content is in base64 to prevent savind unwanted URLs')
        // Don't update editor image if content is in base64 to prevent savind unwanted URLs
        return
      }

      return updateAttributes.value({ src: filename, crop: restCrop, ...resizeFile.value })
    }

    const remove = () => {
      $editor.chain().focus().deleteSelection().run()
    }

    return {
      kupload,
      forceUpload,
      active,
      remove,
      expandImage,
      croppedFile,
      updateCover,
      updateCaption,
      updateLink,
      isCropModalActive,
      featureExpandedImage,
      featureResizedImage,
      handler,
      resizeFile,
      image,
      error,
    }
  },
})
