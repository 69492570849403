import { AuthPayload } from '@kessel/core'
import { Context } from '@nuxt/types'
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { verify } from 'jsonwebtoken'
import { useAuth } from '~/stores/auth'
import { useSubscription } from '~/stores/subscription_v2'

export default defineNuxtMiddleware(async (context: Context) => {
  const { route, error, redirect, $config } = context
  const { setPayload } = useAuth(context.$pinia)
  const { syncSubscriptionFromToken } = useSubscription(context.$pinia)
  const token = route.query.token

  if (!token) {
    error({ statusCode: 404, message: 'Page not found' })
    return
  }

  if (!process.server) {
    route.query.redirect && redirect(route.query.redirect as string)
    return
  }

  const publicKey = $config.JWT_PUBLIC.replace(/\\n/g, '\n')

  await new Promise((resolve) => {
    verify(token as string, publicKey, { algorithms: ['RS256'] }, async (err, decoded) => {
      if (err) {
        return resolve(error({ statusCode: 404, message: 'Invalid token' }))
      }

      try {
        const payload = decoded as AuthPayload
        setPayload(payload)
        resolve(await syncSubscriptionFromToken(String(payload.subscription.id), token as string))
      } catch (e) {
        resolve(error({ statusCode: 404, message: 'Invalid subscription' }))
      }
    })
  })
})
