import { Context } from '@nuxt/types'
import { defineNuxtMiddleware, computed } from '@nuxtjs/composition-api'
import { useRights } from '~/stores/right'
import { useDomain } from '~/stores/domain'
import { useFeatureFlip } from '~/stores/featureFlip'

export default defineNuxtMiddleware((ctx: Context) => {
  const { userCanSubscribe } = useRights(ctx.$pinia)
  const { isActive } = useFeatureFlip()
  const featureInscriptionV2 = computed(() => isActive('inscriptionV2Enabled'))
  const { redirect, route, $device } = ctx

  const crawler = $device.isCrawler
  const { parseHostname } = useDomain()
  const { subdomain, host } = parseHostname(ctx)

  if (!crawler && userCanSubscribe() && process.server && !route.query.subscribe && !route.query.r && !route.query.preview) {
    if (featureInscriptionV2.value) {
      return redirect(`https://inscription.${host}/${subdomain}`)
    } else {
      return redirect({
        query: {
          ...route.query,
          landing: userCanSubscribe().toString(),
        },
      })
    }
  }
})
