














































import { computed, defineComponent, nextTick, ref, toRefs } from '@nuxtjs/composition-api'

import KIcon from '~/components/icons/KIcon.vue'

export default defineComponent({
  components: { KIcon },
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: [Array, Object, String, Number, Boolean],
      required: false,
      default: null,
    },
    nativeValue: {
      type: [Object, String, Number, Boolean],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:value', 'changed:value'],
  setup(props, { emit }) {
    const { nativeValue, value } = toRefs(props)
    const checkbox = ref()

    const updateInput = async (event:Event) => {
      const isChecked = (event.target as HTMLFormElement).checked

      if (value.value instanceof Array) {
        const newValue = [...value.value]

        if (isChecked) {
          newValue.push(nativeValue.value as never)
        } else {
          newValue.splice(newValue.indexOf(nativeValue.value as never), 1)
        }
        emit('update:value', newValue)
        emit('changed:value', nativeValue.value)
      } else {
        emit('update:value', isChecked)
      }

      await nextTick()
    }

    const isChecked = computed(() => {
      if (value.value instanceof Array) {
        return value.value.includes(nativeValue.value as never)
      }
      return value.value
    })

    return {
      checkbox,
      isChecked,
      updateInput,
    }
  },
})
