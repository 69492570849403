import { render, staticRenderFns } from "./newsletter.vue?vue&type=template&id=80db62c4&"
import script from "./newsletter.vue?vue&type=script&lang=ts&"
export * from "./newsletter.vue?vue&type=script&lang=ts&"
import style0 from "./newsletter.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PostNavbar: require('/tmp/build_84effd69/apps/web/components/post/PostNavbar.vue').default,PublicationNavbar: require('/tmp/build_84effd69/apps/web/components/publication/PublicationNavbar.vue').default,SubscribeDrawer: require('/tmp/build_84effd69/apps/web/components/SubscribeDrawer.vue').default,RecommendDrawer: require('/tmp/build_84effd69/apps/web/components/form/recommend/RecommendDrawer.vue').default,PublicationLanding: require('/tmp/build_84effd69/apps/web/components/publication/PublicationLanding.vue').default,KFooter: require('/tmp/build_84effd69/apps/web/components/KFooter.vue').default})
