import { render, staticRenderFns } from "./ColorPicker.vue?vue&type=template&id=7e90dcea&scoped=true&"
import script from "./ColorPicker.vue?vue&type=script&lang=ts&"
export * from "./ColorPicker.vue?vue&type=script&lang=ts&"
import style0 from "./ColorPicker.vue?vue&type=style&index=0&id=7e90dcea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e90dcea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KIcon: require('/tmp/build_84effd69/apps/web/components/icons/KIcon.vue').default,KTooltip: require('/tmp/build_84effd69/apps/web/components/KTooltip.vue').default})
