import { Extension } from '@tiptap/core'
import { isValid, toHexString } from '../color'

export interface BackgroundOptions {
  types: string[]
  defaultBackgroundColor?: string
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    backgroundColor: {
      /**
       * Set the background color attribute
       */
      setBackground: (backgroundColor: string) => ReturnType
      /**
       * Unset the background color attribute
       */
      unsetBackground: () => ReturnType
    }
  }
}

export const BackgroundColor = Extension.create<BackgroundOptions>({
  name: 'backgroundColor',

  content: 'block+',

  group: 'block',

  defining: true,

  addOptions() {
    return {
      types: ['paragraph'],
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      backgroundColor: {
        default: this.options.defaultBackgroundColor,
      },
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          backgroundColor: {
            default: this.options.defaultBackgroundColor,
            parseHTML: (element) => {
              const foundedColor = element.style.backgroundColor || this.options.defaultBackgroundColor

              return (
                (foundedColor !== 'transparent' && foundedColor && isValid(foundedColor) && toHexString(foundedColor)) ||
                undefined
              )
            },
            renderHTML: (attributes) => {
              if (attributes.backgroundColor === this.options.defaultBackgroundColor) {
                return {}
              }

              if (attributes.backgroundColor === 'transparent') {
                return {}
              }

              return {
                style: `background-color: ${attributes.backgroundColor}`,
                class: 'tw-px-4 tw-py-2',
              }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setBackground:
        (backgroundColor) =>
          ({ commands }) => {
            return this.options.types.every((type) => commands.updateAttributes(type, { backgroundColor }))
          },

      unsetBackground:
        () =>
          ({ commands }) => {
            return this.options.types.every((type) => commands.resetAttributes(type, this.name))
          },
    }
  },
})
