import { FontFamily as TipTapFontFamily, FontFamilyOptions as TipTapFontFamilyOptions } from '@tiptap/extension-font-family'

export interface FontFamilyOptions extends TipTapFontFamilyOptions {
    allowlist: string[]
}

export const FontFamily = TipTapFontFamily.extend<FontFamilyOptions>({
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontFamily: {
            default: null,
            parseHTML: (element) => {
              const fontName = element.style.fontFamily?.replace(/['"]+/g, '')
              return (this.options.allowlist.includes(fontName) && fontName) || undefined
            },
            renderHTML: (attributes) => {
              if (!attributes.fontFamily) {
                return {}
              }

              return {
                style: `font-family: ${attributes.fontFamily}`,
              }
            },
          },
        },
      },
    ]
  },
})
