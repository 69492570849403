import type { Context } from '@nuxt/types'
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { usePublication } from '~/stores/publication_v2'

export default defineNuxtMiddleware(async (ctx: Context) => {
  const { publication } = usePublication(ctx.$pinia)
  const { redirect } = ctx
  if (!publication?.about_enabled) {
    await redirect('/')
  }
})
