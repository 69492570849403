
























import { PropType, computed, defineComponent, toRefs, useRoute } from '@nuxtjs/composition-api'

import { SearcheableIndexes } from '@kessel/core'
import { storeToRefs } from 'pinia'
import { useFeatureFlip } from '~/stores/featureFlip'
import { useSearch } from '~/stores/search'

export default defineComponent({
  props: {
    autocomplete: {
      type: Boolean,
      default: true,
    },
    indexes: {
      type: Array as PropType<SearcheableIndexes[]>,
      required: false,
      default: () => [SearcheableIndexes.PUBLICATION, SearcheableIndexes.POST],
    },
  },
  setup(props) {
    const { autocomplete, indexes } = toRefs(props)
    const { isActive } = useFeatureFlip()
    const route = useRoute()

    const { query, search, goToResult, goToSearchPage } = useSearch()
    const { results, isFetching } = storeToRefs(useSearch())

    return {
      query,
      isFetching,
      results: computed(() => (!route.value.name?.includes('search') ? results.value : [])),
      goToResult,
      goToSearchPage: () => autocomplete.value && goToSearchPage(),
      search: (query: string) => search(query, indexes.value),
      isActive: computed(() => isActive('search')),
    }
  },
})
