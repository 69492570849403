import { ImageOptions as NuxtImageOptions, ResolvedImage } from '@nuxt/image'
import { Node, mergeAttributes, nodePasteRule } from '@tiptap/core'
import { PasteRule, VueNodeViewRenderer } from '@tiptap/vue-2'
// @ts-ignore
import DOMParser from 'universal-dom-parser'

import EmbedElement from '~/components/editor/components/tiptap/TipTapEmbedElement.vue'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    embed: {
      /**
       * Insert a embed code
       */
      setEmbed: (options: { code: string; image?: string; href?: string }) => ReturnType
    }
  }
}

export interface EmbedOptions {
  HTMLAttributes: Record<string, any>
  inline: boolean
  getImage: (source: string, options?: NuxtImageOptions | undefined) => ResolvedImage
}

export const YOUTUBE_REGEX = /^(https?:\/\/)?(www\.|music\.)?(youtube\.com|youtu\.be)(.+)?$/
export const YOUTUBE_EMBED =
  /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g
export const YOUTUBE_REGEX_GLOBAL = /^(https?:\/\/)?(www\.|music\.)?(youtube\.com|youtu\.be)(.+)?$/g
export const EMBED_SRC_REGEX = /(?:data-instgrm-permalink|href|src)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']?/

export const INSTAGRAM_SCRIPT = '//platform.instagram.com/fr_FR/embeds.js'
export const TWITTER_SCRIPT = '//platform.twitter.com/widgets.js'

export const platforms = [
  {
    regex: 'tiktok',
    attributes: {
      hid: 'tiktok',
      src: '//www.tiktok.com/embed.js',
      async: true,
      defer: true,
    },
  },
  {
    regex: 'instagram',
    attributes: {
      hid: 'instagram',
      src: '//www.instagram.com/embed.js',
      async: true,
      defer: true,
      onload: 'javascript:window.instgrm.Embeds.process()',
    },
  },
  {
    regex: 'twitter',
    attributes: {
      hid: 'twitter',
      src: '//platform.twitter.com/widgets.js',
      charset: 'utf-8',
      async: true,
      defer: true,
      onload: 'javascript:window.twttr.widgets.load()',
    },
  },
]

export const Embed = Node.create<EmbedOptions>({
  name: 'embed',
  inline() {
    return this.options.inline
  },

  group() {
    return this.options.inline ? 'inline' : 'block'
  },

  draggable: true,
  addNodeView() {
    return VueNodeViewRenderer(EmbedElement)
  },
  addAttributes() {
    // Return an object with attribute configuration
    return {
      code: {
        default: null,
      },
      image: {
        default: null,
      },
      href: {
        default: null,
      },
    }
  },
  renderHTML({ HTMLAttributes: { code, image: src, ...restHTMLAttributes } }) {
    const { getImage } = this.options

    const DomDoc = new DOMParser().parseFromString('', 'text/html')
    const div = !process.server && DomDoc.createElement('div')

    if (div) {
      div.dataset.type = 'raw'

      div.innerHTML = code
    }

    const platformMatch: any = platforms.find(({ regex }) => code.match(regex))

    if (platformMatch && !process.server && typeof window !== 'undefined') {
      const loadEmbedScript = document.createElement('script')

      Object.entries(platformMatch.attributes).forEach(([key, value]) => {
        value && typeof value === 'string' && loadEmbedScript.setAttribute(key, value)
      })

      document.head.appendChild(loadEmbedScript)
    }

    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, restHTMLAttributes, { class: 'element-embed' }),
      div || [
        'img',
        mergeAttributes(this.options.HTMLAttributes, {
          ...(getImage && {
            src:
              (src &&
                getImage(src, {
                  modifiers: {
                    width: 600,
                    height: 320,
                    fit: 'contain',
                    fill: 'blur',
                    blend: getImage('/static/play_button_light.png').url,
                    border: '25,00FFFFFF',
                    blendMode: 'normal',
                    blendAlign: 'middle,center',
                    blendWidth: 100,
                    blendHeight: 100,
                    borderRadiusInner: 20,
                    borderRadius: 20,
                  },
                }).url) ||
              null,
          }),
        }),
      ],
      // (platformMatch && ['script', { ...platformMatch.attributes }]) || ['div'],
    ]
  },
  parseHTML() {
    return [
      {
        tag: 'div[data-embed] iframe',
      },
    ]
  },

  addPasteRules() {
    return [
      new PasteRule({
        find: YOUTUBE_EMBED,
        handler: ({ state, range, match }) => {
          const [, label] = match
          state.tr.replaceWith(range.from, range.to, this.type.create({ label }))
        },
      }),
      nodePasteRule({
        find: YOUTUBE_EMBED,
        type: this.type,
        getAttributes: (match) => {
          return { code: match.input }
        },
      }),
    ]
  },

  addCommands() {
    return {
      setEmbed: ({ code, ...rest }) => {
        const [_, href] = EMBED_SRC_REGEX.exec(code) || []

        return ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            marks: [
              {
                type: 'link',
                attrs: { href },
              },
            ],
            attrs: {
              ...rest,
              code,
            },
          })
        }
      },
    }
  },
})
