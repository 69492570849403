import { useContext } from '@nuxtjs/composition-api'
import { defineStore, storeToRefs } from 'pinia'
import { usePublication } from './publication_v2'
import { useUser } from '~/stores/user'
import { isHexColor, isLightValue } from '~/utils/color'

interface State {
  colors: {
    colorPrimary: string | null
    colorSecondary: string | null
  }
}

export const useApp = defineStore('app', {
  state: (): State => ({
    colors: {
      colorPrimary: null,
      colorSecondary: null,
    },
  }),
  actions: {
    appColors() {
      const {
        $config: { theme },
        $sentry,
      } = useContext()
      try {
        const colorsRef = Object.values(theme.colors)
          .filter((value) => typeof value === 'string')
          .filter((value: any) => isHexColor(value))
          .filter((value: any) =>
            isLightValue(value)
          ) as string[]

        return [...new Set(colorsRef)]
      } catch (error) {
        $sentry?.captureException(error)
      }
    },
    syncColors() {
      const { publication } = storeToRefs(usePublication())
      if (publication.value) {
        const { color_primary: colorPrimary, color_secondary: colorSecondary } = publication.value
        this.$patch({
          colors: {
            colorPrimary,
            colorSecondary,
          },
        })
      }
    },
    async initApp() {
      const { syncUser } = useUser()

      await syncUser()
    },
  },
})
