import { StripeAccount, StripeBilling } from '@kessel/core'
import { defineStore } from 'pinia'
import { useOffer } from '~/stores/offer'

interface State {
  account: StripeAccount | null
}

export const useStripe = defineStore('stripe', {
  state: (): State => ({
    account: null,
  }),
  getters: {
    isAccountEnabled(state): Boolean {
      return state.account?.status === 'enabled' && !!useOffer().offer
    },
    isSubscriptionEnabled(): Boolean {
      return !!useOffer().offer?.subscription_enabled
    },
    isTrialEnabled(): Boolean {
      return !!useOffer().offer?.trial_eligible
    },
    isPremiumSubscriptionEnabled(): Boolean {
      return !!useOffer().offer?.premium_subscription_enabled
    },
  },
  actions: {
    async syncStripe(offer = true) {
      const { syncOffer } = useOffer()

      this.account = await this.getStripeAccount(this.$nuxt.route.params.id)
      offer && await syncOffer(offer)
    },
    async getStripeAccount(pubId: string): Promise<StripeAccount> {
      return await this.$nuxt.$axios.$get(`/v1/newsletter/${encodeURIComponent(pubId)}/stripe_account`)
    },
    async connectStripe(pubId: string, href: string) {
      const { url } = await this.$nuxt.$axios.$get(
        `/v1/newsletter/${pubId}/account_link?return_url=${encodeURIComponent(href)}&link_type=account_onboarding`
      )
      window.open(url, '_blank')
    },
    async enableStripe(pubId: string, recreate = false) {
      await this.$nuxt.$axios.$post(`/v1/newsletter/${pubId}/enable_stripe?recreate=${recreate}`)
      await this.syncStripe(false)
    },
    async disableStripe(pubId: string) {
      await this.$nuxt.$axios.$delete(`/v1/newsletter/${encodeURIComponent(pubId)}/stripe_account`)
      await this.syncStripe(false)
    },
    async stripeBilling(subId: string): Promise<StripeBilling> {
      return await this.$nuxt.$axios.$get(`/v1/subscription/${encodeURIComponent(subId)}/billing`)
    },
  },
})
