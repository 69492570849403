import {
  BasePublication,
  MailTemplate,
  MailTemplateEnum,
  Owner,
  Publication,
  PutPublication,
  PutPublicationValidationSchema,
  SubscribersParameters,
} from '@kessel/core'
import Ajv from 'ajv'
import differenceBy from 'lodash/differenceBy'
import pick from 'lodash/pick'
import { defineStore } from 'pinia'
import { Context } from '@nuxt/types'
import { useDomain } from '~/stores/domain'
import { CSV } from '~/utils/file'

const ajv = new Ajv()
const validate = ajv.compile(PutPublicationValidationSchema)

interface State {
  publications: Publication[]
  mailTemplates: MailTemplate[]
  recommendations: Publication[]
  publication: Publication | null
  publicationSubDomainName: string | null
}

export const usePublication = defineStore('publication', {
  state: (): State => ({
    publications: [],
    mailTemplates: [],
    recommendations: [],
    publication: null,
    publicationSubDomainName: null,
  }),
  getters: {
    getAuthorPublication: (state) => (publication?: Publication) => (publication || state.publication)?.owners[0],
    floorDecimals: () => (value: number) => Math.floor(value * 100) / 100 || 0,
    getAuthorName: () => (author?: Owner) => (author && `${author?.first_name || ''} ${author?.last_name || ''}`) || '',
    getMailtemplate: (state) => (key: MailTemplateEnum) => state.mailTemplates?.find((template) => template.key === key),
  },
  actions: {
    async syncPublications() {
      const {
        $patch,
        $nuxt: { $axios },
      } = this
      $patch({ publications: (await $axios.$get('/v1/newsletter')).items })
    },
    async importSubstack(file: File) {
      const {
        publication,
        $nuxt: { $axios },
      } = this
      const formData = new FormData()
      formData.append('file', file, file.name)

      await $axios.$post(`/v1/newsletter/${publication?.id}/substack_import`, formData)
    },
    async syncPublication(publicationId: string) {
      const { getPublication, $patch } = this

      $patch({
        publication: await getPublication(publicationId),
      })
    },
    async syncMailTemplates(publicationId: string) {
      const { getMailTemplates, $patch } = this

      const mailTemplates = await getMailTemplates(publicationId)
      $patch({
        mailTemplates,
      })
    },
    async syncRecommendations(publicationId: string) {
      const { getRecommendations, $patch } = this

      const recommendations = await getRecommendations(publicationId)
      $patch({
        recommendations,
      })
    },
    async syncOwnPublication(publicationId: string) {
      const { getOwnPublication, $patch } = this

      $patch({
        publication: await getOwnPublication(publicationId),
      })
    },
    async syncPublicationFromSubdomain(context?: Context) {
      const { parseHostname } = useDomain()
      const { getSubdomainPublicationId } = usePublication()
      const { subdomain } = parseHostname(context)

      const publicationId = await getSubdomainPublicationId(subdomain)

      await this.syncPublication(publicationId)
    },
    async getPublication(publicationId: string) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$get(`/v1/newsletter/${publicationId}`)
    },
    async getOwnPublication(publicationId: string) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$get(`/v1/newsletter/${publicationId}/own`)
    },
    async getMailTemplates(publicationId: string) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$get(`/v1/newsletter/${publicationId}/templates`)
    },
    async getRecommendations(publicationId: string) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$get(`/v1/newsletter/${publicationId}/recommendation`)
    },
    putLocalMailTemplate(mailTemplate: Partial<MailTemplate>) {
      const { mailTemplates, $patch } = this

      return $patch({
        mailTemplates: mailTemplates.map((template) => ({
          ...template,
          ...(template.key === mailTemplate.key && { ...template, ...mailTemplate }),
        })),
      })
    },
    async putMailTemplate(putMailTemplate: MailTemplate) {
      const {
        putLocalMailTemplate,
        publication,
        $nuxt: { $axios },
      } = this

      if (publication) {
        const toPutTemplate = {
          publication_id: publication.id,
          ...putMailTemplate,
        }

        await $axios.$put(`/v1/newsletter/${publication.id}/template`, toPutTemplate)
        putLocalMailTemplate(toPutTemplate)

        return toPutTemplate
      }
    },
    async addRecommendations(recommendations: Publication[]) {
      const {
        publication,
        recommendations: oldRecos,
        $nuxt: { $axios },
      } = this

      if (publication) {
        await $axios.$post(
          `/v1/newsletter/${publication.id}/recommendation`,
          recommendations.map((r) => r.id)
        )

        this.$patch({
          recommendations: [...oldRecos, ...recommendations],
        })
      }
    },
    async deleteRecommendations(recommendations: Publication[]) {
      const {
        publication,
        recommendations: oldRecos,
        $nuxt: { $axios },
      } = this

      if (publication) {
        await $axios.$delete(`/v1/newsletter/${publication.id}/recommendation`, { data: recommendations.map((r) => r.id) })

        this.$patch({
          recommendations: differenceBy(oldRecos, recommendations),
        })
      }
    },
    async deleteMailTemplate(deleteMailTemplate: MailTemplate) {
      const {
        publication,
        $nuxt: { $axios },
      } = this
      if (publication) {
        await $axios.$delete(`/v1/newsletter/${publication.id}/template`, { data: deleteMailTemplate })
      }
    },
    async putPublication(putPublication: PutPublication) {
      const {
        putLocalPublication,
        publication: previousPublicationData,
        $nuxt: { $axios },
      } = this

      const publication = pick(putPublication, Object.keys(PutPublicationValidationSchema.properties))
      const isValid = validate(publication)

      if (!isValid) {
        console.error(validate.errors)
        throw new Error(`Validation Error. ${JSON.stringify(validate.errors)}`)
      }

      putLocalPublication({ ...previousPublicationData, ...publication })

      const id = putPublication?.id || previousPublicationData?.id

      const { subdomain } = publication
      const isNew = !id

      const { id: newId } = await $axios[isNew ? '$post' : '$put'](`/v1/publication${isNew ? '' : `/${id}`}`, publication)

      if (subdomain && newId) {
        this.putSubdomainPublicationId(newId, subdomain.name)
      }

      putLocalPublication({ id, ...putPublication })

      return id
    },
    putLocalPublication(publication: Partial<Publication | BasePublication>) {
      const { $patch } = this

      $patch({
        publication: publication as Publication,
      })
    },
    async deletePublication(publicationId: string) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$delete(`/v1/newsletter/${encodeURIComponent(publicationId)}`)
    },
    async getSubdomainPublicationId(subdomain: string) {
      const {
        $patch,
        $nuxt: { $axios },
      } = this

      const { pub_id: pubId, current_subdomain_name: currentSubDomainName } = await $axios.$get(
        `/v1/newsletter/subdomain/${subdomain}/id`
      )
      $patch({ publicationSubDomainName: currentSubDomainName })

      return pubId
    },
    async putSubdomainPublicationId(pubId: string, subdomain: string) {
      const {
        $patch,
        $nuxt: { $axios },
      } = this

      await $axios.$put(`/v1/newsletter/subdomain/${subdomain}?news_id=${pubId}`)

      $patch({ publicationSubDomainName: subdomain })

      return pubId
    },
    async getSubscribers({ publicationId, ...params }: SubscribersParameters) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$get(`/v1/publication/${publicationId}/subscribers`, { params })
    },
    async inviteEmailForPublication(publicationId: string, email: string) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$post(`/v1/publication/${publicationId}/invite/embed`, { email })
    },
    async inviteEmailsForPublication(publicationId: string, users: CSV[]) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$post(`/v1/publication/${publicationId}/invite`, { users })
    },
    async importEmailsForPublication(publicationId: string, users: CSV[]) {
      const {
        $nuxt: { $axios },
      } = this
      return await $axios.$post(`/v1/publication/${publicationId}/subscribers/import`, { users })
    },
    async subscriberExport(publicationId: string) {
      const {
        $nuxt: { $axios },
      } = this

      return await $axios.$get(`/v1/publication/${publicationId}/subscribers/export`)
    },
  },
})
