















import { defineComponent } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { useUser } from '~/stores/user'

export default defineComponent({
  middleware: ['preventSubdomain'],
  props: {
    navbarPosition: {
      type: String,
      default: 'fixed',
      required: false,
      validator: (value: string) => ['absolute', 'relative', 'fixed'].includes(value),
    },
  },
  setup() {
    const { isLogged } = storeToRefs(useUser())
    return {
      isLogged,
    }
  },
})
