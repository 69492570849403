



























import { computed, defineComponent, PropType, useRoute } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    next: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
    current: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    navNext: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { root: { localePath } }) {
    const route = useRoute()
    const routeName = route.value.name?.match(/(.*?)___/)?.at(1)
    const routeStep = routeName?.split(/(.*?)([0-9])/g)

    const routeNamePrefix = String(routeStep?.at(1))
    const backStep = Number(routeStep?.at(2)) - 1

    return {
      back: computed(() => localePath(routeNamePrefix + backStep)),
    }
  },
})
