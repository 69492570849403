import { Context } from '@nuxt/types'
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { useApp } from '~/stores/app'
import { usePublication } from '~/stores/publication'

export default defineNuxtMiddleware(async (ctx: Context) => {
  const { syncColors } = useApp(ctx.$pinia)
  const { syncOwnPublication } = usePublication(ctx.$pinia)
  const { route } = ctx

  await syncOwnPublication(route.params.id)
  await syncColors()
})
