



































import { SearchItem } from '@kessel/core'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    option: {
      type: Object as PropType<SearchItem>,
      required: true,
      default: null,
    },
  },
  setup() {
    return {
    }
  },
})
