




















































import { SignType } from '@kessel/core'
import { PropType, defineComponent, nextTick, ref, toRefs, watch } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    isLogged: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    email: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
    redirectPath: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
    error: {
      type: String as PropType<string>,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { email } = toRefs(props)
    const signType = ref<SignType>(SignType.SIGN_UP)
    const agree = ref(false)
    const emailValueRef = ref(email)
    const emailInputComponentRef = ref<HTMLFormElement>()

    const emitChange = () => {
      emit('onChange', { agree: agree.value, email: emailValueRef.value })
    }

    const onEmailChange = async (value:string) => {
      await nextTick()
      const isValid = emailInputComponentRef.value?.isValid
      if (agree.value && !isValid) {
        agree.value = isValid
      }
      emailValueRef.value = value
      emitChange()
    }

    watch(agree, emitChange)

    return {
      emailValueRef,
      agree,
      onEmailChange,
      signType,
      SignType,
      emailInputComponentRef,
    }
  },
})
