


















































































import { PaymentStatus, Product, Publication, SearchItem, Subscription } from '@kessel/core'
import { PropType, computed, defineComponent, reactive, ref, toRefs, useContext, useRoute, useRouter, watch } from '@nuxtjs/composition-api'
import { AxiosError } from 'axios'
import { storeToRefs } from 'pinia'
import { useAuth } from '~/stores/auth'
import { useSubscription } from '~/stores/subscription'
import { useUser } from '~/stores/user'

export default defineComponent({
  props: {
    publication: {
      type: Object as PropType<Publication>,
      required: true,
    },
    subscription: {
      type: Object as PropType<Subscription>,
      required: false,
      default: null,
    },
    products: {
      type: Array as PropType<Product[]>,
      required: false,
      default: null,
    },
    recommendations: {
      type: Array as PropType<SearchItem[]>,
      required: false,
      default: null,
    },
    canCancel: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  emits: ['cancel'],
  setup: (props, { emit }) => {
    const { publication, subscription, products } = toRefs(props)
    const { i18n, localePath } = useContext()
    const route = useRoute()
    const router = useRouter()
    const { attachPlan } = useSubscription()

    const { user, isLogged } = storeToRefs(useUser())
    const { signUp, generateMagicLink } = useAuth()

    const subscribeLoader = ref(false)
    const errorRegister = ref()

    const prices = computed(() =>
      [
        ...(products.value && (products.value.map(({ prices }) => prices).flat(1))),
      ]
    )

    const trialEligible = computed(() => prices.value.find(({ id }) => form.plan === id)?.recurring?.trial_period_days)

    const form = reactive <{email:string, agree:boolean, plan:string, recommendations:string[]}>({
      email: (user.value?.email || route.value?.query?.email || '').toString(),
      agree: false,
      plan: route.value.query.priceId?.toString() || '',
      recommendations: [],
    })

    const getRedirectPatch = () => {
      return localePath({
        name: 'login',
        query: {
          email: form.email,
          publicationId: publication.value.id,
          redirect: route.value.fullPath,
        },
      })
    }

    const handleError = async (error: AxiosError): Promise<string> => {
      switch (error.response?.status) {
        case 409:
          await generateMagicLink(form.email as string, route.value.fullPath, publication.value.id)
          return i18n.t('EMAIL_ALREADY_REGISTERED_MAGIC_LINK', {
            link: getRedirectPatch(),
          }) as string

        default :
          return i18n.t('SOMETHING_WENT_WRONG') as string
      }
    }
    const cancel = () => {
      emit('cancel')
    }

    const submit = async () => {
      try {
        subscribeLoader.value = true
        errorRegister.value = ''
        if (!form.agree) {
          errorRegister.value = i18n.t('YOU_MUST_AGREE_TO_TERMS') as string
          return
        }
        if (!isLogged.value) {
          try {
            await signUp(String(form.email), undefined, form.agree)
          } catch (error) {
            errorRegister.value = i18n.t(await handleError(error as AxiosError))
            subscribeLoader.value = false
            return
          }
        }

        const { session } = await attachPlan(publication.value.id, form.plan)
        subscribeLoader.value = false

        if (form.recommendations.length) {
          await Promise.all(form.recommendations.map((id:string) => attachPlan(id)))
        }

        if (session?.url) {
          window.location.href = session.url
          return
        }

        await router.replace({ path: route.value.path, query: { ...route.value.query, landing: undefined, subscribe: undefined, r: 'success' } })
        // FIXME find a way to reload without using location ( $nuxt.refresh() )
        location.reload()
      } catch (error: any) {
        errorRegister.value = error?.message
        // TODO error message to toast
        subscribeLoader.value = false
      }
    }

    const isFormValid = computed(() => !!form.email && form.plan !== undefined)

    watch(subscription, () => {
      if (route.value.query.checkout === 'setup' && subscription.value?.payment_status !== PaymentStatus.ACTIVE) {
        // The subscription is not active, that load the checkout page to adjust payment information
        form.plan = ''
        submit()
      }
    }, { immediate: true })

    return ({
      subscribeLoader,
      form,
      submit,
      cancel,
      isLogged,
      errorRegister,
      isFormValid,
      trialEligible,
      redirectPath: computed(() => getRedirectPatch()),
    })
  },
  methods: {
    async onChangePlanId(priceId: string) {
      this.form.plan = priceId
      // Add price id to query params to retrive it after logged in
      if (this.$route.query.priceId?.toString() !== priceId) {
        await this.$router.push({ query: { ...this.$route.query, priceId } })
      }
    },
    onDirectSignupChange({ agree, email }:{ agree: boolean, email: string }) {
      this.form.agree = agree
      if (email) {
        this.form.email = email
      }
    },
    onRecommandationChange(recommendations:string[]) {
      this.form.recommendations = recommendations
    },
  },

})
