
import type { NuxtError } from '@nuxt/types'
import { PropType, defineComponent, toRefs } from '@nuxtjs/composition-api'

export default defineComponent({
  layout: 'error',
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      default: null,
    },
  },
  setup(props, { root: { $sentry } }) {
    const { error } = toRefs(props)
    $sentry?.captureException(error)

    if (error.value.message === 'Invalid token') {
      return {
        headingKey: 'PAGE_INVALID_TOKEN',
        contentKey: 'PAGE_INVALID_TOKEN_DETAILS',
      }
    }

    return {
      headingKey: `PAGE_${error.value.statusCode}`,
      contentKey: `PAGE_${error.value.statusCode}_DETAILS`,
    }
  },
})
