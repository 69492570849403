import { ModalProgrammatic as Modal } from 'buefy'
import Vue from 'vue'

declare global {
  interface Window {
    infoModal: any
  }
}

const infoModal = (title: string, message: string, link: string) => {
  const ModalForm = Vue.extend({
    props: ['title', 'message', 'link'],
    methods: {
      navigate() {
        window.location.href = link
      },
      closeModal() {
        this.$emit('close')
      },
    },
    template: `
        <div
          class="modal-card tw-bg-black tw-text-white tw-rounded-lg p-6 tw-space-y-4 tw-text-center tw-max-w-xs md:tw-max-w-4xl tw-cursor-pointer hover:tw-bg-white hover:tw-text-black hover:tw-scale-105 tw-transition-all tw-w-full !tw-mx-auto tw-relative"
          @click="navigate"
        >
          <button
            type="button"
            class="delete !tw-absolute tw-top-2 tw-left-2"
            @click.stop="closeModal"
          />
          <p class="tw-text-2xl md:tw-text-4xl tw-font-bold tw-italic">${title}</p>
          <p class="tw-text-sm md:tw-text-base tw-font-light">${message}</p>
        </div>
    `,
  })
  Modal.open({
    component: ModalForm as any,
    hasModalCard: true,
  })
}

window.infoModal = infoModal
