import { defineStore } from 'pinia'

interface State {
  features: Record<string, boolean>
}

export const useFeatureFlip = defineStore('featureFlip', {
  state: (): State => ({
    features: {},
  }),
  actions: {
    hydration() {
      this.features = { ...this.$nuxt.$config.features }
    },
    isActive(key: string) {
      return String(this.features[key]) === 'true' ?? false
    },
  },
})
