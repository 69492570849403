import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=4cd46cab&"
import script from "./Button.vue?vue&type=script&lang=ts&"
export * from "./Button.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/tmp/build_84effd69/apps/web/components/editor/components/Icon.vue').default,Button: require('/tmp/build_84effd69/apps/web/components/editor/components/Button.vue').default,KTooltip: require('/tmp/build_84effd69/apps/web/components/KTooltip.vue').default})
